import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { Home2Component } from './home2/home2/home2.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: Home2Component
  // },
  {
    path: '',
    component: HomeComponent
  },
  {
    path:'user',
    loadChildren: ()=>import('./user/user.module').then(x=>x.UserModule)
  },
  {
    path:'',
    loadChildren: ()=>import('./about-us/about-us.module').then(x=>x.AboutUsModule)
  },
  {
    path:'',
    loadChildren: ()=>import('./franchising/franchising.module').then(x=>x.FranchisingModule)
  },
  {
    path:'',
    loadChildren: ()=>import('./company/company.module').then(x=>x.CompanyModule)
  },
  {
    path:'',
    loadChildren: ()=>import('./investors/investors.module').then(x=>x.InvestorsModule)
  },
  {
    path:'',
    loadChildren: ()=>import('./for-you/for-you.module').then(x=>x.ForYouModule)
  },
  {
    path:'',
    loadChildren: ()=>import('./accessibility/accessibility.module').then(x=>x.AccessibilityModule)
  },
  {
    path:'',
    loadChildren: ()=>import('./guarantee-policy/guarantee-policy.module').then(x=>x.GuaranteePolicyModule)
  },
  {
    path:'',
    loadChildren: ()=>import('./privacy-policy/privacy-policy.module').then(x=>x.PrivacyPolicyModule)
  },
  {
    path:'',
    loadChildren: ()=>import('./terms-and-conditions/terms-and-conditions.module').then(x=>x.TermsAndConditionsModule)
  },
  {
    path:'',
    loadChildren: ()=>import('./restaurant-locator/restaurant-locator.module').then(x=>x.RestaurantLocatorModule)
  },
  {
    path:'',
    loadChildren: ()=>import('./product/product.module').then(x=>x.ProductModule)
  },
  {
    path:'catalog',
    loadChildren: ()=>import('./catalog/catalog.module').then(x=>x.CatalogModule)
  },
  {
    path:'about-us',
    loadChildren: ()=>import('./about-us/pizza/pizza.module').then(x=>x.PizzaModule)
  },
  {
    path:'about-us',
    loadChildren: ()=>import('./about-us/chicken-wings-bites/chicken-wings-bites.module').then(x=>x.ChickenWingsBitesModule)
  },
  {
    path:'about-us',
    loadChildren: ()=>import('./about-us/salads-sides/salads-sides.module').then(x=>x.SaladsSidesModule)
  },
  {
    path:'about-us',
    loadChildren: ()=>import('./about-us/sauces-dips/sauces-dips.module').then(x=>x.SaucesDipsModule)
  },
  {
    path:'about-us',
    loadChildren: ()=>import('./about-us/panzerottis/panzerottis.module').then(x=>x.PanzerottisModule)
  },
  {
    path:'about-us',
    loadChildren: ()=>import('./about-us/desserts/desserts.module').then(x=>x.DessertsModule)
  },
  {
    path:'about-us',
    loadChildren: ()=>import('./about-us/beverages/beverages.module').then(x=>x.BeveragesModule)
  },
  {
    path:'about-us',
    loadChildren: ()=>import('./about-us/sandwiches/sandwiches.module').then(x=>x.SandwichesModule)
  },
  {
    path:'franchising',
    loadChildren: ()=>import('./franchising/franchise-opportunities-quebec/franchise-opportunities-quebec.module').then(x=>x.FranchiseOpportunitiesQuebecModule)
  },
  {
    path:'investors',
    loadChildren: ()=>import('./investors/leadership-team/leadership-team.module').then(x=>x.LeadershipTeamModule)
  },
  {
    path:'company',
    loadChildren: ()=>import('./company/accounts-payable-data-entry-clerk/accounts-payable-data-entry-clerk.module').then(x=>x.AccountsPayableDataEntryClerkModule)
  },

  {
    path:'investors',
    loadChildren: ()=>import('./investors/corporate-profile/corporate-profile.module').then(x=>x.CorporateProfileModule)
  },

  {
    path:'investors',
    loadChildren: ()=>import('./investors/investor-faqs/investor-faqs.module').then(x=>x.InvestorFaqsModule)
  },

  {
    path:'investors',
    loadChildren: ()=>import('./investors/investor-glossary/investor-glossary.module').then(x=>x.InvestorGlossaryModule)
  },

  {
    path:'investors',
    loadChildren: ()=>import('./investors/dividend/dividend.module').then(x=>x.DividendModule)
  },
  {
    path:'investors',
    loadChildren: ()=>import('./investors/financial-reports/financial-reports.module').then(x=>x.FinancialReportsModule)
  },
  {
    path:'investors',
    loadChildren: ()=>import('./investors/presentations/presentations.module').then(x=>x.PresentationsModule)
  },
  {
    path:'order',
    loadChildren: ()=>import('./order/order.module').then(x=>x.OrderModule)
  },
  // {
  // path:'order',
  //   loadChildren: ()=>import('./order/order-list/order-list.module').then(x=>x.OrderListModule)
  // },
  // {
  //   path:'page-not-found',
  //     loadChildren: ()=>import('./order/order-list/order-list.module').then(x=>x.OrderListModule)
  //   },
  {
    path: '**',
    component: PageNotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
   // anchorScrolling: 'enabled'
    
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
