import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiServiceService } from './api-service.service';

@Injectable({
  providedIn: 'root'
})

export class DataService{


  private messageSource = new BehaviorSubject<any>("");
currantMessage = this.messageSource.asObservable();

// for pickup and delivery
private messagePickDeliv = new BehaviorSubject<any>("notSet");
currantPickDeliv = this.messagePickDeliv.asObservable();

// cart items
//  orderProduct2:any = JSON.parse(localStorage.getItem("orderProduct") || "null");
private orderProductLo = new BehaviorSubject<any>(null);
currantorderProductLo = this.orderProductLo.asObservable();

// cart hide show
private carthideshow = new BehaviorSubject<any>(true);
currantcarthideshow = this.carthideshow.asObservable();

// header hide
private headerHide = new BehaviorSubject<any>(true);
currantheaderHide = this.headerHide.asObservable();

// list saved pickup address
 private savedAddress = new BehaviorSubject<any>(null);
currantsavedAddress = this.savedAddress.asObservable();

// address on checkout page
private checkoutAddress = new BehaviorSubject<any>(false);
checkoutAddressOb = this.checkoutAddress.asObservable();

// check login
private logincheck = new BehaviorSubject<any>(false);
logincheckob = this.logincheck.asObservable();

  constructor(private http: HttpClient, private router: Router,private apiService:ApiServiceService) {
   
   }

  changeMessage(message:any){
    this.messageSource.next(message)
  }
  pickupdelivery(message:any){
    this.messagePickDeliv.next(message);
  }
  addressCheckoutfunction(message:any){
    this.checkoutAddress.next(message);
  }
  logincheckfunction(message:any){
    this.logincheck.next(message);
  }

  productItem(){
    // var orderProduct = JSON.parse(localStorage.getItem("orderProduct") || "null");
    // console.log(orderProduct);
    // this.orderProductLo.next(orderProduct);
    if(localStorage.getItem("userData") && localStorage.getItem("session_id")){
      var userID:any = JSON.parse(localStorage.getItem("userData") || "null").id;
      var sessionId :any=JSON.parse(localStorage.getItem("session_id") || "null")
    }else if(localStorage.getItem("userData")){
     var userID:any = JSON.parse(localStorage.getItem("userData") || "null").id;
     var sessionId :any=null
    }else if(localStorage.getItem("session_id")){
      var userID:any = null;
     var sessionId :any=JSON.parse(localStorage.getItem("session_id") || "null")
    }
   
    var storeid;
              if(localStorage.getItem("pickupDeliverystores")){
               storeid =JSON.parse(localStorage.getItem("pickupDeliverystores") || "null").data.id;
              }else{
               storeid = null
              }
    this.apiService.listcart(userID, sessionId,storeid).subscribe((data:any)=>{
   
      if(data.status_code==200){
        this.orderProductLo.next(data);
      }
    })

  }
  cartshow(value:boolean){
    this.carthideshow.next(value);
  }
  headerFooterHide(value:boolean){
    this.headerHide.next(value)
  }
  listAddress(){
    this.apiService.listUSerPickUp(JSON.parse(localStorage.getItem("userData")||"null").id).subscribe(data=>{
      this.savedAddress.next(data);
    })
  }


}
