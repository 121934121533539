<app-web-loader *ngIf="wholeloader"></app-web-loader>
<main class="homepage-wraper">
<!-- <section class="homebanner">
    <div class="container">
        
        <div class="homeslider pro-item">
            <ngx-skeleton-loader class="loader-banner" *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '500px' }">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader count="2" class="darkbg" *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '20px', 'background-color':'#e5e5e5' }">
            </ngx-skeleton-loader>
            <ngx-slick-carousel *ngIf="contentLoaded" #slickModal="slick-carousel" [config]="bannerConfig">
                <div ngxSlickItem class="item" *ngFor="let banners of homeBanner">
                    <div class="homemain d-flex align-items-center" style="cursor: pointer;" (click)="bannerproduct(banners)">
                        <div class="homesliderright">
                            
                            <picture >
                                <source media="(max-width: 767px)" srcset="{{imageUrl}}storage/{{banners.mobile_url}}">
                                <source media="(min-width: 768px)" srcset="{{imageUrl}}storage/{{banners.fileurl}}">
                                <img src="{{imageUrl}}storage/{{banners.fileurl}}" alt="{{banners.alt_tag}}" width="100" height="100">
                               
                              </picture>
                        </div>
                        <div class="homesliderleft">
                            <ngx-skeleton-loader *ngIf="!contentLoaded" count="4" [theme]="{ width: '100%', height: '30px' }">
                            </ngx-skeleton-loader>
                            
                            <h2 class="pizzaxlheading" *ngIf="contentLoaded">{{banners.title}} <span>{{banners.subtitle}}</span></h2>
                            <p *ngIf="contentLoaded">{{banners.description}}</p>
                         
                            <ngx-skeleton-loader *ngIf="!contentLoaded" [theme]="{ width: '155px', height: '40px' }">
                            </ngx-skeleton-loader>
                            
                        </div>
                        
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section> -->
<ngx-skeleton-loader class="loader-banner" *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '500px' }">
</ngx-skeleton-loader>
<ngx-skeleton-loader count="2" class="darkbg" *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '20px', 'background-color':'#e5e5e5' }">
</ngx-skeleton-loader>
<section class="inner-banner" *ngIf="contentLoaded">
    
    <div class="container" >
        <div class="inner-top">
            <div class="signin-top">
                <a class="back-btn" href="/">Back to menu</a>
            </div>
            <h1 class="pizzalgheading">{{descName}}</h1>
        </div>
        <div class="inner-bottom">
            <div class="product-header-image">
                <img src="{{newFilterManu.length > 2 ? newFilterManu[2].itemImage_png : newFilterManu[0].itemImage_png }}" alt="old_school_2">
            </div>
        </div>

    </div>
</section>


<section class="pizza-list">
    <div class="container">
        
        <div class="find-box" *ngIf="findMeInternal">
            <p>Menu and pricing vary for each PZA Pizzeria  location. Provide your address to find your local PZA Pizzeria.</p>
            <button class="find-btn" id="findpopup2"><i class="fa fa-location-arrow" aria-hidden="true"></i> Find Me</button>
        </div>
        <div class="plp-wraper">
            <div class="flx--home-heading">
            <h2 class="pizz-xheading" class="menu-h">MENU</h2>
                <div class="language-btn-product">
                    <div class="button" id='button-container' (click)="translate()">
                        <div id='my-button' class="button-element notranslate">
                        <p id='spanish'>{{spanish}}</p>
                        </div>
                        <p id='english' class="notranslate">{{english}}</p>
                    </div>
                <!--   data in this label is changing dynamically according to the toggle button response. currently the label is hidden using css -->
                    <label>Preffered Contact method: <input id='for-button' type="text" name="method" value='Email' /></label>
              </div>
            </div>
            <div class="pro-item" *ngIf="!contentLoaded">
                <ngx-skeleton-loader   [theme]="{ width: '100%', height: '250px' }">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader class="darkbg" count="2" [theme]="{ width: '100%', height: '20px', 'background-color':'#e5e5e5'}">
                </ngx-skeleton-loader>
            </div>
            <div class="pro-item" *ngIf="!contentLoaded">
                <ngx-skeleton-loader   [theme]="{ width: '100%', height: '250px' }">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader class="darkbg" count="2" [theme]="{ width: '100%', height: '20px', 'background-color':'#e5e5e5'}">
                </ngx-skeleton-loader>
            </div>
            <div class="plp-box" *ngFor="let manuItems of newFilterManu; let x= index">
                
                <div class="product-item" *ngIf="contentLoaded">
                    <div class="product-thumb">
                        
                        <a>
                            <img src="{{manuItems.itemImage_png == null?'assets/images/product/pizza.png':manuItems.itemImage_png}}" alt="pizza" width="100" height="100">
                        </a>
                       
                        <!-- <a href="javascript:void(0);" (click)="customizeBtn(manuItems)" *ngIf="manuItems.category.addon_customize==true || manuItems.category.isCombo == true?true:false" ></a>
                            <a id="additem" href="javascript:void(0)"  *ngIf="manuItems.category.addon_customize==true || manuItems.category.isCombo == true?false:true" (click)="quickAddBtn(manuItems, x)"><img src="{{manuItems.itemImage == null?'assets/images/product/pizza.png':manuItems.itemImage}}" alt="pizza"></a> -->
                    </div>
                    <div class="product-content-right">
                    <div>    
                    <div class="pizzalgheading" *ngIf="sptoen == true">{{manuItems.name}}</div>
                    <p class="product-description" *ngIf="sptoen == true">{{manuItems.description}}</p>
                    <div class="pizzalgheading" *ngIf="sptoen == false">{{manuItems.aliasName}}</div>
                    <p class="product-description" *ngIf="sptoen == false">{{manuItems.aliasDescription}}</p>
                    <div class="product-detail">
                        <div class="product-price">
                            <p>
                                <!-- <span *ngIf="sptoen == true">Empezando desde</span>
                                <span *ngIf="sptoen == false">Starting from</span> -->
                                 <span class="notranslate"> ${{manuItems.rate}}</span>
                                
                            </p>
                                 
                        </div>
                        <p class="product-description timdlg" *ngIf="sptoen == false">*taxes included</p>
                        <p class="product-description timdlg" *ngIf="sptoen == true">*impuestos incluidos</p>
                        
                        <div *ngIf="manuItems.out_of_stock == 0" class="product-btn-group">
                            <a href="javascript:void(0);" (click)="customizeBtn(manuItems)" *ngIf="manuItems.category.addon_customize==true || manuItems.category.isCombo == true?true:false" class="pizza-btn customize-btn">
                               <span *ngIf="sptoen == true">personalizar</span> 
                               <span *ngIf="sptoen == false">Customize</span> 
                            </a>
                            <a id="additem" href="javascript:void(0)" class="pizza-btn" (click)="quickAddBtn(manuItems, x)" *ngIf="manuItems.category.addon_customize==true || manuItems.category.isCombo == true?false:true">
                                
                                <span *ngIf="sptoen == true">Añadir rápido</span> 
                               <span *ngIf="sptoen == false">Quick Add</span> 
                                <i *ngIf="quickaddloader == true && quickaddloaderflag == x"  class="fa fa-spinner fa-spin" style="color:#fff" aria-hidden="true"></i></a>
                        </div>
                        <div *ngIf="manuItems.out_of_stock == 1" class="product-btn-group">
                            <p class="outofstock">
                                <span *ngIf="sptoen == true">Indisponible</span>
                                <span *ngIf="sptoen == false">UnAvailable</span>
                            </p>
                        </div>
                        
                    </div>
                    <p class="product-description tixs" *ngIf="sptoen == false">*taxes included</p>
                    <p class="product-description tixs" *ngIf="sptoen == true">*impuestos incluidos</p>
                    </div>
                  </div>
                </div>

                <!-- <div class="size-popup additem-popup">
                    <div class="size-overlay" id="additem"></div>
                    <div class="size-wrapper size-transition">
                      <button class="size-close"><img src="assets/images/icons/close-icon.png" alt="close-icon"> </button>
                      <div class="size-body">
                        <div class="size-content">
                            <div class="signup-main">
                                <div class="pizzaxxsheading">
                                    Add an Item
                                </div>
                                <span class="line"></span>
                                <div class="size-picker-wrapper">
                                    <button class="text-center size-instance active" [ngClass]="{'active': selectedSlices == 'Small'}" (click)="listClick($event, 'Small')">
                                        <div class="icon-wrap"><img src="assets/images/icons/6-Slice.png" alt="6-Slice"></div>
                                        <div class="label">Small</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">6 Slices</div>
                                    </button>
                                    <button class="text-center size-instance" [ngClass]="{'active': selectedSlices == 'Medium'}" (click)="listClick($event, 'Medium')">
                                        <div class="icon-wrap"><img src="assets/images/icons/8-Slice.png" alt="8-Slice"></div>
                                        <div class="label">Medium</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">8 Slices</div>
                                    </button>
                                    <button class="text-center size-instance" [ngClass]="{'active': selectedSlices == 'Large'}" (click)="listClick($event, 'Large')">
                                        <div class="icon-wrap"><img src="assets/images/icons/10-Slice.png" alt="10-Slice"></div>
                                        <div class="label">Large</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">10 Slices</div>
                                    </button>
                                    <button class="text-center size-instance" [ngClass]="{'active': selectedSlices == 'X-Large'}" (click)="listClick($event, 'X-Large')">
                                        <div class="icon-wrap"><img src="assets/images/icons/12-Slice.png" alt="12-Slice"></div>
                                        <div class="label">X-Large</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">12 Slices</div>
                                    </button>
                                </div>
                                <div class="additem-details">
                                    <div class="pizzasmheading">${{manuItems.rate}}</div>
                                    <span class="line"></span>
                                    <div class="pizzasmheading slice-tittle">230 Cals/slice</div>
                                    <button class="pizza-btn" (click)="addToCart(manuItems)">ADD TO CART</button>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div> -->
            </div>
            <!-- <div class="col-md-6 plp-box">
                <div class="product-item">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/pizza.png" alt="pizza"></a>
                    </div>
                    <div class="pizzalgheading">Ultimate Pepperoni</div>
                    <p class="product-description">For a limited time only, get more pepperoni for the same price! Loaded crust to crust with our delicious New York style Pepperoni. It's the ultimate in pepperoni pizzas! For a limited time only, get more pepperoni for the same price! Loaded crust to crust with our delicious New York style Pepperoni. It's the ultimate in pepperoni pizzas!</p>
                    <div class="product-detail">
                        <div class="product-price">
                            <p>Starting from $9.69 210 To 260 Cals/Slice</p>
                        </div>
                        <div class="product-btn-group">
                            <a href="#" class="pizza-btn customize-btn">Customize</a>
                            <a id="additem" href="javascript:void(0)" class="pizza-btn">Quick Add</a>
                        </div>
                    </div>
                </div>
                <div class="size-popup additem-popup">
                    <div class="size-overlay" id="additem"></div>
                    <div class="size-wrapper size-transition">
                      <button class="size-close"><img src="assets/images/icons/close-icon.png" alt="close-icon"> </button>
                      <div class="size-body">
                        <div class="size-content">
                            <div class="signup-main">
                                <div class="pizzaxxsheading">
                                    Add an Item
                                </div>
                                <span class="line"></span>
                                <div class="size-picker-wrapper">
                                    <button class="text-center size-instance active">
                                        <div class="icon-wrap"><i class="server-font-icon small"></i></div>
                                        <div class="label">Small</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">6 Slices</div>
                                    </button>
                                    <button class="text-center size-instance">
                                        <div class="icon-wrap"><i class="server-font-icon small"></i></div>
                                        <div class="label">Small</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">8 Slices</div>
                                    </button>
                                    <button class="text-center size-instance">
                                        <div class="icon-wrap"><i class="server-font-icon small"></i></div>
                                        <div class="label">Small</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">10 Slices</div>
                                    </button>
                                    <button class="text-center size-instance">
                                        <div class="icon-wrap"><i class="server-font-icon small"></i></div>
                                        <div class="label">Small</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">12 Slices</div>
                                    </button>
                                </div>
                                <div class="additem-details">
                                    <div class="pizzasmheading">$9.69</div>
                                    <span class="line"></span>
                                    <div class="pizzasmheading slice-tittle">230 Cals/slice</div>
                                    <button class="pizza-btn">ADD TO CART</button>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="product-item">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/pizza.png" alt="pizza"></a>
                    </div>
                    <div class="pizzalgheading">Ultimate Pepperoni</div>
                    <p class="product-description">For a limited time only, get more pepperoni for the same price! Loaded crust to crust with our delicious New York style Pepperoni. It's the ultimate in pepperoni pizzas!</p>
                    <div class="product-detail">
                        <div class="product-price">
                            <p>Starting from $9.69 210 To 260 Cals/Slice</p>
                        </div>
                        <div class="product-btn-group">
                            <a href="#" class="pizza-btn customize-btn">Customize</a>
                            <a href="#" class="pizza-btn">Quick Add</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="product-item">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/pizza.png" alt="pizza"></a>
                    </div>
                    <div class="pizzalgheading">Ultimate Pepperoni</div>
                    <p class="product-description">For a limited time only, get more pepperoni for the same price! Loaded crust to crust with our delicious New York style Pepperoni. It's the ultimate in pepperoni pizzas! For a limited time only, get more pepperoni for the same price! Loaded crust to crust with our delicious New York style Pepperoni. It's the ultimate in pepperoni pizzas!</p>
                    <div class="product-detail">
                        <div class="product-price">
                            <p>Starting from $9.69 210 To 260 Cals/Slice</p>
                        </div>
                        <div class="product-btn-group">
                            <a href="#" class="pizza-btn customize-btn">Customize</a>
                            <a href="#" class="pizza-btn">Quick Add</a>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>
    </div>
</section>

<!-- <section class="may-also">
    <div class="container">
        <div class="also-top">
            <h3 class="pizzalgheading">YOU MAY ALSO LIKE</h3>
            <a href="#" class="seeall-btn">See all <span></span></a>
        </div>
        <div class="alsosider">
            <ngx-slick-carousel #slickModal="slick-carousel" [config]="alsoConfig">
                <div ngxSlickItem class="item">
                    <div class="also-box">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center also-box-top">
                                <img src="assets/images/also-like/combo.jpg" alt="combo">
                            </div>
                            <div class="col-md-12 d-flex also-box-bottom">
                                <div class="also-bottom-left">
                                    <div class="pizzasmheading">Garlic Bread with cheese</div>
                                    <p>$5.79</p>
                                </div>
                                <div class="also-bottom-right">
                                    <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="also-box">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center also-box-top">
                                <img src="assets/images/also-like/coke.png" alt="coke">
                            </div>
                            <div class="col-md-12 d-flex also-box-bottom">
                                <div class="also-bottom-left">
                                    <div class="pizzasmheading">Garlic Bread with cheese</div>
                                    <p>$5.79</p>
                                </div>
                                <div class="also-bottom-right">
                                    <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="also-box">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center also-box-top">
                                <img src="assets/images/also-like/coke.png" alt="coke">
                            </div>
                            <div class="col-md-12 d-flex also-box-bottom">
                                <div class="also-bottom-left">
                                    <div class="pizzasmheading">Garlic Bread with cheese</div>
                                    <p>$5.79</p>
                                </div>
                                <div class="also-bottom-right">
                                    <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="also-box">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center also-box-top">
                                <img src="assets/images/also-like/coke.png" alt="coke">
                            </div>
                            <div class="col-md-12 d-flex also-box-bottom">
                                <div class="also-bottom-left">
                                    <div class="pizzasmheading">Garlic Bread with cheese</div>
                                    <p>$5.79</p>
                                </div>
                                <div class="also-bottom-right">
                                    <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section> -->
</main>

