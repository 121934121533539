import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http:HttpClient) { }

  // getLocationService():Promise<any>{
  //   return new Promise((resolve, reject)=>{
      
  //   })
  // }
  // public getCity(long:any, lat:any): Observable<any>{
  //   return this.http.get(`https://api.opencagedata.com/geocode/v1/json?q=${lat}+${long}&key=32f8f8d49fe34f56b16769f3266cef9f`)
  // }
  
}
