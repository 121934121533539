import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ProductModule } from './product/product.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorInterceptor } from './helper/interceptor.interceptor';
import { FindMeLocationModule } from './find-me-location/find-me-location.module';
import { PickupLocationPopupModule } from './pickup-location-popup/pickup-location-popup.module';
import { WebLoaderModule } from './web-loader/web-loader.module';
import { DoughPopupsModule } from './dough-popups/dough-popups.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Home2Component } from './home2/home2/home2.component';
import { SortByKeyPipe } from './pipes/sort-by-key.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    Home2Component,
    SortByKeyPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SlickCarouselModule,
    ProductModule,
    HttpClientModule,
    FindMeLocationModule,
    PickupLocationPopupModule,
    WebLoaderModule,
    DoughPopupsModule,
    NgxSkeletonLoaderModule,
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorInterceptor,
      multi: true
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
