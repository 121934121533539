import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebLoaderRoutingModule } from './web-loader-routing.module';
import { WebLoaderComponent } from './web-loader.component';


@NgModule({
  declarations: [
    WebLoaderComponent
  ],
  exports:[WebLoaderComponent],
  imports: [
    CommonModule,
    WebLoaderRoutingModule
  ]
})
export class WebLoaderModule { }
