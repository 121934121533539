import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { DataService } from 'src/app/services/data.service';
import { SeoService } from 'src/app/services/seo.service';


@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.scss']
})
export class Home2Component implements OnInit {
  menudata:any;
  newFilterManu:any;
  descName:any;
  quickAddShow:boolean = true;
  findMeInternal:boolean = true;
  selectedSlices:any = "Large";
  pickupDeliverystores:any;
  contentLoaded = false;
  imageUrl:any;
  homeBanner:any;
  outofstockdata:any = [];
  quickaddloader:boolean=false;
  quickaddloaderflag:any;
  sptoen:boolean = true;
  spanish:any = "Spanish";
  english:any = "English";
  wholeloader:boolean = true;
  private geoCoder: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService:ApiServiceService,
    private dataservice: DataService,
    private seoData:SeoService
  ) { }

  async ngOnInit() {
    
   this.wholeloader = true;
    this.imageUrl = this.apiService.baseUrl;
    // home api
    this.apiService.homeApi().subscribe(data=>{
      console.log(data.data.banner[0])
      // this.seoData.updatemetatags(data.data.banner[0]);
      this.homeBanner = data.data.banner;
      this.wholeloader = false;
    }, error=>{
      console.log(error, "home api error")
      // if(error.status == 401){
        localStorage.clear();
        // localStorage.removeItem("user");
        // localStorage.removeItem("userData");
        // localStorage.removeItem("orderProduct");
        this.dataservice.productItem();
        // localStorage.removeItem("savedPickupLocation");
        this.dataservice.changeMessage("logedOut");
        
        this.dataservice.logincheckfunction(false);
        document.body.classList.remove('successshow')
        // this.router.navigate(['/']);
      // }
    })
    this.dataservice.cartshow(true);
    this.scripts();



    // if(localStorage.getItem("pickupDeliverystores")){
    //   this.findMeInternal = false;
      
    // }else{
    //   this.findMeInternal = true;
    // }
    this.dataservice.currantPickDeliv.subscribe(async data=>{
     
      if(data == "notSet"){
        if(localStorage.getItem("pickupDeliverystores")){
          this.findMeInternal = false;
          this.pickupDeliverystores = localStorage.getItem("pickupDeliverystores");
          let addressData = JSON.parse(this.pickupDeliverystores);
          this.getMenuAPI("take_out", addressData.data.customer_key);
          await this.outofstockfunction();
        }else{
          this.findMeInternal = true;
          let locateArray = {
            "lat":"0",
            "long":"0",
            "city":"default"
        }
        this.apiService.storeLocator(locateArray).subscribe(storedefalut=>{
          this.getMenuAPI("take_out", storedefalut.data.customer_key);
          
        })
          navigator.geolocation.getCurrentPosition((resp): void => {
            this.getAddress(resp.coords.latitude, resp.coords.longitude)
          })
          
          
        }
  
      }else if(data == "SetPickup"){
        this.pickupDeliverystores = localStorage.getItem("pickupDeliverystores");
        let addressData = JSON.parse(this.pickupDeliverystores);
        this.findMeInternal = false;
        await this.outofstockfunction();
        this.getMenuAPI("take_out", addressData.data.customer_key);
        
      }
    })
    
  }

  translate(){
    if(this.sptoen == true){
      this.spanish = "English";
      this.english = "Spanish";
      document.getElementById("my-button")?.style.setProperty('transform', 'translateX(100px)');
      document.getElementById("my-button")?.style.setProperty('border-radius', '0px 20px 20px 0px');
      document.getElementById('english')?.style.setProperty('transform', 'translateX(-100px)');
      this.sptoen = false;
      
    }else{
      this.spanish = "Spanish";
      this.english = "English";
      document.getElementById("my-button")?.style.setProperty('transform', 'translateX(0px)');
      document.getElementById("my-button")?.style.setProperty('border-radius', '20px 0px 0px 20px');
      document.getElementById('english')?.style.setProperty('transform', 'translateX(0px)');
      this.sptoen = true;
    }
    

  }
  bannerproduct(banner:any){
    // console.log(this.newFilterManu)
    
    // var productbannerID:any
    // if(banner.id == 1){
    //   productbannerID = "6384659f53b0b17b3ec0155d"
      
    // }else if(banner.id == 2){
    //   productbannerID = "6384659f53b0b17b3ec01562"
    // }else if(banner.id == 3){
    //   productbannerID = "6384659f53b0b17b3ec0155e"
    // }
    // var bannerfilterdata = this.newFilterManu.filter((value:any, index:any)=>{
    //   return value._id == productbannerID;
    // })
    // console.log(bannerfilterdata);
    // this.customizeBtn(bannerfilterdata[0]);
  
  }

  async getAddress(lati:any, long:any): Promise<void> {
   
     
    this.geoCoder = new google.maps.Geocoder();
    this.geoCoder.geocode({ 'location': { lat: lati, lng: long } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          var add = results[0].formatted_address;
           var value = add.split(",");
         
           let locateArray = {
            "lat":lati,
            "long":long,
            "city":value[value.length - 3]
         }
      //    let locateArray = {
      //     "lat":20.6414255,
      //     "long":-103.3910925,
      //     "city":'44580 Guadalajara'
      // }
    
         this.apiService.storeLocator(locateArray).subscribe(async store=>{
         
          if(await store.status_code == 200){
            if(store.data.length !== 0){
              document.body.classList.remove('Find-open')
              jQuery(".find-popup").removeClass("is-visible");
              let pickupstores = {
                "type": "Pickup",
                "data": await store.data[0]
              }
             await localStorage.setItem('pickupDeliverystores', JSON.stringify(pickupstores));
             this.dataservice.pickupdelivery("SetPickup");
              await this.getMenuAPI("take_out", store.data[0].customer_key);
            }
            
          }
          // else{
          //       this.findMeInternal = true;
          //       let locateArray = {
          //         "lat":"0",
          //         "long":"0",
          //         "city":"default"
          //     }
          //     this.apiService.storeLocator(locateArray).subscribe(async storedata=>{
          //       this.getMenuAPI("take_out",await storedata.data.customer_key);
                
          //     })

          // }

        })
          
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  
 }



  async getMenuAPI(tabType:any, customer_key:any){
    this.apiService.getMenu("tabid" , customer_key).subscribe(async data=>{
      let allcatArray:any = data;
      let newEntityArr:any ={
        "entities": [],
    };
    
   

      let mapaddonItems = await allcatArray.filter((value:any, index:any)=>{
        return value.category.isAddOn == true;
      })
     await mapaddonItems.forEach((element:any) => {
          if(newEntityArr.entities.some((x:any) => x.name == element.category.superCategoryName) == false){
            newEntityArr.entities.push(
              {
                "_id":element.category._id,
                "name": element.category.superCategoryName,
                "levels": [
                  {
                      "name": "base",
                      "priceChange": 0,
                      "categories": []
                  }
                ]
              }
            )
          }

      });
      
      await mapaddonItems.forEach((element:any) => {
        newEntityArr.entities.forEach((items:any) => {
          if(element.category.superCategoryName ==items.name){
              if(items.levels[0].categories.some((x:any) => x.categoryName == element.category.name) == false){
                items.levels[0].categories.push(
                  {
                    "_id": element.category._id,
                    "categoryName": element.category.name,
                    "items": []
                }
                )
              }
             
          }
        })
      })
      
    
      localStorage.setItem('mapAddonItems', JSON.stringify(mapaddonItems));
      localStorage.setItem('entity', JSON.stringify(newEntityArr));
      // localStorage.setItem('mapAddonItems', JSON.stringify(mapaddonItems));

    let menuFinalData= await allcatArray.filter((value:any, index:any)=>{
      return value.category.isAddOn == false && value.category.notInWeb == false && value.activeStatus[0].isDeleted==0;
    }).sort((a:any,b:any)=> a.number-b.number)

  
    let FinalCat:any = []
    localStorage.setItem('menuData', JSON.stringify(menuFinalData));

    // let entityData= await menuFinalData.filter((value:any, index:any)=>{
    //   return value.configuration !=null
    // })
    // if(entityData.length>0){
    //   localStorage.setItem('entity', JSON.stringify(entityData[0].configuration));
    // }
   
    this.getproductData(menuFinalData, this.outofstockdata);
    });



    



  }

  async outofstockfunction():Promise<any>{
    this.pickupDeliverystores = localStorage.getItem("pickupDeliverystores");
    let addressData = JSON.parse(this.pickupDeliverystores);
    if(addressData != null){
      await this.apiService.outofstock(addressData.data.customer_key).then(data=>{
      
         this.outofstockdata= data;
      })
    }else{
      this.outofstockdata= [];
    }
    }

    async getproductData(data:any, outofstock:any){
    
let mapAddonItems = await JSON.parse(localStorage.getItem("mapAddonItems") || "null")

this.newFilterManu = data.filter((item:any)=>{
  this.descName = `${this.activatedRoute.snapshot.params['slug'].replace(/-/g, " ")}`;
  return item.category.name.toLowerCase() == `${this.activatedRoute.snapshot.params['slug'].replace(/-/g, " ")}`;
})
if(!localStorage.getItem("menuData")){
  this.router.navigate(['/'])
}
console.log(this.newFilterManu, "akash patel")
// this.newFilterManu = data
this.newFilterManu.forEach((addmanu:any)=>{
  addmanu["out_of_stock"] = 0;
  addmanu.category["addon_customize"]= false;
})
// this.contentLoaded = true;
outofstock.forEach((element:any) => {
  this.newFilterManu.forEach((manu:any)=>{
    if(element.item_id == manu._id){
      
      manu["out_of_stock"] = 1;
    
    }
  })
});
await this.newFilterManu.forEach((newMenu:any)=>{
  mapAddonItems.forEach((addonItems:any)=>{
    addonItems.mapAddonItems.forEach((mapItems:any)=>{
      if(newMenu._id == mapItems._id){
        newMenu.category["addon_customize"]= true;
        newMenu["configuration"]=
          JSON.parse(localStorage.getItem("entity") || "null")
        
      }
    })
    
  })
})

await this.newFilterManu.forEach((addingEntityItem:any)=>{
  
  if(addingEntityItem.category.addon_customize== true){


    mapAddonItems.forEach((mapAddonItem:any) => {
      
      if(addingEntityItem.configuration != null){
        addingEntityItem.configuration.entities.forEach((entities:any) => {
          if(mapAddonItem.category.superCategoryName == entities.name){
            entities.levels[0].categories.forEach((categories:any) => {
              
              if(mapAddonItem.mapAddonItems.length>0){
                
                if(categories.categoryName == mapAddonItem.category.name){
                  mapAddonItem.mapAddonItems.forEach((addonitems:any) => {
                    if(addonitems._id== addingEntityItem._id){
                      
                      categories.items.push(mapAddonItem);
                    }
                  });
                } 
              }
            });
          }
        });
      }
      
    });


  
    
    
  }else if(addingEntityItem.category.isCombo == true){
  
    mapAddonItems.forEach((mapAddonItem:any) => {
      if(addingEntityItem.configuration != null){
        addingEntityItem.configuration.entities.forEach((entities:any) => {
          if(mapAddonItem.category.superCategoryName == entities.name){
            entities.levels[0].categories.forEach((categories:any) => {
              if(categories.categoryName == mapAddonItem.category.name){
                categories.items.push(mapAddonItem);
              }
            });
          }
        });
      }
      
    });
  }
  
})
let manuitem = await JSON.parse(localStorage.getItem("menuData")|| "null")

    // await manuitem.forEach((manu:any, index:any) => {
      await this.newFilterManu.forEach((element:any, index:any) => {
        manuitem= manuitem.filter((item:any) => item._id != element._id);
        manuitem.push(element)
        // if(manu._id == element._id){
        //   manuitem.pop(manu)
        //   manuitem.push(element)
          // console.log(filteredPeople, element);
        // }
      });
    // });
    localStorage.setItem('menuData', JSON.stringify(manuitem));

this.contentLoaded = true;
    }
    customizeBtn(manuItems:any){
      localStorage.setItem('orderedProductDetail', JSON.stringify(manuItems));
      this.router.navigate([
        'product-details',
        manuItems.category.name.replace(/ /g, "_").toLowerCase(),
        manuItems._id
      ])
        }
      
        listClick(event:any, item:any){
          this.selectedSlices = item;
        }
        quickAddBtn(manuItems:any, index:any){
          this.quickaddloaderflag = index
          // this.selectedSlices = "Large";
          this.quickaddloader = true;
       
          if(localStorage.getItem("userData")){
            var cartProduct:any ={
              "productDetail":manuItems,
              "productName": manuItems.name,
              "sliceType":"",
              "pizzaIngrediants": [],
              "rate": manuItems.rate,
              "quantity": 1,
              "categoryUrl": manuItems.category.name.replace(/ /g, "_").toLowerCase(),
              "productUrl": manuItems.name.replace(/ /g, "_").toLowerCase(),
              "entity": [],
              "editProduct": [],
              "store_id": localStorage.getItem("pickupDeliverystores") ? JSON.parse(localStorage.getItem("pickupDeliverystores") || "null").data.id : 111,
              "user_id": JSON.parse(localStorage.getItem("userData") || "null").id,
              "session_id": null,
              "combo_details":[],
          "addon_details":[],
            }
          }else if(localStorage.getItem("session_id")){
            var cartProduct:any ={
              "productDetail":manuItems,
              "productName": manuItems.name,
              "sliceType":"",
              "pizzaIngrediants": [],
              "rate": manuItems.rate,
              "quantity": 1,
              "categoryUrl": manuItems.category.name.replace(/ /g, "_").toLowerCase(),
              "productUrl": manuItems.name.replace(/ /g, "_").toLowerCase(),
              "entity": [],
              "editProduct": [],
              "store_id": localStorage.getItem("pickupDeliverystores") ? JSON.parse(localStorage.getItem("pickupDeliverystores") || "null").data.id : 111,
              "user_id": null,
              "session_id": JSON.parse(localStorage.getItem("session_id") || "null"),
              "combo_details":[],
          "addon_details":[],
            }
          }
          else{
            var cartProduct:any ={
              "productDetail":manuItems,
              "productName": manuItems.name,
              "sliceType":"",
              "pizzaIngrediants": [],
              "rate": manuItems.rate,
              "quantity": 1,
              "categoryUrl": manuItems.category.name.replace(/ /g, "_").toLowerCase(),
              "productUrl": manuItems.name.replace(/ /g, "_").toLowerCase(),
              "entity": [],
              "editProduct": [],
              "store_id": localStorage.getItem("pickupDeliverystores") ? JSON.parse(localStorage.getItem("pickupDeliverystores") || "null").data.id : 111,
              "session_id": null,
              "user_id": null,
              "combo_details":[],
            "addon_details":[],
            }
          }
         
          this.apiService.addtocart(cartProduct).subscribe((data:any)=>{
           
            if(data.status_code == 200){
          
              setTimeout(() =>{
                this.dataservice.productItem();
                this.quickaddloader = false;
                // this.ngOnInit();
              },1000)
              
              if(data.data.session_id != null){
                localStorage.setItem("session_id",JSON.stringify(data.data.session_id))
              }
            }
          })
        }
        addToCart(manuItems:any){
     
        }
      
        /* justslider-slider */ 
        // justConfig = { 
        
      
        /* alsoConfig-slider */ 
        alsoConfig = { 
          arrows:true,
          infinite: true,
          dots:false,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        };

          /* home-banener-slider */ 
  bannerConfig = { 
    arrows:true,
    infinite: true,
    dots:true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          adaptiveHeight: false,
          dots: true,
        },
      },
    ],
  };

  scripts(){ 
    $(document).ready(function(){
      jQuery('.plp-box #additem').on('click', function(e){
        e.preventDefault();
        jQuery('.plp-box.is-visible').removeClass('is-visible');
        jQuery(this).parents('.plp-box').addClass('is-visible');
      });
      jQuery('.size-close').on('click', function(e){
          e.preventDefault();
          jQuery(this).parents('.plp-box').removeClass('is-visible');
      });


      // jQuery("#additem").on('click', function(){
      //   jQuery(".additem-popup").addClass("is-visible");
      // });
      // jQuery(".size-close").on('click', function(){
      //   jQuery(".additem-popup").removeClass("is-visible");
      // });
      jQuery("#findpopup2").on('click', function(){
        jQuery(".find-popup").addClass("is-visible");
      });
      jQuery(".size-close").on('click', function(){
        jQuery(".find-popup").removeClass("is-visible");
      });
      jQuery('.findpopuptab li a').on('click', function(){
        var target = $(this).attr('data-tab');
        jQuery('.findpopuptab li a').removeClass('active');
        jQuery(this).addClass('active');
        jQuery("#"+target).fadeIn('slow').siblings(".tab-pane").hide();
        return false;
      });
    
    });
  }
  slickInit(e: any) {
    //console.log('slick initialized');
  }
}

