import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiServiceService } from '../services/api-service.service';
import { DataService } from '../services/data.service';
import { LocationService } from '../services/location.service';
import { Pipe, PipeTransform } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';
declare var $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @Pipe({
    name: 'noComma'
  })
 lat:any;
 wholepage:boolean=false;
 long:any;
 isloader:boolean=false;
 storeData:any;
 notstoreData:boolean = true;
 storeDataAvail:boolean = false;
 pickupId:any;
 pickupgoBtn:boolean = false;
 textGo:boolean = true;
 goLoader:boolean = false;
 category:any = [];
 sptoen:boolean = true;
 spanish:any = "Spanish";
 english:any = "English";
 findMeInternal:boolean = true;
 loading:boolean = false;
 pickupDeliverystores:any = localStorage.getItem("pickupDeliverystores");
 options:any={
  componentRestrictions: { country: 'UA' }
  }
  imageUrl:any;
  homeBanner:any;
  justforyou:any;
  comboffer:any;
  orderapp:any=[];
  customer_key:any;
  colorClass:any = ['gradient-clay','gradient-green','gradient-navy','gradient-teal','gradient-gray']
  contentLoaded = false;
  constructor(private apiService:ApiServiceService, private locationService:LocationService,private dataservice: DataService,private seoData:SeoService) { 
    this.dataservice.headerFooterHide(true);
  }
  ngOnInit(): void {

      
    this.dataservice.productItem();
    // add color randomly
    


    
    this.imageUrl = this.apiService.baseUrl;
    // home api
    this.apiService.homeApi().subscribe(data=>{
      this.homeBanner = data.data.banner;
      this.justforyou = data.data.justforyou;
      this.comboffer = data.data.comboffer;
      this.orderapp = data.data.orderapp[0]
      this.wholepage = true;
    })



    this.dataservice.cartshow(true);
    setTimeout(() => {
      this.isloader=true
    }, 3);

    
    this.dataservice.currantPickDeliv.subscribe(data=>{
      this.contentLoaded = false;
     
      if(data == "notSet"){
        if(localStorage.getItem("pickupDeliverystores")){
          this.pickupDeliverystores = localStorage.getItem("pickupDeliverystores");
          let addressData = JSON.parse(this.pickupDeliverystores);
          this.getMenuAPI("take_out", addressData.data.customer_key);
        }else{
          let locateArray = {
            "lat":"0",
            "long":"0",
            "city":"default"
         }
        this.apiService.storeLocator(locateArray).subscribe(store=>{
          
          this.getMenuAPI("take_out", store.data.customer_key);
          
        })
          
        }
  
      }else if(data == "SetPickup"){
        this.pickupDeliverystores = localStorage.getItem("pickupDeliverystores");
        let addressData = JSON.parse(this.pickupDeliverystores);
   
        this.getMenuAPI("take_out", addressData.data.customer_key);
        
      }
    },(error)=>{
      console.log(error);
    })
this.dataservice.currantPickDeliv.subscribe(data=>{
  if(data == "notSet"){
    if(localStorage.getItem("pickupDeliverystores")){
      this.findMeInternal = false;
      this.pickupDeliverystores = localStorage.getItem("pickupDeliverystores");
    let addressData = JSON.parse(this.pickupDeliverystores);
    if(addressData.type == "Delivery"){
      let tabtype = "delivery"

      // this.getMenuAPI(tabtype);
    }else{
      let tabtype = "take_out"
      // this.getMenuAPI(tabtype);
    }
    }else{
      this.findMeInternal = true;
      let tabtype = "delivery"

      // this.getMenuAPI(tabtype);
    }
  }else if(data == "SetPickup"){
    this.findMeInternal = false;
    this.pickupDeliverystores = localStorage.getItem("pickupDeliverystores");
    let addressData = JSON.parse(this.pickupDeliverystores);
    if(addressData.type == "Delivery"){
      let tabtype = "delivery"

      // this.getMenuAPI(tabtype);
    }else{
      let tabtype = "take_out"
      // this.getMenuAPI(tabtype);
    }
  }
})
    
    this.scripts();
    navigator.geolocation.getCurrentPosition(resp=>{
      this.lat = resp.coords.latitude;
      this.long = resp.coords.longitude;
      // this.getLocation();
    })

    // hide show find me internal pages

    // if(localStorage.getItem("pickupDeliverystores")){
    //   this.findMeInternal = false;
    // }else{
    //   this.findMeInternal = true;
    // }
    
  }
 getMenuAPI(tabType:any, customer_key:any){
  

    this.apiService.getMenu(tabType, customer_key).subscribe(async data=>{


      let allcatArray:any = data;

      let mapaddonItems = await allcatArray.filter((value:any, index:any)=>{
        return value.category.superCategoryName == "Toppings";
      })
      localStorage.setItem('mapAddonItems', JSON.stringify(mapaddonItems));

    // let menuFinalData= await allcatArray.filter((value:any, index:any)=>{
    //   return value.category.superCategoryName != "Toppings";
    // })
    let menuFinalData= await allcatArray.filter((value:any, index:any)=>{
      return value.category.isAddOn == false && value.category.notInWeb == false && value.activeStatus[0].isDeleted==0;
    }).sort((a:any,b:any)=> a.number-b.number)


    let FinalCat:any = []
    localStorage.setItem('menuData', JSON.stringify(menuFinalData));
    for(const nedata of menuFinalData){
console.log(nedata.category, 'ji')
      await FinalCat.push(
        {
          "name": nedata.category.name.replace(/"/g,''),
        "slug": nedata.category.name.replace(/ /g,'-').replace(/"/g,'').toLowerCase(),
        "_id":nedata.category._id ,
        "image":nedata.itemImage_png,
        "displayPriority":nedata.category.displayPriority,
        "rate":nedata.rate,
        "aliasName" : nedata.category?._extras?.aliasName

        }
      );
      // if(nedata.category.isCombo== true){
      //   localStorage.setItem('entity', JSON.stringify(nedata.configuration));
      // }
    }
    let entityData= await menuFinalData.filter((value:any, index:any)=>{
      return value.configuration !=null
    })
    if(entityData.length>0){
      localStorage.setItem('entity', JSON.stringify(entityData[0].configuration));
    }

    this.category = await FinalCat.filter((value:any, index:any, self:any)=>{
      return index === self.findIndex((t:any) => (
        t.place === value.place && t.name === value.name
      ))
      
    }).map((obj:any)=>{
      obj.class = this.colorClass[Math.floor(Math.random()*this.colorClass.length)]
      return obj
    })
    localStorage.setItem("categoryUrl", JSON.stringify(this.category));
    
  
    this.contentLoaded = true;
  })
}
  handleAddressChange(address: any) {
    console.log(address.formatted_address)
    console.log(address.geometry.location.lat())
    console.log(address.geometry.location.lng())
  }
  transform(val: number): string {
    if (val !== undefined && val !== null) {
      // here we just remove the commas from value
      return val.toString().replace(/"/g, "");
    } else {
      return "";
    }
  }

  // locateMe(type:any){
  //   this.textGo = false;
  //   this.goLoader = true;
  //   navigator.geolocation.getCurrentPosition(resp=>{
  //     this.locationService.getCity(resp.coords.longitude, resp.coords.latitude).subscribe(data=>{

  //       var locateArray:any
  //       if(data.results[0].components.city){
  //         locateArray = {
           
  //           "lat":"28.5672",
  //           "long":"77.3211",
  //           "city":"Noida"
  //        }
  //       }else{
  //         locateArray = {
          
  //           "lat":"28.5672",
  //           "long":"77.3211",
  //           "city":"Noida"
  //        }
  //       }
        
  //       this.apiService.storeLocator(locateArray).subscribe(store=>{
  //         if(store.status_code == 200){
  //           this.notstoreData = false;
  //           this.storeDataAvail = true;
  //           this.storeData = store.data;
  //           this.textGo = true;
  //           this.goLoader = false;
  //         }

  //       })
  //     })
  //   })
  // }
  selectClick(index:any, stores:any, pickup:any){
    this.pickupId = index;
    this.pickupgoBtn = true;
    let pickupstores = {
      "type": pickup,
      "data": stores
    }
    localStorage.setItem('pickupDeliverystores', JSON.stringify(pickupstores));
  }
  pickupGo(){
    var storeData = JSON.parse(this.pickupDeliverystores);
    this.dataservice.pickupdelivery("SetPickup");
    jQuery(".find-popup").removeClass("is-visible");
  }
// get lat and long from services

// getLocation(){
//     this.apiService.getCurrentLocation(this.lat, this.long).subscribe(data =>{
      
//     })
// }

translate(){
  if(this.sptoen == true){
    this.spanish = "English";
    this.english = "Spanish";
    document.getElementById("my-button")?.style.setProperty('transform', 'translateX(100px)');
    document.getElementById("my-button")?.style.setProperty('border-radius', '0px 20px 20px 0px');
    document.getElementById('english')?.style.setProperty('transform', 'translateX(-100px)');
    this.sptoen = false;
    
  }else{
    this.spanish = "Spanish";
    this.english = "English";
    document.getElementById("my-button")?.style.setProperty('transform', 'translateX(0px)');
    document.getElementById("my-button")?.style.setProperty('border-radius', '20px 0px 0px 20px');
    document.getElementById('english')?.style.setProperty('transform', 'translateX(0px)');
    this.sptoen = true;
  }
  

}



  /* home-banener-slider */ 
  bannerConfig = { 
    arrows:true,
    infinite: true,
    dots:true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  /* home-banener-end */
  /* justslider-slider */ 
  justConfig = { 
    arrows:true,
    infinite: true,
    dots:false,
    centerMode: true,
    centerPadding: '60px',
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          autoplay: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          autoplay: false,
          slidesToShow: 1,
          centerPadding: '30px',
          slidesToScroll: 1,
        },
      },
    ],
  };
  /* justslider-end */
  /* comboofferslider-slider */ 
  comboofferConfig = { 
    arrows:false,
    infinite: true,
    dots:false,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          autoplay: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          autoplay: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  /* comboofferslider-end */  
  save(): void {
    this.loading = true;
  }

  scripts(){ 
    $(document).ready(function(){
      jQuery(".find-popup-onload").removeClass("is-visible");
      jQuery("#findpopup1").on('click', function(){
        jQuery(".find-popup").addClass("is-visible");
      });
      jQuery(".size-close").on('click', function(){
        jQuery(".find-popup").removeClass("is-visible");
      });
      jQuery('.findpopuptab li a').on('click', function(){
        var target = $(this).attr('data-tab');
        jQuery('.findpopuptab li a').removeClass('active');
        jQuery(this).addClass('active');
        jQuery("#"+target).fadeIn('slow').siblings(".tab-pane").hide();
        return false;
      });
      
    
    });

      }
  slickInit(e: any) {
  }
}
