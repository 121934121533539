import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { ProductListingComponent } from './product-listing/product-listing.component';
import { Home2Component } from '../home2/home2/home2.component';
const routes: Routes = [
  { path: 'product-listing/:slug', component: Home2Component },
  { path: 'product-details/:slug/:product', component: ProductDetailComponent },
  { path: 'product-details/:slug/:product/:id', component: ProductEditComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductRoutingModule { }
