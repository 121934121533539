import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dough-popups',
  templateUrl: './dough-popups.component.html',
  styleUrls: ['./dough-popups.component.scss']
})
export class DoughPopupsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  doughpopupClose():void{
    document.body.classList.remove('doughpopup')
  }
}
