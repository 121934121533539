import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { DataService } from './services/data.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'PZA Pizzeria';

  onActive(){
    window.scroll(0, 0);
  }

  headeFooter:boolean = true;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private meta:Meta, private seoTitle:Title, private dataservice: DataService) { }
  loadJsFile(url:any) {

      let node = document.createElement('script');  

      node.src = url;  

      node.type = 'text/javascript';  

      document.getElementsByTagName('head')[0].appendChild(node);

    }  

  ngOnInit() {
    this.loadJsFile("https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit");

    this.meta.addTags([
      {name:"description", content:""},
      {name:"title", content:""},
      {name:"og:title", content:""}
    ])
      if (isPlatformServer(this.platformId)) {
          // do server side stuff
      }
    this.dataservice.currantheaderHide.subscribe(data=>{
      this.headeFooter = data;
    })

  }
  
}
