<section class="page-404">
  <div class="container">
    <div class="pizzaxlheading">404!</div>
    <div class="pizzalgheading">Sorry! The Page Not Found</div>
    <p class="pizzaxsheading">Oops! The page you are looking for does not exist. it might been moved or deleted.</p>
    <div class="return-btn-404">
      <a href="#"  class="pizza-btn">Return to Home</a>
    </div>
  </div>
</section>
