import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { PickupLocationPopupComponent } from './pickup-location-popup.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [PickupLocationPopupComponent],
  exports:[PickupLocationPopupComponent],
  imports: [
    CommonModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAQtOAXGzzIbMtR1xDXN8PIhlQgyUNhfm8',
      libraries: ['places']
    })
  ]
})
export class PickupLocationPopupModule { }
