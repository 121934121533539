



<app-web-loader *ngIf="!isloader"></app-web-loader>

<div>
<div class="help" >
    <div class="container">
      <div class="row">
        <div class="col-sm-8">
          <p class="pizzamdheading">FEATURED SPECIALS</p>
        </div>
        <!-- <div class="col-sm-4 right">
          <a href="#" class="help-btn">
            <img src="assets/images/icons/help-icon.png" alt="help-icon"/>
          </a>
        </div> -->
      </div>

    </div>
</div>
<section class="homebanner">
    <div class="container">
        
        <div class="homeslider pro-item">
            <ngx-skeleton-loader class="loader-banner" *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '500px' }">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader count="2" class="darkbg" *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '20px', 'background-color':'#e5e5e5' }">
            </ngx-skeleton-loader>
            <ngx-slick-carousel *ngIf="contentLoaded" #slickModal="slick-carousel" [config]="bannerConfig">
                <div ngxSlickItem class="item" *ngFor="let banners of homeBanner">
                    <div class="homemain d-flex align-items-center">
                        <div class="homesliderright">
                            
                            <picture >
                                <source media="(max-width: 767px)" srcset="{{imageUrl}}storage/{{banners.mobile_url}}">
                                <source media="(min-width: 768px)" srcset="{{imageUrl}}storage/{{banners.fileurl}}">
                                <img src="{{imageUrl}}storage/{{banners.fileurl}}" alt="{{banners.alt_tag}}">
                                <!-- <img src="http://139.59.43.165/biotique2/assets/pizza-banner.jpg"> -->
                              </picture>
                        </div>
                        <div class="homesliderleft">
                            <ngx-skeleton-loader *ngIf="!contentLoaded" count="4" [theme]="{ width: '100%', height: '30px' }">
                            </ngx-skeleton-loader>
                            <!-- <h1 class="pizzaxlheading" *ngIf="contentLoaded">Best Choice</h1> -->
                            <h2 class="pizzaxlheading" *ngIf="contentLoaded">{{banners.title}} <span>{{banners.subtitle}}</span></h2>
                            <p *ngIf="contentLoaded">{{banners.description}}</p>
                            <!-- <span class="premium" *ngIf="contentLoaded">*Additional charge for premium toppings </span> -->
                            <ngx-skeleton-loader *ngIf="!contentLoaded" [theme]="{ width: '155px', height: '40px' }">
                            </ngx-skeleton-loader>
                            <!-- <a class="pizza-btn" *ngIf="contentLoaded" href="#" mat-raised-button color="primary" [class.spinner]="loading"  (click)="save()">ORDER NOW</a> -->
                        </div>
                        
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section>
<section class="find-top" *ngIf="findMeInternal">
    <div class="container">
        <div class="find-box" >
            <p>Menu and pricing vary for each Pizza Pizza location. Provide your address to find your local Pizza Pizza.</p>
            <button class="find-btn" id="findpopup1"><i class="fa fa-location-arrow" aria-hidden="true"></i> Find Me</button>
        </div>
    </div>
</section>


<section class="pizza-list">
    <span class="ring-left"><img src="assets/images/ring.png" alt="ring"></span>
    <span class="ring-right"><img src="assets/images/ring.png" alt="ring"></span>
    <div class="container">
        <div class="menu-hdng">
            <div class="flx--home-heading">
                <h2 class="pizz-xheading" class="menu-h">MENU</h2>
                    <div class="language-btn-product">
                        <div class="button" id='button-container' (click)="translate()">
                            <div id='my-button' class="button-element notranslate">
                            <p id='spanish'>{{spanish}}</p>
                            </div>
                            <p id='english' class="notranslate">{{english}}</p>
                        </div>
                    <!--   data in this label is changing dynamically according to the toggle button response. currently the label is hidden using css -->
                        <label>Preffered Contact method: <input id='for-button' type="text" name="method" value='Email' /></label>
                  </div>
                </div>
        </div>

        <div class="row" *ngIf="!contentLoaded">
            <div  class="col-6 col-md-6 col-lg-4 col-xl-3 pro-item">
                <ngx-skeleton-loader *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '250px' }">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader count="2" class="darkbg" *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '20px', 'background-color':'#e5e5e5' }">
                </ngx-skeleton-loader>
            </div>
            <div  class="col-6 col-md-6 col-lg-4 col-xl-3 pro-item">
                <ngx-skeleton-loader *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '250px' }">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader count="2" class="darkbg" *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '20px', 'background-color':'#e5e5e5' }">
                </ngx-skeleton-loader>
            </div>
            <div  class="col-6 col-md-6 col-lg-4 col-xl-3 pro-item">
                <ngx-skeleton-loader *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '250px' }">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader count="2" class="darkbg" *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '20px', 'background-color':'#e5e5e5' }">
                </ngx-skeleton-loader>
            </div>
            <div  class="col-6 col-md-6 col-lg-4 col-xl-3 pro-item">
                <ngx-skeleton-loader *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '250px' }">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader count="2" class="darkbg" *ngIf="!contentLoaded" [theme]="{ width: '100%', height: '20px', 'background-color':'#e5e5e5' }">
                </ngx-skeleton-loader>
            </div>
        </div>
        <div class="row" *ngIf="contentLoaded">
            
            <div  class="col-6 col-md-6 col-lg-4 col-xl-3 pro-item" *ngFor="let catData of category | sortByKey :'displayPriority'; let i = index">
                    <div class="product-item" [ngClass]="catData.class">
                        <div  class="pizzalgheading" *ngIf="sptoen == true">{{catData.name}}</div>
                        <div  class="pizzalgheading" *ngIf="sptoen == false">{{catData?.aliasName}}</div>
                        <div class="product-thumb" >
                            <a [routerLink]="['/product-listing',catData.slug]"><img src='https://pizza-backend.lyxelandflamingotech.in/storage/categories/{{catData._id}}.png' alt="pizza"></a>
                        </div>
                        <div class="product-detail">
                            <div class="product-detail-left">
                                <!-- <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                                <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p> -->
                            </div>
                            <a  [routerLink]="['/product-listing',catData.slug]" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                        </div>
                    </div>
               
                
            </div>
            <!-- <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-clay">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/pizza.png" alt="pizza"></a>
                    </div>
                    <div class="pizzalgheading">Veggie Favourites</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-green">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/gourmet-thins.png" alt="gourmet-thins"></a>
                    </div>
                    <div class="pizzalgheading">Gourmet Thins</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-gray">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/alternative-crusts.png" alt="alternative-crusts"></a>
                    </div>
                    <div class="pizzalgheading">Alternative Crusts</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-green">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/beer.png" alt="beer"></a>
                    </div>
                    <div class="pizzalgheading">Beer</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-teal">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/specials.png" alt="specials"></a>
                    </div>
                    <div class="pizzalgheading">Specials</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-gray">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/pickup-specials.png" alt="pickup-specials"></a>
                    </div>
                    <div class="pizzalgheading">Pickup Specials</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-teal">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/chicken.png" alt="chicken"></a>
                    </div>
                    <div class="pizzalgheading">Chicken</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-clay">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/plant-based.png" alt="plant-based"></a>
                    </div>
                    <div class="pizzalgheading">Plant-Based</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-navy">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/salad.png" alt="salad"></a>
                    </div>
                    <div class="pizzalgheading">Salad</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-green">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/panzerottis.png" alt="panzerottis"></a>
                    </div>
                    <div class="pizzalgheading">Panzerottis</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-green">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/bread-basket.png" alt="bread-basket"></a>
                    </div>
                    <div class="pizzalgheading">The Bread Basket</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-gray">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/drinks.png" alt="drinks"></a>
                    </div>
                    <div class="pizzalgheading">Drinks</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-clay">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/famous-dips.png" alt="famous-dips"></a>
                    </div>
                    <div class="pizzalgheading">Famous Dips</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-teal">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/sweet-treats.png" alt="sweet-treats"></a>
                    </div>
                    <div class="pizzalgheading">Sweet Treats</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/apps-sides.png" alt="apps-sides"></a>
                    </div>
                    <div class="pizzalgheading">Apps & Sides</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6 col-lg-4 col-xl-3">
                <div class="product-item gradient-navy">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/donate-slices.png" alt="donate-slices"></a>
                    </div>
                    <div class="pizzalgheading">Donate to Slices For Smiles</div>
                    <div class="product-detail">
                        <div class="product-detail-left">
                            <img src="assets/images/icons/dairy-tag.jpg" alt="dairy-tag">
                            <p>Our pizzas are made with 100% Canadian Mozzarella Cheese</p>
                        </div>
                        <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<!-- <section class="just-for">
    <div class="container">
        <div class="just-top">
            <h3 class="pizzalgheading">JUST FOR YOU</h3>
            <a href="#" class="seeall-btn">See all <span></span></a>
        </div>
        <div class="justslider just2">
            <ngx-slick-carousel #slickModal="slick-carousel" [config]="justConfig">
                <div ngxSlickItem class="item" *ngFor="let homeJustforyou of justforyou">
                    <div class="combooffer-box">
                        <a href="#" target="_blank">
                            <img src="{{imageUrl}}storage/{{homeJustforyou.fileurl}}" alt="just-combo-3">
                            <div class="justfor-botm-hding">
                                <div class="left-b">
                                    <div class="pizzalgheading">Veggie Delight </div>
                                </div>
                                <div class="right-b">
                                    <span class="pizza-img-btn"><img  src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section> -->

<!-- ---just--for---you---dummy--- -->
<!-- <section class="just-for" style="background-color: #fff;">
    <div class="container">
        <div class="just-top">
            <h3 class="pizzalgheading">JUST FOR YOU</h3>
            <a href="#" class="seeall-btn">See all <span></span></a>
        </div>
        <div class="justslider just2">
            <ngx-slick-carousel #slickModal="slick-carousel" [config]="justConfig">
                <div ngxSlickItem class="item">
                    <div class="combooffer-box">
                        <a href="#" target="_blank">
                            <img src="https://pizza-backend.lyxelandflamingotech.in/storage/images/July2022/fo5jz7QtzcFXZouvuYv0.jpg" alt="just-combo-3">
                            <div class="justfor-botm-hding">
                                <div class="left-b">
                                    <div class="pizzalgheading">Veggie Delight </div>
                                </div>
                                <div class="right-b">
                                    <span class="pizza-img-btn"><img  src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></span>
                                </div>
                            </div>
                        </a>
                        
                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="combooffer-box">
                        <a href="#" target="_blank">
                            <img src="https://pizza-backend.lyxelandflamingotech.in/storage/images/July2022/fo5jz7QtzcFXZouvuYv0.jpg" alt="just-combo-3">
                            <div class="justfor-botm-hding">
                                <div class="left-b">
                                    <div class="pizzalgheading">Veggie Delight </div>
                                </div>
                                <div class="right-b">
                                    <span class="pizza-img-btn"><img  src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></span>
                                </div>
                            </div>
                        </a>
                        
                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="combooffer-box">
                        <a href="#" target="_blank">
                            <img src="https://pizza-backend.lyxelandflamingotech.in/storage/images/July2022/fo5jz7QtzcFXZouvuYv0.jpg" alt="just-combo-3">
                            <div class="justfor-botm-hding">
                                <div class="left-b">
                                    <div class="pizzalgheading">Veggie Delight </div>
                                </div>
                                <div class="right-b">
                                    <span class="pizza-img-btn"><img  src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></span>
                                </div>
                            </div>
                        </a>
                        
                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="combooffer-box">
                        <a href="#" target="_blank">
                            <img src="https://pizza-backend.lyxelandflamingotech.in/storage/images/July2022/fo5jz7QtzcFXZouvuYv0.jpg" alt="just-combo-3">
                            <div class="justfor-botm-hding">
                                <div class="left-b">
                                    <div class="pizzalgheading">Veggie Delight </div>
                                </div>
                                <div class="right-b">
                                    <span class="pizza-img-btn"><img  src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></span>
                                </div>
                            </div>
                        </a>
                        
                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="combooffer-box">
                        <a href="#" target="_blank">
                            <img src="https://pizza-backend.lyxelandflamingotech.in/storage/images/July2022/fo5jz7QtzcFXZouvuYv0.jpg" alt="just-combo-3">
                            <div class="justfor-botm-hding">
                                <div class="left-b">
                                    <div class="pizzalgheading">Veggie Delight </div>
                                </div>
                                <div class="right-b">
                                    <span class="pizza-img-btn"><img  src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></span>
                                </div>
                            </div>
                        </a>
                        
                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="combooffer-box">
                        <a href="#" target="_blank">
                            <img src="https://pizza-backend.lyxelandflamingotech.in/storage/images/July2022/fo5jz7QtzcFXZouvuYv0.jpg" alt="just-combo-3">
                            <div class="justfor-botm-hding">
                                <div class="left-b">
                                    <div class="pizzalgheading">Veggie Delight </div>
                                </div>
                                <div class="right-b">
                                    <span class="pizza-img-btn"><img  src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></span>
                                </div>
                            </div>
                        </a>
                        
                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="combooffer-box">
                        <a href="#" target="_blank">
                            <img src="https://pizza-backend.lyxelandflamingotech.in/storage/images/July2022/fo5jz7QtzcFXZouvuYv0.jpg" alt="just-combo-3">
                            <div class="justfor-botm-hding">
                                <div class="left-b">
                                    <div class="pizzalgheading">Veggie Delight </div>
                                </div>
                                <div class="right-b">
                                    <span class="pizza-img-btn"><img  src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></span>
                                </div>
                            </div>
                        </a>
                        
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section> -->




<!-- <section class="combo-offer">
    <div class="container">
        <div class="comboofferslider">
            <ngx-slick-carousel #slickModal="slick-carousel" [config]="comboofferConfig">
                <div ngxSlickItem class="item" *ngFor="let homeComboffer of comboffer">
                    <div class="combooffer-box">
                        <a href="#"><img src="{{imageUrl}}storage/{{homeComboffer.fileurl}}" alt="combo-offer"></a>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section> -->

<!-- <section class="order-our">
    <div class="container">
        <div class="order-our-main">
            <div class="order-our-left">
                <h4 class="pizzaxlheading">{{orderapp.title}}</h4>
                <h5 class="pizzaxlheading">{{orderapp.subtitle}}!</h5>
                <p>{{orderapp.description}}</p>
                <div class="app-store">
                    <a href="#" class="app-btn apple-btn">
                        <img src="assets/images/apple-app.png" alt="apple-app">
                    </a>
                    <a href="#" class="app-btn android-btn">
                        <img src="assets/images/android-app.png" alt="android-app">
                    </a>
                </div>
            </div>
            <div class="order-our-right">
                <span class="order-scle"></span>
                <img src="{{imageUrl}}storage/{{orderapp.fileurl}}" [attr.alt]="orderapp.alt_tag">
            </div>
        </div>
    </div>
</section> -->






</div>
