<section class="inner-banner">
    <div class="container">

        <div class="inner-top">
            <div class="signin-top">
                <a class="back-btn" href="javascript:history.back()">Back to menu</a>
            </div>
            <h1 class="pizzalgheading">{{descName}}</h1>
        </div>
        <div class="inner-bottom">
            <div class="product-header-image">
                <img src="assets/images/old_school_2.png" alt="old_school_2">
            </div>
        </div>

    </div>
</section>


<section class="pizza-list">
    <div class="container">
        <div class="find-box" *ngIf="findMeInternal">
            <p>Menu and pricing vary for each Pizza Pizza location. Provide your address to find your local Pizza Pizza.</p>
            <button class="find-btn" id="findpopup2"><i class="fa fa-location-arrow" aria-hidden="true"></i> Find Me</button>
        </div>
        <div class="plp-wraper">
            <div class="pro-item" *ngIf="!contentLoaded">
                <ngx-skeleton-loader   [theme]="{ width: '100%', height: '250px' }">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader class="darkbg" count="2" [theme]="{ width: '100%', height: '20px', 'background-color':'#e5e5e5'}">
                </ngx-skeleton-loader>
            </div>
            <div class="pro-item" *ngIf="!contentLoaded">
                <ngx-skeleton-loader   [theme]="{ width: '100%', height: '250px' }">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader class="darkbg" count="2" [theme]="{ width: '100%', height: '20px', 'background-color':'#e5e5e5'}">
                </ngx-skeleton-loader>
            </div>
            <div class="plp-box" *ngFor="let manuItems of newFilterManu; let x= index">
                
                <div class="product-item" *ngIf="contentLoaded">
                    <div class="product-thumb">
                        
                        <a href="javascript:void(0);">
                            <img src="{{manuItems.itemImage == null?'assets/images/product/pizza.png':manuItems.itemImage}}" alt="pizza">
                        </a>
                    </div>
                    <div class="product-content-right">
                    <div class="pizzalgheading">{{manuItems.name}}</div>
                    <p class="product-description">{{manuItems.description}}</p>
                    <div class="product-detail">
                        <div class="product-price">
                            <p>Starting from <span class="notranslate"> ${{manuItems.rate}}</span></p>
                        </div>
                        <div *ngIf="manuItems.out_of_stock == 0" class="product-btn-group">
                            <a href="javascript:void(0);" (click)="customizeBtn(manuItems)" *ngIf="manuItems.category.addon_customize==true || manuItems.category.isCombo == true?true:false" class="pizza-btn customize-btn">Customize</a>
                            <a id="additem" href="javascript:void(0)" class="pizza-btn" *ngIf="manuItems.category.addon_customize==true || manuItems.category.isCombo == true?false:true" (click)="quickAddBtn(manuItems)">Quick Add</a>
                        </div>
                        <div *ngIf="manuItems.out_of_stock == 1" class="product-btn-group">
                            <p class="outofstock">UnAvailable</p>
                        </div>
                    </div>
                    </div>
                </div>

                <!-- <div class="size-popup additem-popup">
                    <div class="size-overlay" id="additem"></div>
                    <div class="size-wrapper size-transition">
                      <button class="size-close"><img src="assets/images/icons/close-icon.png" alt="close-icon"> </button>
                      <div class="size-body">
                        <div class="size-content">
                            <div class="signup-main">
                                <div class="pizzaxxsheading">
                                    Add an Item
                                </div>
                                <span class="line"></span>
                                <div class="size-picker-wrapper">
                                    <button class="text-center size-instance active" [ngClass]="{'active': selectedSlices == 'Small'}" (click)="listClick($event, 'Small')">
                                        <div class="icon-wrap"><img src="assets/images/icons/6-Slice.png" alt="6-Slice"></div>
                                        <div class="label">Small</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">6 Slices</div>
                                    </button>
                                    <button class="text-center size-instance" [ngClass]="{'active': selectedSlices == 'Medium'}" (click)="listClick($event, 'Medium')">
                                        <div class="icon-wrap"><img src="assets/images/icons/8-Slice.png" alt="8-Slice"></div>
                                        <div class="label">Medium</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">8 Slices</div>
                                    </button>
                                    <button class="text-center size-instance" [ngClass]="{'active': selectedSlices == 'Large'}" (click)="listClick($event, 'Large')">
                                        <div class="icon-wrap"><img src="assets/images/icons/10-Slice.png" alt="10-Slice"></div>
                                        <div class="label">Large</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">10 Slices</div>
                                    </button>
                                    <button class="text-center size-instance" [ngClass]="{'active': selectedSlices == 'X-Large'}" (click)="listClick($event, 'X-Large')">
                                        <div class="icon-wrap"><img src="assets/images/icons/12-Slice.png" alt="12-Slice"></div>
                                        <div class="label">X-Large</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">12 Slices</div>
                                    </button>
                                </div>
                                <div class="additem-details">
                                    <div class="pizzasmheading">${{manuItems.rate}}</div>
                                    <span class="line"></span>
                                    <div class="pizzasmheading slice-tittle">230 Cals/slice</div>
                                    <button class="pizza-btn" (click)="addToCart(manuItems)">ADD TO CART</button>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div> -->
            </div>
            <!-- <div class="col-md-6 plp-box">
                <div class="product-item">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/pizza.png" alt="pizza"></a>
                    </div>
                    <div class="pizzalgheading">Ultimate Pepperoni</div>
                    <p class="product-description">For a limited time only, get more pepperoni for the same price! Loaded crust to crust with our delicious New York style Pepperoni. It's the ultimate in pepperoni pizzas! For a limited time only, get more pepperoni for the same price! Loaded crust to crust with our delicious New York style Pepperoni. It's the ultimate in pepperoni pizzas!</p>
                    <div class="product-detail">
                        <div class="product-price">
                            <p>Starting from $9.69 210 To 260 Cals/Slice</p>
                        </div>
                        <div class="product-btn-group">
                            <a href="#" class="pizza-btn customize-btn">Customize</a>
                            <a id="additem" href="javascript:void(0)" class="pizza-btn">Quick Add</a>
                        </div>
                    </div>
                </div>
                <div class="size-popup additem-popup">
                    <div class="size-overlay" id="additem"></div>
                    <div class="size-wrapper size-transition">
                      <button class="size-close"><img src="assets/images/icons/close-icon.png" alt="close-icon"> </button>
                      <div class="size-body">
                        <div class="size-content">
                            <div class="signup-main">
                                <div class="pizzaxxsheading">
                                    Add an Item
                                </div>
                                <span class="line"></span>
                                <div class="size-picker-wrapper">
                                    <button class="text-center size-instance active">
                                        <div class="icon-wrap"><i class="server-font-icon small"></i></div>
                                        <div class="label">Small</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">6 Slices</div>
                                    </button>
                                    <button class="text-center size-instance">
                                        <div class="icon-wrap"><i class="server-font-icon small"></i></div>
                                        <div class="label">Small</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">8 Slices</div>
                                    </button>
                                    <button class="text-center size-instance">
                                        <div class="icon-wrap"><i class="server-font-icon small"></i></div>
                                        <div class="label">Small</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">10 Slices</div>
                                    </button>
                                    <button class="text-center size-instance">
                                        <div class="icon-wrap"><i class="server-font-icon small"></i></div>
                                        <div class="label">Small</div>
                                        <span class="line"></span>
                                        <div class="label mt-2">12 Slices</div>
                                    </button>
                                </div>
                                <div class="additem-details">
                                    <div class="pizzasmheading">$9.69</div>
                                    <span class="line"></span>
                                    <div class="pizzasmheading slice-tittle">230 Cals/slice</div>
                                    <button class="pizza-btn">ADD TO CART</button>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="product-item">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/pizza.png" alt="pizza"></a>
                    </div>
                    <div class="pizzalgheading">Ultimate Pepperoni</div>
                    <p class="product-description">For a limited time only, get more pepperoni for the same price! Loaded crust to crust with our delicious New York style Pepperoni. It's the ultimate in pepperoni pizzas!</p>
                    <div class="product-detail">
                        <div class="product-price">
                            <p>Starting from $9.69 210 To 260 Cals/Slice</p>
                        </div>
                        <div class="product-btn-group">
                            <a href="#" class="pizza-btn customize-btn">Customize</a>
                            <a href="#" class="pizza-btn">Quick Add</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="product-item">
                    <div class="product-thumb">
                        <a href="#"><img src="assets/images/product/pizza.png" alt="pizza"></a>
                    </div>
                    <div class="pizzalgheading">Ultimate Pepperoni</div>
                    <p class="product-description">For a limited time only, get more pepperoni for the same price! Loaded crust to crust with our delicious New York style Pepperoni. It's the ultimate in pepperoni pizzas! For a limited time only, get more pepperoni for the same price! Loaded crust to crust with our delicious New York style Pepperoni. It's the ultimate in pepperoni pizzas!</p>
                    <div class="product-detail">
                        <div class="product-price">
                            <p>Starting from $9.69 210 To 260 Cals/Slice</p>
                        </div>
                        <div class="product-btn-group">
                            <a href="#" class="pizza-btn customize-btn">Customize</a>
                            <a href="#" class="pizza-btn">Quick Add</a>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>
    </div>
</section>

<section class="may-also">
    <div class="container">
        <div class="also-top">
            <h3 class="pizzalgheading">YOU MAY ALSO LIKE</h3>
            <!-- <a href="#" class="seeall-btn">See all <span></span></a> -->
        </div>
        <div class="alsosider">
            <ngx-slick-carousel #slickModal="slick-carousel" [config]="alsoConfig">
                <div ngxSlickItem class="item">
                    <div class="also-box">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center also-box-top">
                                <img src="assets/images/also-like/combo.jpg" alt="combo">
                            </div>
                            <div class="col-md-12 d-flex also-box-bottom">
                                <div class="also-bottom-left">
                                    <div class="pizzasmheading">Garlic Bread with cheese</div>
                                    <p>$5.79</p>
                                </div>
                                <div class="also-bottom-right">
                                    <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="also-box">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center also-box-top">
                                <img src="assets/images/also-like/coke.png" alt="coke">
                            </div>
                            <div class="col-md-12 d-flex also-box-bottom">
                                <div class="also-bottom-left">
                                    <div class="pizzasmheading">Garlic Bread with cheese</div>
                                    <p>$5.79</p>
                                </div>
                                <div class="also-bottom-right">
                                    <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="also-box">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center also-box-top">
                                <img src="assets/images/also-like/coke.png" alt="coke">
                            </div>
                            <div class="col-md-12 d-flex also-box-bottom">
                                <div class="also-bottom-left">
                                    <div class="pizzasmheading">Garlic Bread with cheese</div>
                                    <p>$5.79</p>
                                </div>
                                <div class="also-bottom-right">
                                    <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="also-box">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center also-box-top">
                                <img src="assets/images/also-like/coke.png" alt="coke">
                            </div>
                            <div class="col-md-12 d-flex also-box-bottom">
                                <div class="also-bottom-left">
                                    <div class="pizzasmheading">Garlic Bread with cheese</div>
                                    <p>$5.79</p>
                                </div>
                                <div class="also-bottom-right">
                                    <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section>
