
<app-web-loader *ngIf="wholeloader"></app-web-loader>
<div *ngIf="wholePage">
<section class="pdp-banner" >
    <div class="container">
        <div class="inner-top">
            <div class="signin-top">
                <a class="back-btn" href="javascript:history.back()">BACK TO MENU</a>
            </div>
            <h1 class="pizzalgheading" *ngIf="!contentLoaded"><span *ngIf="slicesType">{{selectedSlices}}</span> {{productDetail.name}}</h1>
            <p class="pizzalgheading">
                <ngx-skeleton-loader  *ngIf="contentLoaded"    [theme]="{'background-color': '#e9e9e9', width: '30%', height: '30px' }">
                </ngx-skeleton-loader>
            </p>
        </div>
        <div class="customize-box">
            <div class="row customize-row align-items-end align-items-md-center justify-content-center">
                <div class="add-pro-slider" *ngIf="!contentLoaded">
                    <ngx-slick-carousel #slickModal="slick-carousel" [config]="addproductConfig">
                        <div ngxSlickItem class="item">
                            <div class="product-image-wrap">
                                <img  src="{{productDetail.itemImage_png == null?'assets/images/product/pizza.png':productDetail.itemImage_png}}" alt="base">
                                <!-- <div  *ngIf="entityDetails.length != 0" >
                                    <div  *ngFor="let category of entityDetails[0].category">
                                        <div class="append-img-list" *ngFor="let item of category.items">
                                            <img  src="{{item.itemImage}}" alt="base">
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
                <div class="col-md-4 col-lg-4 col-xl-3 add-new-box d-none d-md-flex justify-content-center">
                    <!-- <div class="configurator-upsale-modal">
                        <div class="close-btn"><i class="fa fa-times"></i></div>
                        <div class="row configurator-upsale-modal-row mx-0 w-100">
                            <div class="col-3 product-image-wrap p-1">
                                <img class="rounded-circle img-fluid pp-image-loaded" src="../../../assets/images/product/alternative-crusts.png" alt="">
                            </div>
                            <div class="col-9 product-image-left">
                                <div class="pizzaxxsheading">2 Bottled Drinks</div>
                                <div class="description">$4.24</div>
                                <div class="d-flex align-content-center add-to-cart">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                    <span class="label align-self-center"> Add to cart </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="additem" class="add-new-item text-center">
                        <p>New Item</p>
                    </div>
                    <div class="size-popup additem-popup">
                        <div class="size-overlay" id="additem"></div>
                        <div class="size-wrapper size-transition">
                            <div class="size-header">
                                <button class="size-close"><img src="assets/images/icons/close-icon.png" alt="close-icon"> </button>
                                <div class="pizzaxxsheading">Add an Item</div>
                                <span class="line"></span>
                            </div>

                          <div class="size-body">
                            <div class="size-content">
                                <div class="signup-main">
                                    <div class="menu-container-data">
                                        <div class="pizzaxxsheading">Pizza</div>
                                        <ul>
                                            <li *ngFor="let item of categoryUrl">
                                                <a [routerLink]="['/product-listing/'+item.slug]">{{item.name}}</a>
                                            </li>
                                        </ul>
                                        
                                    </div>

                                </div>
                            </div>
                          </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-md-8 col-lg-8 col-xl-9">
                    <div class="product-summary-row d-flex align-content-center justify-content-md-end justify-content-center">
                        <div *ngIf="!contentLoaded" class="product-summary-left">
                            <!-- <div class="size-picker-wrapper">
                                <button class="text-center size-instance active" [ngClass]="{'active': selectedSlices == 'Small'}" (click)="listClick($event, 'Small')">
                                    <div class="icon-wrap"><img src="assets/images/icons/6-Slice.png" alt="6-Slice"></div>
                                    <div class="label">Small</div>
                                    <span class="line"></span>
                                    <div class="label mt-2">6 Slices</div>
                                </button>
                                <button class="text-center size-instance" [ngClass]="{'active': selectedSlices == 'Medium'}" (click)="listClick($event, 'Medium')">
                                    <div class="icon-wrap"><img src="assets/images/icons/8-Slice.png" alt="8-Slice"></div>
                                    <div class="label">Medium</div>
                                    <span class="line"></span>
                                    <div class="label mt-2">8 Slices</div>
                                </button>
                                <button class="text-center size-instance" [ngClass]="{'active': selectedSlices == 'Large'}" (click)="listClick($event, 'Large')">
                                    <div class="icon-wrap"><img src="assets/images/icons/10-Slice.png" alt="10-Slice"></div>
                                    <div class="label">Large</div>
                                    <span class="line"></span>
                                    <div class="label mt-2">10 Slices</div>
                                </button>
                                <button class="text-center size-instance" [ngClass]="{'active': selectedSlices == 'X-Large'}" (click)="listClick($event, 'X-Large')">
                                    <div class="icon-wrap"><img src="assets/images/icons/12-Slice.png" alt="12-Slice"></div>
                                    <div class="label">X-Large</div>
                                    <span class="line"></span>
                                    <div class="label mt-2">12 Slices</div>
                                </button>
                            </div> -->
                            <div class="item-description d-md-none">
                                <p><span *ngFor="let items of entityarray">{{items}}, </span></p>
                            </div>
                            <div class="product-quantity-price notranslate">
                                <div class="product-quantity-left">
                                    <div class="product-number">
                                        <span class="minus" (click)="minus()" [ngClass]="quantity == 1?'notallowed':''">-</span>
                                        <!-- <input type="text"  [value]="quantity"/> -->
                                        <span class="numbertype">{{quantity}}</span>
                                        <span class="plus" (click)="plus()">+</span>
                                    </div>
                                </div>
                                <div class="product-quantity-right">
                                    <h2 class="pizzalgheading" (click)="upgradepopupShow()">${{productRate}}</h2>
                                </div>
                            </div>
                            <div class="cart-update-btn">
                                <button class="pizza-btn" [disabled]="!addtocarflag" *ngIf="addBtnHS" (click)="pushedOrdered(productDetail.name)" [ngClass]="addtocarflag == false?'notallowed-btn':''">
                                    Update
                                    &nbsp;
                                    <i *ngIf="loader" class="fa fa-spinner fa-spin" style="color:#fff" aria-hidden="true"></i>
                                </button>
                                <button *ngIf="addedBtn" class="pizza-btn with-icon" style="    background-color: #4e7026;">Added</button>
                            </div>
                            <div class="product-cals">
                                <!-- <h3 class="pizzamdheading">220 Cals/slice</h3> -->
                            </div>
                        </div>
                        <div *ngIf="contentLoaded" class="product-summary-left" style="text-align: right;">
                            <hr>
                            <ngx-skeleton-loader [theme]="{'background-color': '#cdcdcd', width: '50%', height: '30px' }">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader [theme]="{'background-color': '#e9e9e9', width: '100%', height: '30px' }">
                            </ngx-skeleton-loader>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="item-description d-none d-md-block">
                <!-- <p *ngIf="!contentLoaded">
                    <span *ngFor="let items of entityarray">{{items}}, </span>
                    <span *ngFor="let entities of entityDetails" class="notranslate">
                        <span *ngFor="let categorys of entities.category">
                           
                            <span *ngFor="let catItems of categorys.items">{{catItems.name}} {{catItems.quantity == 1?" ":"(X"+catItems.quantity+")"}}, </span>

                        </span>
                    </span>
                    
                </p> -->
                <p *ngIf="!contentLoaded">{{productDetail.description}}</p>
                <p>
                    <ngx-skeleton-loader  *ngIf="contentLoaded"    [theme]="{'background-color': '#e9e9e9', width: '50%', height: '20px' }">
                    </ngx-skeleton-loader>
                </p>
            </div>
        </div>
    </div>
</section>
<section class="configuration-categories" *ngIf="contentLoaded" >
    <div class="container">
        <div class="configurator-options">
            <div class="row justify-content-center config-row">
                <div class="col-12 col-md-8 configurator-options-sizer">
                    <div class="tab-content">
                        <div class="tab-pane show active">
                            
                            
                            <div class="tab-content second-tab-content">

                                <div class="tab-pane show active">
                                    <div class="row justify-content-center">


                                        <div class="product-option-col col-12 col-sm-6 col-md-4 col-lg-3">
                                            <div class="product-option">
                                                <div class="check-box-icon-wrap">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </div>
                                                <div class="info-wrap">
                                                    <div class="select">Select</div>
                                                    <div class="animation-wrap">
                                                        <div class="option-label">
                                                            <ngx-skeleton-loader [theme]="{'background-color': '#e9e9e9', width: '200%', height: '20px' }">
                                                            </ngx-skeleton-loader>
                                                        </div>

                                                        <div class="half-half-wrap">
                                                            <div class="d-flex justify-content-between icons-wrap">
                                                            

                                                            </div>
                                                            
                                                        </div>
                                                        <div class="separator"></div>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-option-col col-12 col-sm-6 col-md-4 col-lg-3">
                                            <div class="product-option">
                                                <div class="check-box-icon-wrap">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </div>
                                                <div class="info-wrap">
                                                    <div class="select">Select</div>
                                                    <div class="animation-wrap">
                                                        <div class="option-label">
                                                            <ngx-skeleton-loader [theme]="{'background-color': '#e9e9e9', width: '200%', height: '20px' }">
                                                            </ngx-skeleton-loader>
                                                        </div>

                                                        <div class="half-half-wrap">
                                                            <div class="d-flex justify-content-between icons-wrap">
                                                            

                                                            </div>
                                                            
                                                        </div>
                                                        <div class="separator"></div>
                                                       
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-option-col col-12 col-sm-6 col-md-4 col-lg-3">
                                            <div class="product-option">
                                                <div class="check-box-icon-wrap">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </div>
                                                <div class="info-wrap">
                                                    <div class="select">Select</div>
                                                    <div class="animation-wrap">
                                                        <div class="option-label">
                                                            <ngx-skeleton-loader [theme]="{'background-color': '#e9e9e9', width: '200%', height: '20px' }">
                                                            </ngx-skeleton-loader>
                                                        </div>

                                                        <div class="half-half-wrap">
                                                            <div class="d-flex justify-content-between icons-wrap">
                                                            

                                                            </div>
                                                            
                                                        </div>
                                                        <div class="separator"></div>
                                                     
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-option-col col-12 col-sm-6 col-md-4 col-lg-3">
                                            <div class="product-option">
                                                <div class="check-box-icon-wrap">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </div>
                                                <div class="info-wrap">
                                                    <div class="select">Select</div>
                                                    <div class="animation-wrap">
                                                        <div class="option-label">
                                                            <ngx-skeleton-loader [theme]="{'background-color': '#e9e9e9', width: '200%', height: '20px' }">
                                                            </ngx-skeleton-loader>
                                                        </div>

                                                        <div class="half-half-wrap">
                                                            <div class="d-flex justify-content-between icons-wrap">
                                                            

                                                            </div>
                                                            
                                                        </div>
                                                        <div class="separator"></div>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="configuration-categories" *ngIf="!contentLoaded" >
    <div class="container">
        <div class="product-tab">
            <ul class="productconfiguratortab productconfiguratorslider">
                <li *ngFor="let entities of productDetail.configuration.entities; let i = index">
                  <a [ngClass]="i==0? 'active': ''" [attr.data-tab]="entities.name.split(' ').join('_')" href="javascript:void(0);">
                      <span class="tab-icon">
                          <img class="gray-icon" src="assets/images/icons/dsc-icon-gray.png" alt="dsc-icon-gray">
                          <img class="white-icon" src="assets/images/icons/dsc-icon-white.png" alt="dsc-icon-white">
                      </span>
                      {{i+1}}. {{entities.name}}
                  </a>
                </li>

            </ul>
        </div>


        <div class="configurator-options">
            <div class="row justify-content-center config-row">
                <div class="col-12 col-md-8 configurator-options-sizer">
                    <div class="tab-content">
                        <div  id="{{entities.name.split(' ').join('_')}}" class="tab-pane" [ngClass]="j==0? 'show active': ''" *ngFor="let entities of productDetail.configuration.entities; let j = index">
                            <ul class="productsubcategory">
                                <li *ngFor="let entityCategories of entities.levels[0].categories; let k = index">
                                    <a [ngClass]="k+j==0? 'active': ''" [attr.data-tab]="entityCategories.categoryName.split(' ').join('_')+j | removedoubleComma" href="javascript:void(0);">{{entityCategories.categoryName | removedoubleComma}}</a>
                                </li>
                               

                            </ul>
                            <!-- <div class="reset-btn-pdp">
                                <button class="resetbuttonPro" (click)="resetproduct()">Reset <i class="fa fa-refresh" aria-hidden="true"></i></button>
                            </div> -->
                            <div class="tab-content second-tab-content">

                                <div id="{{entityCategories.categoryName.split(' ').join('_')+j | removedoubleComma}}" class="tab-pane show " [ngClass]="l==0? 'show active': ''" *ngFor="let entityCategories of entities.levels[0].categories; let l = index">
                                    <div class="row justify-content-center">


                                        <div class="product-option-col col-12 col-sm-6 col-md-4 col-lg-3" [ngClass]="editProductarray[j].levels[0].categories[l].items[x].addedItem == true?'col-lg-6':'' " (click)="entityFunction(entityItems.name, entities.name, entityCategories.categoryName, toppingquantity,l,x, productDetail, entityItems)"  *ngFor="let entityItems of entityCategories.items; let x= index">
                                            <div class="product-option" [ngClass]="editProductarray[j].levels[0].categories[l].items[x].addedItem == true?'open':'' ">
                                                <div class="check-box-icon-wrap">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </div>
                                                <div class="info-wrap">
                                                    <div class="select">Select</div>
                                                    <div class="animation-wrap">
                                                        <div class="option-label">{{entityItems.name}}</div>

                                                        <!-- <div class="half-half-wrap">
                                                            <div class="d-flex justify-content-between icons-wrap notranslate">
                                                                
                                                                <span class="icon-topping icon-topping-left leftRight{{x}}" [ngClass]="editProductarray[j].levels[0].categories[l].items[x].onSides == 'Left'? 'active':''" (click)="leftRightCheck('Left', l, x,entityItems.name, entities.name, entityCategories.categoryName)"></span>
                                                                <span class="icon-topping icon-topping-full leftRight{{x}}" [ngClass]="editProductarray[j].levels[0].categories[l].items[x].onSides == 'Whole'? 'active':''" (click)="leftRightCheck('Whole', l, x,entityItems.name, entities.name, entityCategories.categoryName)"></span>
                                                                <span class="icon-topping icon-topping-right leftRight{{x}}" [ngClass]="editProductarray[j].levels[0].categories[l].items[x].onSides == 'Right'? 'active':''" (click)="leftRightCheck('Right', l, x,entityItems.name, entities.name, entityCategories.categoryName)"></span>

                                                            </div>
                                                            <div class="info" >On <span id="leftRight{{l}}{{x}}">{{editProductarray[j].levels[0].categories[l].items[x].onSides}}</span></div>
                                                        </div> -->
                                                        <div class="separator"></div>
                                                        <!-- <div class="calories-label">100 Cals</div> -->
                                                    </div>
                                                    <!-- <div class="align-items-center justify-content-between incrementor-wrap notranslate">
                                                        <div class="product-number">
                                                            <span class="minus"  (click)="toppingminus(x,l, entityItems.name, toppingquantity, entities.name, entityCategories.categoryName, entityItems, productDetail)">-</span>
                                                            
                                                            <span class="numbertype" id="numberPizza{{l}}{{x}}">{{editProductarray[j].levels[0].categories[l].items[x].quantity}}</span>
                                                            <span class="plus"  (click)="toppingplus(x,l, entityItems.name, toppingquantity, entities.name, entityCategories.categoryName, entityItems,productDetail)">+</span>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div class="image-overflow-wrap">
                                                    <div class="image-wrap">
                                                        <img src="{{entityItems.itemImage_png == null?'assets/images/ZC.png':entityItems.itemImage_png}}" alt="Zc">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="side-product-details col-12 col-md-4" *ngIf="hideproductsummaryleft">
                    <div class="product-summary-left">
                        <div class="product-image-wrap">
                            <img src="assets/images/base.png" alt="base">
                        </div>
                        <div class="item-description">
                            <p>
                                <span *ngFor="let items of entityarray">{{items}}, </span>
                            </p>
                        </div>
                        <div class="size-picker-wrapper">
                            <button class="text-center size-instance active" [ngClass]="{'active': selectedSlices == 'Small'}" (click)="listClick($event, 'Small')">
                                <div class="icon-wrap"><img src="assets/images/icons/6-Slice.png" alt="6-Slice"></div>
                                <div class="label">Small</div>
                                <span class="line"></span>
                                <div class="label mt-2">6 Slices</div>
                            </button>
                            <button class="text-center size-instance" [ngClass]="{'active': selectedSlices == 'Medium'}" (click)="listClick($event, 'Medium')">
                                <div class="icon-wrap"><img src="assets/images/icons/8-Slice.png" alt="8-Slice"></div>
                                <div class="label">Medium</div>
                                <span class="line"></span>
                                <div class="label mt-2">8 Slices</div>
                            </button>
                            <button class="text-center size-instance" [ngClass]="{'active': selectedSlices == 'Large'}" (click)="listClick($event, 'Large')">
                                <div class="icon-wrap"><img src="assets/images/icons/10-Slice.png" alt="10-Slice"></div>
                                <div class="label">Large</div>
                                <span class="line"></span>
                                <div class="label mt-2">10 Slices</div>
                            </button>
                            <button class="text-center size-instance" [ngClass]="{'active': selectedSlices == 'X-Large'}" (click)="listClick($event, 'X-Large')">
                                <div class="icon-wrap"><img src="assets/images/icons/12-Slice.png" alt="12-Slice"></div>
                                <div class="label">X-Large</div>
                                <span class="line"></span>
                                <div class="label mt-2">12 Slices</div>
                            </button>
                        </div>

                        <div class="product-quantity-price notranslate">
                            <div class="product-quantity-left">
                                <div class="product-number">
                                    <span class="minus" (click)="minus()">-</span>
                                    <input type="text" class="numbertype" [value]="quantity"/>
                                    <span class="plus" (click)="plus()">+</span>
                                </div>
                            </div>
                            <div class="product-quantity-right">
                                <h2 class="pizzalgheading" >${{productDetail.rate}}</h2>
                            </div>
                        </div>
                        <div class="cart-update-btn">
                            <button class="pizza-btn" *ngIf="addBtnHS" (click)="pushedOrdered(productDetail.name)">
                                Update &nbsp;
                                <i *ngIf="loader" class="fa fa-spinner fa-spin" style="color:#fff" aria-hidden="true"></i>
                            </button>
                                <button *ngIf="addedBtn" class="pizza-btn with-icon" style="    background-color: #4e7026;">Added</button>
                        </div>
                        <div class="product-cals">
                            <!-- <h3 class="pizzamdheading">220 Cals/slice</h3> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <section class="Charge-box">
    <div class="container">
        <ul>
            <li>
                Small Only
            </li>
            <li>
                Medium Only
            </li>
            <li>
                Premium Charge
            </li>
            <li>
                Contains Gluten
            </li>
        </ul>
        <p>Calories listed are in addition to the calories displayed for the basic preparation of the standard food item. (Cals/slice). For more details on Pizza Pizza product allergens <a href="javascript:void(0);" routerLink="/about-us" target="_blank">click here</a></p>
    </div>
</section> -->

<!-- <section class="may-also">
    <div class="container">
        <div class="also-top">
            <h3 class="pizzalgheading">YOU MAY ALSO LIKE</h3>
            <a href="#" class="seeall-btn">See all <span></span></a>
        </div>
        <div class="alsosider">
            <ngx-slick-carousel #slickModal="slick-carousel" [config]="alsoConfig">
                <div ngxSlickItem class="item">
                    <div class="also-box">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center also-box-top">
                                <img src="assets/images/also-like/combo.jpg" alt="combo">
                            </div>
                            <div class="col-md-12 d-flex also-box-bottom">
                                <div class="also-bottom-left">
                                    <div class="pizzasmheading">Garlic Bread with cheese</div>
                                    <p>$5.79</p>
                                </div>
                                <div class="also-bottom-right">
                                    <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="also-box">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center also-box-top">
                                <img src="assets/images/also-like/coke.png" alt="coke">
                            </div>
                            <div class="col-md-12 d-flex also-box-bottom">
                                <div class="also-bottom-left">
                                    <div class="pizzasmheading">Garlic Bread with cheese</div>
                                    <p>$5.79</p>
                                </div>
                                <div class="also-bottom-right">
                                    <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="also-box">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center also-box-top">
                                <img src="assets/images/also-like/coke.png" alt="coke">
                            </div>
                            <div class="col-md-12 d-flex also-box-bottom">
                                <div class="also-bottom-left">
                                    <div class="pizzasmheading">Garlic Bread with cheese</div>
                                    <p>$5.79</p>
                                </div>
                                <div class="also-bottom-right">
                                    <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div ngxSlickItem class="item">
                    <div class="also-box">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center also-box-top">
                                <img src="assets/images/also-like/coke.png" alt="coke">
                            </div>
                            <div class="col-md-12 d-flex also-box-bottom">
                                <div class="also-bottom-left">
                                    <div class="pizzasmheading">Garlic Bread with cheese</div>
                                    <p>$5.79</p>
                                </div>
                                <div class="also-bottom-right">
                                    <a href="#" class="pizza-img-btn"><img src="assets/images/icons/pizza-icon.png" alt="pizza-icon"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section> -->
</div>




<!-- delete saved stores popup starts-->
<div class="find-popup2">
  <div class="find-overlay" id="findpopup"></div>
  <div class="find-wrapper find-transition delite-popup-wraper">
      <div class="find-header">
          <button class="find-close" (click)="upgradepopupClose()"><img src="assets/images/icons/close-icon.png" alt="close-icon"> </button>
          <div class="user-icon">
            <img src="assets/images/up-arrow.png">
          </div>
          <div class="pizzalgheading">Ready for an Upgrade?</div>
      </div>
    <div class="find-body">
      <p class="pizzaparaDelete">Do you want to upsize to X-Large (230 Cals/slice) for only an extra $3.10?</p>
      <div class="find-content" style="text-align: center;">
          <button class="btn-delete-no" (click)="upgradepopupClose()">No, Thanks</button>
          <button class="btn-delete-yes">Oh Yeah!</button>
      </div>
    </div>
  </div>
</div>
<!-- delete saved stores popup end-->


<!-- out of stock popup starts-->
<div class="out-of-stock">
    <div class="find-overlay" id="findpopup"></div>
    <div class="find-wrapper find-transition delite-popup-wraper">
        <div class="find-header">
            <button class="find-close" (click)="outstockpopupClose()"><img src="assets/images/icons/close-icon.png" alt="close-icon"> </button>
            <div class="user-icon">
              <img src="assets/images/out-of-stock.png">
            </div>
            <div class="pizzalgheading">Out of stock</div>
        </div>
      <!-- <div class="find-body">
        <p class="pizzaparaDelete">Something went wrong, Please try again Later</p>
      </div> -->
    </div>
  </div>
  <!-- dout of stock popup end-->


<!-- <button (click)="outofstockpopupShow()" style="width: 150px; display: block; margin: 10px auto;">Out of stock popup</button> -->


