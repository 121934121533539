import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ApiServiceService } from '../services/api-service.service';
import { DataService } from '../services/data.service';
import { LocationService } from '../services/location.service';
import { Pipe, PipeTransform } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-find-me-location',
  templateUrl: './find-me-location.component.html',
  styleUrls: ['./find-me-location.component.scss']
})
export class FindMeLocationComponent implements OnInit {
  textGo:boolean = true;
  goLoader:boolean = false;
  storeData:any;
 notstoreData:boolean = true;
 storeDataAvail:boolean = false;
 pickupgoBtn:boolean = false;
 pickupId:any;

 longitude:any;
 latitude:any;
 noDelivery:boolean = false;
 currentCity:any;
 bar:any ;
 storeImage:boolean = true;
 popupsignupbtn:boolean = false;
 storedatanotavail:any;
 resetButton:boolean = false;
 pickupstores:any;
 private storeLocatorSubscription!: Subscription;
 private geoCoder: any;
 saveaddressShow:boolean= false;
 @ViewChild('search')
  public searchElementRef!: ElementRef;
  constructor(
    private apiService:ApiServiceService, 
    private locationService:LocationService,
    private dataservice: DataService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private router: Router,

    ) { }
  ngOnInit(): void {
    this.dataservice.logincheckob.subscribe(data=>{
      if(data == false){
        if(localStorage.getItem("userData")){
          this.storeDataAvail = false;
          this.popupsignupbtn = false;
          this.storeImage = true;
          // document.getElementById("storeDataAvail")?.style.setProperty("display","none");
          
          // document.getElementById("popupsignupbtn")?.style.setProperty("display","none");
          // document.getElementById("storeImage")?.style.setProperty("display","block");
        }else{
          this.storeDataAvail = false;
          // this.popupsignupbtn = true;
          this.storeImage = true;
          // document.getElementById("storeDataAvail")?.style.setProperty("display","none");
          // document.getElementById("popupsignupbtn")?.style.setProperty("display","block");
          // document.getElementById("storeImage")?.style.setProperty("display","block");
        }
      }else{
        this.storeDataAvail = false;
          this.popupsignupbtn = false;
          this.storeImage = true;
        // document.getElementById("storeDataAvail")?.style.setProperty("display","none");
        // document.getElementById("popupsignupbtn")?.style.setProperty("display","none");
        // document.getElementById("storeImage")?.style.setProperty("display","block");
      }
    })

    if(localStorage.getItem("userData")){
      
          this.popupsignupbtn = false;
          this.storeImage = true;
      // document.getElementById("popupsignupbtn")?.style.setProperty("display","none");
      // document.getElementById("storeImage")?.style.setProperty("display","block");
    }else{
          this.storeImage = true;
      // document.getElementById("popupsignupbtn")?.style.setProperty("display","block");
      document.getElementById("storeImage")?.style.setProperty("display","block");
    }

    
    this.mapsAPILoader.load().then(() => {
      // this.getAddress();
      this.geoCoder = new google.maps.Geocoder;
      this.storedatanotavail="";
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
 
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
 
          //set latitude, longitude and zoom
          this.getAddress(place.geometry.location.lat(), place.geometry.location.lng());
        });
      });
    });
  }

  modelChangeFn(e:any){
    this.bar = e;
    if(e==""){
      this.resetButton = false;
          this.popupsignupbtn = false;
          this.storeImage = true;
      // document.getElementById("storeImage")?.style.setProperty("display","block");
      // document.getElementById("popupsignupbtn")?.style.setProperty("display","none");
      this.storeDataAvail = false;
      // document.getElementById("storeDataAvail")?.style.setProperty("display","none");
      this.textGo = true;
      this.goLoader = false;
      this.storeLocatorSubscription.unsubscribe()
    }else{
      this.resetButton = true;
    }
  }
  resetAddress(){
    this.storedatanotavail="";
    this.bar ="";
    // document.getElementById("storeImage")?.style.setProperty("display","block");
    // document.getElementById("popupsignupbtn")?.style.setProperty("display","none");
    this.storeDataAvail = false;
          this.popupsignupbtn = false;
          this.storeImage = true;
    // document.getElementById("storeDataAvail")?.style.setProperty("display","none");
    this.textGo = true;
    this.goLoader = false;
    this.resetButton = false;
    this.storeLocatorSubscription.unsubscribe()
  }
  movetosignin(){
    document.body.classList.remove('Find-open')
    jQuery(".find-popup").removeClass("is-visible");
    this.router.navigate(['/user/sign-in'])
  }

 async getAddress(lati:any, long:any): Promise<void> { 

     
     this.geoCoder = new google.maps.Geocoder();
     this.geoCoder.geocode({ 'location': { lat: lati, lng: long } }, (results: any, status: any) => {
       if (status === 'OK') {
         if (results[0]) {
           
           var add = results[0].formatted_address;
           var value = add.split(",");
          
           this.longitude =long ;
           this.latitude = lati;
           this.currentCity = value[value.length - 3].replace(/[0-9]/g, '');
           
           this.textGo = false;
            this.goLoader = true;
           let locateArray = {
            "lat":this.latitude,
            "long":this.longitude,
            "city":this.currentCity
         }
          
          this.storeLocatorSubscription = this.apiService.storeLocator(locateArray).subscribe(async store=>{
           
            if(store.status_code == 200){
             
              // this.storeDataAvail = true;
              
              this.storeData =await store.data;
              this.textGo = true;
              this.goLoader = false;
              this.storeDataAvail = true;
          this.popupsignupbtn = false;
          this.storeImage = false;
              this.storedatanotavail="";
              // document.getElementById("storeImage")?.style.setProperty("display","none");
              // document.getElementById("popupsignupbtn")?.style.setProperty("display","none");
              // document.getElementById("storeDataAvail")?.style.setProperty("display","block");
            }else{
              this.textGo = true;
              this.goLoader = false;
              this.storeDataAvail = false;
              this.popupsignupbtn = false;
              this.storeImage = false;
              this.storedatanotavail = store.message
            }
  
          })
           
         } else {
           window.alert('No results found');
         }
       } else {
         window.alert('Geocoder failed due to: ' + status);
       }

     });
   
  }


  selectClick(index:any, stores:any, pickup:any){
    this.pickupId = index;

    this.pickupgoBtn = true;
    this.pickupstores = {
      "type": pickup,
      "data": stores
    }
    
  }
  pickupGo(){
    localStorage.setItem('pickupDeliverystores', JSON.stringify(this.pickupstores));
    var pickupDeliverystores = JSON.parse(localStorage.getItem("pickupDeliverystores") || "null");
    if(localStorage.getItem("savedPickupLocation")){
      var arraysavedPickupLocation = JSON.parse(localStorage.getItem("savedPickupLocation") || "null");
      
      arraysavedPickupLocation.map((obj:any)=>{
        obj.by_default = 0;
      })

      if(arraysavedPickupLocation.some((item:any)=>item.id == pickupDeliverystores.data.id)==false){
       Object.assign(pickupDeliverystores.data, {by_default:1})
        arraysavedPickupLocation.push(pickupDeliverystores.data)
        localStorage.setItem('savedPickupLocation', JSON.stringify(arraysavedPickupLocation));
        this.dataservice.addressCheckoutfunction(true);
      }else if(arraysavedPickupLocation.some((item:any)=>item.id == pickupDeliverystores.data.id)==true){
        arraysavedPickupLocation = arraysavedPickupLocation.filter((item:any)=>{
          
           return item.id !== pickupDeliverystores.data.id
        })
        Object.assign(pickupDeliverystores.data, {by_default:1})
        arraysavedPickupLocation.push(pickupDeliverystores.data)
        localStorage.setItem('savedPickupLocation', JSON.stringify(arraysavedPickupLocation));
        this.dataservice.addressCheckoutfunction(true);
      }
    }else{
      var savedArrLocation=[];
      Object.assign(pickupDeliverystores.data, {by_default:1})
      savedArrLocation.push(
        pickupDeliverystores.data
      )

      localStorage.setItem('savedPickupLocation', JSON.stringify(savedArrLocation));
      this.dataservice.addressCheckoutfunction(true);
    }


    const date = new Date();

var newDate = new Date(date.getUTCFullYear(),date.getUTCMonth(),date.getUTCDate(),date.getUTCHours(),date.getUTCMinutes(),date.getUTCSeconds());
var CSToffSet = -360;
var offset= CSToffSet*60*1000;
var CSTTime = new Date(newDate.getTime()+offset);
    
    // closing hours to minutes
    var closingHours = pickupDeliverystores.data.timings[CSTTime.getDay()].closed_time.trim().split(/\s+/);
  
    var closingMinute;
    var closingHoursSplit = closingHours[0].trim().split(/:/);
    if(closingHours[1]=="PM"){
      
      closingMinute = (Number(closingHoursSplit[0])+12)*60+Number(closingHoursSplit[1])
    }else{
      closingMinute = (Number(closingHoursSplit[0]))*60+Number(closingHoursSplit[1])
    }
    // opening hours to minutes
    var openingHours = pickupDeliverystores.data.timings[CSTTime.getDay()].open_time.trim().split(/\s+/);
    var openingminutes;
    var openingHoursSplit = openingHours[0].trim().split(/:/);
    if(openingHours[1]=="PM"){
      openingminutes = (Number(openingHoursSplit[0])+12)*60+Number(openingHoursSplit[1])
    }else{
      openingminutes = (Number(openingHoursSplit[0]))*60+Number(openingHoursSplit[1])
    }
    if(openingminutes <= (CSTTime.getHours()*60+CSTTime.getMinutes()) && (CSTTime.getHours()*60+CSTTime.getMinutes()) <= closingMinute){
      this.dataservice.pickupdelivery("SetPickup");
      jQuery(".find-popup").removeClass("is-visible");
    }else{
      jQuery(".find-popup").removeClass("is-visible");
      document.body.classList.add('storeCloseSavedStores')
      
      localStorage.removeItem("pickupDeliverystores");
    }

    
  }
  storeClosepopuClose(){
    localStorage.removeItem("orderProduct");
    this.dataservice.productItem();
    document.body.classList.remove('storeCloseSavedStores')
    document.body.classList.remove('Find-open')

    
  }

  async deliveryClick(){
    // await this.getAddress();
    this.textGo = false;
    this.goLoader = true;

    var locateArray = {
      "lat":"28.5672",
      "long":"77.3211",
      "city":"Noida"
   }

    this.apiService.storeLocator(locateArray).subscribe(store=>{
      if(store.status_code == 200){
        for(const newArray of store.data){
          
          if(JSON.parse(newArray.order_type).length == 2){
            
            let pickupstores = {
              "type": "Delivery",
              "data": newArray
            }
            localStorage.setItem('pickupDeliverystores', JSON.stringify(pickupstores));
            this.dataservice.pickupdelivery("SetPickup");
            // document.getElementById("storeImage")?.style.setProperty("displamodelChangeFny","block");
            
            this.storeDataAvail = false;
          this.popupsignupbtn = false;
          this.storeImage = true;
        //     document.getElementById("popupsignupbtn")?.style.setProperty("display","none");
        // document.getElementById("storeDataAvail")?.style.setProperty("display","none");
        this.textGo = true;
        this.goLoader = false;
        this.pickupgoBtn = true
            
        jQuery(".find-popup").removeClass("is-visible");
        return
          }
        }
      }

    })
  }
  async pickupClick(type:any){
    
    navigator.geolocation.getCurrentPosition((resp): void => {
      this.getAddress(resp.coords.latitude, resp.coords.longitude);
    })
       
     
  }

}
