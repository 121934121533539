import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class ApiServiceService {
  
  // baseUrl = "https://pizza-backend.lyxelandflamingotech.in/"
  // baseWbesiteUrl="http://pizzapizza.lyxelandflamingotech.in/";

  // UAT
  baseUrl = "https://api.pzapizzeria.mx/"
  // baseWbesiteUrl="https://uat.pzapizzeria.mx/";
  baseWbesiteUrl="https://pzapizzeria.mx/";
  ourToken="bb1f8d27d5d0710fdcdaf5901146f219";
 
  jwttokenRequest:any;
 


// test posist url
// poistbaseurl = "http://18.140.214.202:9010/"
// posisttocken="cjFMNV9yZ1hjOnhVaGtvOXB6d3VCN2xCWXRRbE1WUXdaZExLTityRmZTdDdycmFMZUMvZ2s9"
//  customer_key = "c590528e1ac87399a4ef5eb0e9b04ac07da0cac0afeab486e5e32f18b682d3a51e61fdf8066e87406496aebd15ab566e"
 

// uat posist url
posisttocken='cnlCMHlNUnFEOmlXSVAwcVhFRGNXd0E2blIzOFE1MGpUMjRFUzNtT3BlLzdqT2pIYk1xTWM9'
customer_key='f5b8d3bba00fb2763e6dee0497e5d3d00320ab703c0afabb2cea84e6eb8707661076456d33b4d1c040e381d83e7486a1'
// poistbaseurl='http://posistapi.com/'
// &tabId=648c714f782a7b6f53ffad91


// live posist url
poistbaseurl = "https://posistapi.com/"
// posisttocken="cnlCMHlNUnFEOmlXSVAwcVhFRGNXd0E2blIzOFE1MGpUMjRFUzNtT3BlLzdqT2pIYk1xTWM9"
// &tabId=64810b17543127317a895daa

// customer_key_1="7a653271c0c1a801bb06a67f5c0a6690dc8f71e795ee90ad24d2edd083d757c9ef641a939f09881d8e992adbf2fb466d"
 

  // header for Posist Token
  
  posistrequestOptions = { 
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Basic ${this.posisttocken}`
    })
  };

  //  googleApiheaders = new HttpHeaders({
  //   'Content-Type': 'application/json',
  //   'Access-Control-Allow-Origin': '*',
  //   'Access-Control-Allow-Headers': 'Content-Type',
  //   'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
  // });
  // googlerequestOptions = { headers: this.googleApiheaders };

// basic token
// date = new Date();
//  offset = -300; //Timezone offset for EST in minutes.
//  estDate = new Date(this.date.getTime() + this.offset*60*1000);



 date = new Date();
 newDate = new Date(this.date.getUTCFullYear(),this.date.getUTCMonth(),this.date.getUTCDate(),this.date.getUTCHours(),this.date.getUTCMinutes(),this.date.getUTCSeconds());
CSToffSet = -360;
offset= this.CSToffSet*60*1000;
CSTTime = new Date(this.newDate.getTime()+this.offset);
cstdate = this.CSTTime.getFullYear() + '/' +((this.CSTTime.getMonth() > 8) ? (this.CSTTime.getMonth() + 1) : ('0' + (this.CSTTime.getMonth() + 1))) + '/' + ((this.CSTTime.getDate() > 9) ? this.CSTTime.getDate() : ('0' + this.CSTTime.getDate()))







constructor(private http:HttpClient) { 
 
}
basictokenRequest = {
  
  headers: new HttpHeaders({
  'Accept': 'application/json',
  'Authorization': 'Basic ' + btoa('pizza-pizza:PizzaPizza'+this.cstdate+'@admin'),
})
};





  getMenu(tabtype:any, customer_key:any){
    // live
    return this.http.get(`${this.poistbaseurl}api/v1/online_order/menu?tabtype=delivery&customer_key=${customer_key}&tabId=64810b17543127317a895daa`, this.posistrequestOptions)

    // stagging
    // return this.http.get(`${this.poistbaseurl}api/v1/online_order/menu?tabtype=take_out&customer_key=f5b8d3bba00fb2763e6dee0497e5d3d00320ab703c0afabb2cea84e6eb8707661076456d33b4d1c040e381d83e7486a1&tabId=648c714f782a7b6f53ffad91`, this.posistrequestOptions)
  }
  async outofstock(customer_key:any): Promise<any>{
    return await this.http.get(`${this.poistbaseurl}api/v1/online_order/out_of_stock_items?customer_key=${customer_key}&tabId=64810b17543127317a895daa`, this.posistrequestOptions).toPromise()
  }
 
  public login(formdata:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/login`, formdata, this.basictokenRequest)
  }

  public signup(signupdata:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/register`, signupdata, this.basictokenRequest)
  }
  public otp(otpdata:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/send-email-otp`, otpdata, this.basictokenRequest)
  }
  public socialLogin(socialData:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/social-login`, socialData, this.basictokenRequest)
  }
  public editprofile(editData:any): Observable<any>{
    this.jwttokenRequest = {
      headers: new HttpHeaders({
      
      'Authorization': 'Bearer ' +(localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")||"null").access_token:""),
    })
    };
    return this.http.post(`${this.baseUrl}api/auth/update-profile/${editData.get("id")}`, editData, this.jwttokenRequest)
  }
  public updatePassword(passwordData:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/reset-password`, passwordData, this.basictokenRequest)
  }
  public storeLocator(location:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/get-stores`, location, this.basictokenRequest)
  }
  public addUSerPickUp(storeData:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/add-user-pickup`, storeData, this.basictokenRequest)
  }
  public listUSerPickUp(id:any): Observable<any>{
    this.jwttokenRequest = {
      headers: new HttpHeaders({
      
      'Authorization': 'Bearer ' +(localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")||"null").access_token:""),
    })
    };
    return this.http.get(`${this.baseUrl}api/auth/pickup-list/${id}`, this.jwttokenRequest)
  }


  public deletePickupApi(id:any): Observable<any>{
    return this.http.get(`${this.baseUrl}api/auth/delete-user-pickup/${id}`, this.basictokenRequest)
  }
  public setDefaultPickup(data:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/update-user-pickup`, data, this.basictokenRequest)
  }
  public homeApi(): Observable<any>{
    return this.http.get(`${this.baseUrl}api/auth/home-page`, this.basictokenRequest);
  }
  // public aboutUs(): Observable<any>{
  //   return this.http.post(`${this.baseUrl}api/auth/static-pages/about-us`,'');
  // }
  public foryouform(data:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/customer-contact`, data, this.basictokenRequest)
  }

  public franchisingForm(data:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/franchising-contact`, data, this.basictokenRequest)
  }

  public addtocart(data:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/add-to-cart`, data, this.basictokenRequest)
  }

  public listcart(userID:any, sessionID:any, storeid:any): Observable<any>{
    return this.http.get(`${this.baseUrl}api/auth/list-cart?user_id=${userID}&session_id=${sessionID}&store_id=${storeid}`, this.basictokenRequest)
  }
  public removeCart(cartProductId:any): Observable<any>{
    return this.http.delete(`${this.baseUrl}api/auth/delete-cart?cart_id=${cartProductId}`, this.basictokenRequest)
  }
  public updateCart(updateData:any): Observable<any>{
    return this.http.put(`${this.baseUrl}api/auth/update-cart`, updateData, this.basictokenRequest)
  }
  public placeorder(data:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/create-order`, data, this.basictokenRequest)
  }
  public emptyCart(userID:any, sessionID:any): Observable<any>{
    return this.http.delete(`${this.baseUrl}api/auth/empty-cart?user_id=${userID}&session_id=${sessionID}`,this.basictokenRequest)
  }

  public staticPages(url:any): Observable<any>{
    return this.http.get(`${this.baseUrl}api/auth/home-page-data/${url}`, this.basictokenRequest)
  }
  public company(): Observable<any>{
    return this.http.get(`${this.baseUrl}api/auth/home-page-data/company`, this.basictokenRequest)
  }
  public orderDetail(userID:any, orderId:any): Observable<any>{
    return this.http.get(`${this.baseUrl}api/auth/list-order?user_id=${userID}&order_id=${orderId}`, this.basictokenRequest)
  }

  public orderCancel(orderId:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/cancel-order`, orderId, this.basictokenRequest)
  }
  public orderStatus(orderId:any): Observable<any>{
    return this.http.get(`${this.baseUrl}api/auth/order-status?order_id=${orderId}`, this.basictokenRequest)
  }
  public orderList(userid:any): Observable<any>{
    return this.http.get(`${this.baseUrl}api/auth/list-order?user_id=${userid}`, this.basictokenRequest)
  }
  public aboutUs(): Observable<any>{
    return this.http.get(`${this.baseUrl}api/auth/home-page-data/about-us-new`, this.basictokenRequest)
  }
  public addpaymentCard(cardDetails:any): Observable<any>{
    this.jwttokenRequest = {
      headers: new HttpHeaders({
      
      'Authorization': 'Bearer ' +(localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")||"null").access_token:""),
    })
    };
    return this.http.post(`${this.baseUrl}api/auth/add-user-payment`,cardDetails, this.jwttokenRequest)
  }
  public forgotpassword(email:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/forget-password`,email, this.basictokenRequest)
  }
  public cardList(data:any): Observable<any>{
    this.jwttokenRequest = {
      headers: new HttpHeaders({
      
      'Authorization': 'Bearer ' +(localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")||"null").access_token:""),
    })
    };
    return this.http.post(`${this.baseUrl}api/auth/card-list`,data, this.jwttokenRequest)
  }
  public deletecard(id:any): Observable<any>{
    this.jwttokenRequest = {
      headers: new HttpHeaders({
      
      'Authorization': 'Bearer ' +(localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")||"null").access_token:""),
    })
    };
    return this.http.get(`${this.baseUrl}api/auth/delete-user-payment/${id}`, this.jwttokenRequest)
  }
  public paymentstatus(orderid:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/payment-status`, orderid, this.basictokenRequest)
  }
   public footerlinks(): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/footer-links`, "", this.basictokenRequest)
  }
  public patchRequest(orderidcvv:any): Observable<any>{
    return this.http.post(`${this.baseUrl}api/auth/patch-request`, orderidcvv, this.basictokenRequest)
  }
  
  // getCurrentLocation(lat:any,lng:any): Observable<any> {
  //   const YOUR_API_KEY = "AIzaSyCtxY79Gw82wrgI7nTVrgK4QeYtpNqWqW0";
  //   const url = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=amoeba&types=establishment&location=37.76999%2C-122.44696&radius=500&key=AIzaSyCtxY79Gw82wrgI7nTVrgK4QeYtpNqWqW0';
  //   return this.http.get(url, this.googlerequestOptions)
         
  //   }
}
