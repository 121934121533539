import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as e from 'express';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { DataService } from 'src/app/services/data.service';
import { SeoService } from 'src/app/services/seo.service';
declare var $:any;

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {
  loader:boolean = false;
  wholePage:boolean = false;
  productDetail:any ='';
  selectedSlices:any = "Large";
  slicesType:boolean = false;
  leftrightValue:any;
  thisvalue:any = this;
  quantity:number=1;
  customizedOrderedProduct:any= [];
  checkedLeftRight:any= "Whole"
  entityarray:any = ['regular dough', 'mozzarella cheese', 'home style italian tomato sauce'];
  entityDetails:Array<any> = [];
  categoryUrl:any;
  addedBtn:boolean = false;
  addBtnHS:boolean = true;
  combocompare:Array<any> =[];
  toppingquantity:any =1;
  toppingIndex:any = 0;
  hideproductsummaryleft:boolean = false;
  editProductarray:Array<any>= []
  wholeloader:boolean = false;
  productRate:number=0;
  resetproductItem:any;
  contentLoaded = true;
  addtocarflag:boolean =false;
  combo_details:Array<any> = [];
  addon_details:Array<any> = [];
  constructor(
    private dataservice: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService:ApiServiceService,
    private seoData:SeoService
  ) { }

  ngOnInit(): void {

    this.seoData.updatemetatags("");
    this.contentLoaded = true;
    this.dataservice.cartshow(true);
 this.dataservice.currantorderProductLo.subscribe(data=>{
  var menuItem = JSON.parse(localStorage.getItem("menuData") || "null")
        var subLink = this.activatedRoute.snapshot.params["id"];
        
      if(data == null){

      this.wholeloader= true;
      if(localStorage.getItem("userData") && localStorage.getItem("session_id")){
        var userID:any = JSON.parse(localStorage.getItem("userData") || "null").id;
        var sessionId :any=JSON.parse(localStorage.getItem("session_id") || "null")
      }else if(localStorage.getItem("userData")){
          var userID:any = JSON.parse(localStorage.getItem("userData") || "null").id;
          var sessionId :any=null
         }else if(localStorage.getItem("session_id")){
           var userID:any = null;
          var sessionId :any=JSON.parse(localStorage.getItem("session_id") || "null")
         }else{
          var userID:any = undefined;
          var sessionId :any=undefined;
         }
         this.productDetailNotAdded();
         var thisAng = this
              this.scripts(thisAng);
              var storeid;
              if(localStorage.getItem("pickupDeliverystores")){
               storeid =JSON.parse(localStorage.getItem("pickupDeliverystores") || "null").data.id;
              }else{
               storeid = null
              }
         this.apiService.listcart(userID, sessionId,storeid).subscribe((data:any)=>{
          if(data.data.length==0){
            this.router.navigate(['/']);
          }
          this.contentLoaded = false;

           if(data.status_code==200){
            if(data.data.some((datasome:any)=>subLink == datasome.id) == true){
            
              this.productdetailAdded(data);
              var thisAng = this
              this.scripts(thisAng);
            }else{
              var thisAng = this
              this.scripts(thisAng);

            }
           }
            // $('.product-option-col').removeClass('col-lg-6');
            // $('.product-option-col .product-option').removeClass('open');
         })

      }else{
        if(data.data.some((checkdata:any)=>subLink == checkdata.id) == false){
         this.productDetailNotAdded();
        }else{
          this.productdetailAdded(data);
        }
      }
    });

    var headerHeight = $('#site-header').outerHeight();
    var section1 = $('.pdp-banner').outerHeight();
    var totalheight = headerHeight + section1;
    $(window).on('scroll', function() {
      if ($(window).scrollTop() > totalheight) {
        $('.side-product-details').addClass("fixed");
      }
      else{
        $('.side-product-details').removeClass("fixed");
      }
    });
    var thisAng = this
    this.scripts(thisAng);


    // get category url
    this.categoryUrl = JSON.parse(localStorage.getItem("categoryUrl") || "null")
  }
  productdetailAdded(prodata:any){
   
    this.contentLoaded = false;
    var menuItem = JSON.parse(localStorage.getItem("menuData") || "null")
        var subLink = this.activatedRoute.snapshot.params["id"];
        var subLinkproduct = this.activatedRoute.snapshot.params["product"];
    var orderedSavedProducts = prodata.data;
              var filterSavedProduct = orderedSavedProducts.filter((data:any)=>{
            return subLink == data.id
          })
         
             if(filterSavedProduct.length == 1){
            this.entityDetails = filterSavedProduct[0].full_details.entity
            this.quantity = filterSavedProduct[0].full_details.quantity
            this.addedBtn = true;
            this.addBtnHS = false;

            var filterArray = menuItem.filter((data:any)=>{
              return subLinkproduct == data._id;
            })
           
            this.productDetail = filterArray[0]
            this.productRate = filterSavedProduct[0].full_details.rate;
            this.editProductarray = filterSavedProduct[0].full_details.editProduct;
            this.productDetail.configuration.entities.forEach((frtArr:any, i:any)=>{
              frtArr.levels[0].categories.forEach((secArr:any,j:any) => {
                secArr.items.forEach((thArr:any, k:any)=>{
                  var itemImage = menuItem.filter((data:any)=>{
                    return thArr._id == data._id;
                  })
                  thArr.itemImage_png = itemImage[0]?.itemImage_png ;
                  thArr.addedItem = false;
                  thArr.onSides = "Whole";
                  thArr.quantity = 1;
                })
              });
            });
            
        this.editProductarray.forEach((frtArr, i) => {
          let comboname = {
            "productName": frtArr.name,
            "items": frtArr.levels[0].categories
              .flatMap((secArr: any) => secArr.items)
              .filter((thArr: any) => thArr.addedItem)
          };
          this.combocompare.push(comboname);
        });
       

            this.wholeloader= false;
            this.wholePage = true;
            var thisAng = this
        this.scripts(thisAng);
          }

          var filterArrayreset = menuItem.filter((data:any)=>{
            return subLink.replace(/_/g, " ") == data.name.toLowerCase();
          })


          // reset function
          // this.resetproductItem = filterArrayreset[0].configuration.entities;
          // this.resetproductItem.forEach((frtArr:any, i:any)=>{
          //   frtArr.levels[0].categories.forEach((secArr:any,j:any) => {
          //     secArr.items.forEach((thArr:any, k:any)=>{
          //       thArr.addedItem = false;
          //       // thArr.onSides = "Whole";
          //        thArr.onSides = "Whole";
          //       thArr.quantity = 1;
          //     })
          //   });
          // })
          // 
  }

  productDetailNotAdded(){
    this.combocompare = [];
    var menuItem = JSON.parse(localStorage.getItem("menuData") || "null")
        var subLink = this.activatedRoute.snapshot.params["id"];
        var subLinkproduct = this.activatedRoute.snapshot.params["product"];
    var filterArray = menuItem.filter((data:any)=>{
      return subLinkproduct == data._id;
    })
    if(filterArray.length ==0){
      // this.router.navigate(['/']);
    }else{
      this.editProductarray = filterArray[0].configuration.entities;   
      this.dataservice.cartshow(true);

      if(localStorage.getItem("menuData")){
        this.addedBtn = false;
        this.addBtnHS = true;
        this.quantity = 1;

        this.productDetail = filterArray[0]
        this.productRate = this.productDetail.rate;
        this.entityDetails = [];
        this.editProductarray.forEach((frtArr, i)=>{
          frtArr.levels[0].categories.forEach((secArr:any,j:any) => {
            secArr.items.forEach((thArr:any, k:any)=>{
              var itemImage = menuItem.filter((data:any)=>{
                return thArr._id == data._id;
              })
              thArr.itemImage_png = itemImage[0]?.itemImage_png ;
              thArr.addedItem = false;
              thArr.onSides = "Whole";
              thArr.quantity = 1;
            })
          });
        })



        this.wholeloader= false;
        this.wholePage = true;
        // $('.product-option-col').removeClass('col-lg-6');
        // $('.product-option-col .product-option').removeClass('open');
        var thisAng = this
        this.scripts(thisAng);
      }else{
        // this.router.navigate(['/']);
      }
    }
  }

  leftRightCheck(lrValue:any, l:any, x:any,item:any, entity:any, category:any){
    this.checkedLeftRight = lrValue;
    var elem:any = document.getElementById(`leftRight${l}${x}`)
   
    elem.innerHTML = lrValue;
    this.addedBtn = false;
        this.addBtnHS = true;
    // this.entitingrediant(item, entity, category, l,x)
    // this.edittypeQuantity(item, entity, category,l,x)
    this.leftRightScript(x);
  }


  async pushedOrdered(name:any){

 
    if(await this.productDetail.category.addon_customize== true){
      this.combo_details=[];
      this.entityDetails.forEach(async (entities:any)=>{
        await entities.category.forEach(async (categorys:any) => {
         await categorys.items.forEach(async (items:any) => {
             await this.addon_details.push(
              {
                "_id": items._id,
          "name": items.name,
          "rate": items.rate,
          // "onSides": items,
          "onSides": "Whole",
          "quantity": items.quantity
              }
            )
          });
        });
      })
     } else if(await this.productDetail.category.isCombo== true){
      this.addon_details=[];

      for (const editProductarray of this.editProductarray) {
        for (const categories of editProductarray.levels[0].categories) {
          for (const items of categories.items){
          if (items.addedItem) {
            this.combo_details.push({
              "_id": items._id,
              "name": items.name,
              "rate": items.rate,
              "onSides": "Whole",
              "quantity": items.quantity
            });
          }
        }
        }
      }

     }
     
  // add to car in apis
  this.loader = true
  if(localStorage.getItem("userData")){
    var cartProduct:any ={
      "productDetail":this.productDetail,
      "productName": name,
      "sliceType": this.selectedSlices,
      // "pizzaIngrediants": this.entityarray,
      "rate": this.productRate,
      "quantity": this.quantity,
      "categoryUrl": this.activatedRoute.snapshot.params["slug"],
      "productUrl": this.activatedRoute.snapshot.params["product"],
      "entity": this.entityDetails,
      "editProduct": this.editProductarray,
      "store_id": 2,
      "user_id": JSON.parse(localStorage.getItem("userData") || "null").id,
      "session_id": null,
      "cart_id": this.activatedRoute.snapshot.params["id"],
      "combo_details":this.combo_details,
      "addon_details":this.addon_details
    }
  }else if(localStorage.getItem("session_id")){
    var cartProduct:any ={
      "productDetail":this.productDetail,
      "productName": name,
      "sliceType": this.selectedSlices,
      // "pizzaIngrediants": this.entityarray,
      "rate": this.productRate,
      "quantity": this.quantity,
      "categoryUrl": this.activatedRoute.snapshot.params["slug"],
      "productUrl": this.activatedRoute.snapshot.params["product"],
      "entity": this.entityDetails,
      "editProduct": this.editProductarray,
      "user_id": null,
      "session_id": JSON.parse(localStorage.getItem("session_id") || "null"),
      "cart_id": this.activatedRoute.snapshot.params["id"],
      "combo_details":this.combo_details,
      "addon_details":this.addon_details
    }
  }
  else{
    var cartProduct:any ={
      "productDetail":this.productDetail,
      "productName": name,
      "sliceType": this.selectedSlices,
      // "pizzaIngrediants": this.entityarray,
      "rate": this.productRate,
      "quantity": this.quantity,
      "categoryUrl": this.activatedRoute.snapshot.params["slug"],
      "productUrl": this.activatedRoute.snapshot.params["product"],
      "entity": this.entityDetails,
      "editProduct": this.editProductarray,
      "session_id": null,
      "user_id": null,
      "cart_id": this.activatedRoute.snapshot.params["id"],
      "combo_details":this.combo_details,
      "addon_details":this.addon_details
    }
  }

  
  this.apiService.addtocart(cartProduct).subscribe((data:any)=>{
  
    if(data.status_code == 200){
  
      setTimeout(() =>{
        this.dataservice.productItem();
        this.ngOnInit();
        this.combo_details=[];
        this.addtocarflag = false;
      },2000)
      // window.location.reload();
      this.addedBtn = true;
      this.addBtnHS = false;
      this.loader = false;
      if(data.data.session_id != null){
        localStorage.setItem("session_id",JSON.stringify(data.data.session_id))
      }
    }
  })
  
  
  
    }
  listClick(event:any, item:any){
    this.selectedSlices = item;
    this.slicesType = true;
    this.addedBtn = false;
    this.addBtnHS = true;
  }

  async entityFunction(item:any, entity:any, category:any, toppingquantity:any,l:any, x:any, productDetail:any, entityItems:any){
    var categoryType = category.replace(/"/g, "")
    this.addtocarflag = true;
    this.addedBtn = false;
      this.addBtnHS = true;
      var elemWholeLeftR:any = document.getElementById(`leftRight${l}${x}`)
      var quentityEntItem:any = document.getElementById(`numberPizza${l}${x}`)

    // edit functions starts
    if(this.editProductarray.some((a:any)=>a.name == entity)== true){
      var firstedArr= this.editProductarray.filter((edfirst:any)=>{
        return edfirst.name == entity;
      })

      if(firstedArr[0].levels[0].categories.some((b:any)=>b.categoryName == category)== true){
        var secondedArr= firstedArr[0].levels[0].categories.filter((edSec:any)=>{
        return edSec.categoryName == category;
      })
      if(secondedArr[0].items.some((c:any)=>c._id == entityItems._id)== true){

         var thirdArr = secondedArr[0].items.filter((edThird:any)=>{
            return edThird._id == entityItems._id
           })
           if(productDetail.category.addon_customize == true){
            if(thirdArr[0].addedItem == true){
              thirdArr.map((d:any)=>{
               d.addedItem = false;
               d.onSides = "Whole";
               d.quantity = 1;
             })
             }else{
              thirdArr.map((d:any)=>{
                d.addedItem = true;
              })
             }
           }else if(productDetail.category.isCombo == true){
            
            await this.editProductarray.forEach((product: any) => {

             
             if(entity == product.name){
              product.levels[0].categories.forEach((category: any) => {
                category.items.forEach((item: any) => {
                 
                  if(item.category_id == thirdArr[0].category_id){
                    item.addedItem = false
                  }
                 
             
                });
              });
             }
                 
               })
                if(this.combocompare.length != 0){
                  let combo = this.combocompare.find((combo:any) => combo.productName == entity);
                  if(combo){
                    combo.items = combo.items.filter((item:any) => item.category_id != thirdArr[0].category_id);
                    combo.items.push(thirdArr[0]);
                    thirdArr[0].addedItem = combo.items.some((item:any) => item.category_id == thirdArr[0].category_id);
                  }
                }
            //  if(thirdArr[0].addedItem == true){
            //   thirdArr.map((d:any)=>{
            //    d.addedItem = false;
            //    d.onSides = "Whole";
            //    d.quantity = 1;
            //  })
            //  }else{
            //   thirdArr.map((d:any)=>{
            //     d.addedItem = true;
            //   })
            //  }
           }
          



      }
      }

    }

// edit function end

     if(this.entityDetails.length == 0){
      if(productDetail.category.isCombo == false){
      }
      this.addtocarflag = true;
      this.entityDetails.push(
        {
          "entities": entity,
          "category":[{
            "categoryName": categoryType,
            "items":[{
              "name":item,
              "quantity": quentityEntItem.innerHTML,
              "_id": entityItems._id,
              "rate": entityItems.rate,
              "onSides": "Whole",
              // "onSides": elemWholeLeftR.innerHTML

            }]
          }]
        }

      )
      this.rateaddition();
     }else{
if(productDetail.category.isCombo == true){
  // this.entityDetails = [];
  var comboflag = true;
  this.addentityinArr(item, entity, category, toppingquantity,l, x, productDetail, entityItems, comboflag)

}else if(productDetail.category.addon_customize == true){
  var comboflag = false;
  this.addentityinArr(item, entity, category, toppingquantity,l, x, productDetail, entityItems, comboflag)
}

     }


    // add price for combo and addonitems
  


  }


addentityinArr(item:any, entity:any, category:any, toppingquantity:any,l:any, x:any, productDetail:any, entityItems:any, comboflag:boolean){
  var categoryType = category.replace(/"/g, "");
  var elemWholeLeftR:any = document.getElementById(`leftRight${l}${x}`)
  var quentityEntItem:any = document.getElementById(`numberPizza${l}${x}`)
  if(this.entityDetails.some((e:any)=> e.entities == entity) == true){
    
    var categoryArray =  this.entityDetails.filter((obj:any) => {
      return obj.entities == entity;
    });
    if(categoryArray[0].category.some((f:any)=> f.categoryName == categoryType) == true){
      var itemArray = categoryArray[0].category.filter((Catitem:any)=>{
        return Catitem.categoryName == categoryType
      })
       if(itemArray[0].items.some((g:any)=>g._id == entityItems._id)== true){
        
        quentityEntItem.innerHTML = 1;
        this.toppingquantity = 1;
        itemArray[0].items = itemArray[0].items.filter((h:any)=>{
         return h._id.indexOf(entityItems._id) !== 0;
        })
        this.rateaddition();
        if(itemArray[0].items.length == 0){
          this.addtocarflag = false;
        }else{
          this.addtocarflag = true;
        }
       }else{
        this.addtocarflag = true;
        
        
        itemArray[0].items.push({
                  "name":item,
                  "quantity": quentityEntItem.innerHTML,
                  "_id": entityItems._id,
              "rate": entityItems.rate,
              "onSides": "Whole",
                  // "onSides": elemWholeLeftR.innerHTML
        })
        this.rateaddition();
       }
    }else{
      if(comboflag == false){
        this.addtocarflag = true;
        this.rateaddition();
      }
      categoryArray[0].category.push({
              "categoryName": categoryType,
              "items":[{
                "name":item,
                "quantity": quentityEntItem.innerHTML,
                "_id": entityItems._id,
              "rate": entityItems.rate,
              "onSides": "Whole",
                // "onSides": elemWholeLeftR.innerHTML

              }]
            })
    }
}else{
 
  this.entityDetails.push(
    
    {
      "entities": entity,
      "category":[{
        "categoryName": categoryType,
        "items":[{
          "name":item,
          "quantity": quentityEntItem.innerHTML,
          "_id": entityItems._id,
              "rate": entityItems.rate,
              "onSides": "Whole",
          // "onSides": elemWholeLeftR.innerHTML

        }]
      }]
    }

  )
}
}


  
toppingplus(index:any,l:any, item:any, num:any, entity:any, category:any, entityItems:any,productDetail:any){
  var span_Text:any = document.getElementById(`numberPizza${l}${index}`);
  this.addedBtn = false;
  this.addBtnHS = true;
  this.addtocarflag = true;
// if(this.toppingIndex == index ){
if(productDetail.category.isCombo == true){
  
  if(parseInt(span_Text.innerHTML)<1){
    
  span_Text.innerHTML = ++ span_Text.innerHTML;
 
  this.entitingrediant(item, entity, category, l,index, entityItems)
  this.edittypeQuantity(item, entity, category,l,index, entityItems)
 

  }
}else if(productDetail.category.addon_customize == true){
  
  if(parseInt(span_Text.innerHTML)<3){
    
    
  span_Text.innerHTML = ++span_Text.innerHTML;
  
  

  this.entitingrediant(item, entity, category, l,index, entityItems)
  this.edittypeQuantity(item, entity, category,l,index, entityItems)
 

  }

}

// }
// else{

//   this.toppingIndex = index;
//   this.toppingquantity = 1;
//   var span_Text:any = document.getElementById(`numberPizza${l}${index}`);
//   span_Text.innerHTML = ++this.toppingquantity;
//   this.productRate = this.productRate +entityItems.rate 
//   this.entitingrediant(item, entity, category,l,index)
//   this.edittypeQuantity(item, entity, category,l,index)
// }
}
toppingminus(index:any,l:any, item:any, num:any, entity:any, category:any,entityItems:any,productDetail:any){
  var categoryType = category.replace(/"/g, "")
  this.addtocarflag = true;
  this.addedBtn = false;
  this.addBtnHS = true;
  var span_Text:any = document.getElementById(`numberPizza${l}${index}`);
 
//  if(this.toppingIndex == index){
  if(span_Text.innerHTML>1){
    span_Text.innerHTML = --span_Text.innerHTML
    this.entitingrediant(item, entity, category,l,index, entityItems);
    this.edittypeQuantity(item, entity, category,l,index, entityItems)

  }
//  }else{
//   if(span_Text.innerHTML>1){
//     this.toppingquantity = --span_Text.innerHTML
//     this.productRate = this.productRate -entityItems.rate 
//     this.entitingrediant(item, entity, category,l,index);
//     this.edittypeQuantity(item, entity, category,l,index)
//   }
//  }

}
  entitingrediant(item:any, entity:any, category:any, l:any,x:any, entityItems:any){
    var elemWholeLeftR:any = document.getElementById(`leftRight${l}${x}`)
    var span_Text:any = document.getElementById(`numberPizza${l}${x}`);
    var categoryType = category.replace(/"/g, "")
    if(this.entityDetails.some((e:any)=> e.entities == entity) == true){
      var categoryArray =  this.entityDetails.filter((obj:any) => {
        return obj.entities == entity;
      });
      if(categoryArray[0].category.some((f:any)=> f.categoryName == categoryType) == true){
        var itemArray = categoryArray[0].category.filter((Catitem:any)=>{
          return Catitem.categoryName == categoryType
        })
         if(itemArray[0].items.some((g:any)=>g._id == entityItems._id)== true){
          var abc = itemArray[0].items.filter((h:any)=>{
           return h._id == entityItems._id;
          }).map((obj:any)=>{
            obj.quantity = parseInt(span_Text.innerHTML);
            // obj.onSides = elemWholeLeftR.innerHTML;
            obj.onSides = "Whole";
            return obj
          })
        }
      }
    }
    this.rateaddition();
  }

  edittypeQuantity(item:any, entity:any, category:any, l:any,x:any, entityItems:any){
    var elemWholeLeftR:any = document.getElementById(`leftRight${l}${x}`)
    var span_Text:any = document.getElementById(`numberPizza${l}${x}`);
    if(this.editProductarray.some((a:any)=>a.name == entity)== true){
      var firstedArr= this.editProductarray.filter((edfirst:any)=>{
        return edfirst.name == entity;
      })

      if(firstedArr[0].levels[0].categories.some((b:any)=>b.categoryName == category)== true){
        var secondedArr= firstedArr[0].levels[0].categories.filter((edSec:any)=>{
        return edSec.categoryName == category;
      })
      
      if(secondedArr[0].items.some((c:any)=>c._id == entityItems._id)== true){
        secondedArr[0].items.filter((edThird:any)=>{
         return edThird._id == entityItems._id
        }).map((d:any)=>{
          // d.onSides = elemWholeLeftR.innerHTML;
          d.quantity = parseInt(span_Text.innerHTML);
          d.onSides = "Whole";
          return d;
        })
      }
      }

    }
    this.rateaddition();
  }


  partDivision(index:any, value:any){
    this.leftrightValue = index;
    this.leftRightScript(index)
  }

  resetproduct(){
    // jQuery('.product-option-col').removeClass('col-lg-6');
    // jQuery('.product-option-col .product-option').removeClass("open")
    // jQuery('.product-option-col').attr("ng-reflect-ng-class","");
    // jQuery('.product-option-col .product-option').attr("ng-reflect-ng-class","");
    // this.entityDetails = [];
    // this.editProductarray=this.resetproductItem;
    // console.log(this.resetproductItem)
    // this.quantity = 1;
    this.ngOnInit();
    
  }
  /* alsoConfig-slider */
  alsoConfig = {
    arrows:true,
    infinite: true,
    dots:false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  /* alsoConfig-end */
  /* comboofferslider-slider */
  addproductConfig = {
    arrows:false,
    infinite: true,
    dots:false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  /*-- quantity-number-start --*/

  plus(){
    this.addedBtn = false;
    this.addBtnHS = true;
      if(this.quantity && this.quantity <999){
        this.quantity=++this.quantity;
        this.rateaddition();
      }

      
  }
  minus(){
    this.addedBtn = false;
    this.addBtnHS = true;
    if(this.quantity && this.quantity >1){
      this.quantity=--this.quantity;
      this.rateaddition();
    }
    
  }

  rateaddition(){
    this.addtocarflag = true;
    
    if(this.productDetail.category.addon_customize == true){
      var entityrate=0;
     
      if(this.entityDetails.length==0){
        this.productRate = this.productDetail.rate * this.quantity
      }else{
        setTimeout(() => {
          this.entityDetails.forEach(async (entities:any)=>{
            await entities.category.forEach(async (categorys:any) => {
             
             await categorys.items.forEach(async (items:any) => {
              entityrate = entityrate+(items.rate * items.quantity);
              
             })
            })
          })
          this.productRate =(this.productDetail.rate * this.quantity)+(entityrate*this.quantity); 
        }, 100); 
      }
    }else if(this.productDetail.category.isCombo== true){
      this.productRate = this.productDetail.rate * this.quantity
    }
  }

  /*-- quantity-number-end --*/


  scripts(thisAng:any){
    $(document).ready(function(){
      setTimeout(function(){
      jQuery('.add-new-box #additem').on('click', function(e){
        e.preventDefault();
        jQuery('.add-new-box.is-visible').removeClass('is-visible');
        jQuery(this).parents('.add-new-box').addClass('is-visible');
      });
      jQuery('.size-close').on('click', function(e){
          e.preventDefault();
          jQuery(this).parents('.add-new-box').removeClass('is-visible');
      });

      /*--customize-top-tab-start--*/
      jQuery('.productconfiguratortab li a').on('click', function(){
        var target = jQuery(this).attr('data-tab');
        jQuery('.productconfiguratortab li a').removeClass('active');
        jQuery(this).addClass('active');
        jQuery("#"+target).fadeIn('slow').siblings(".tab-pane").hide();
        $('.productconfiguratorslider').slick('setPosition');
        return false;
      });
      /*--customize-top-tab-end--*/
      /*--customize-second-tab-start--*/
      jQuery('.productsubcategory li a').on('click', function(){
        var target = $(this).attr('data-tab');
        jQuery('.productsubcategory li a').removeClass('active');
        jQuery(this).addClass('active');
        jQuery("#"+target).fadeIn('slow').siblings(".tab-pane").hide();
        return false;
      });
      /*--customize-second-tab-end--*/
      /*--customize-box-start--*/
      // jQuery('.product-option').on('click', function(e){
        
      //   e.preventDefault();
      
      //   jQuery(this).parents('.product-option-col').toggleClass('col-lg-6');
      //   jQuery(this).toggleClass('open');
      // });

      jQuery(function() {
        var list = jQuery('.js-dropdown-list');
        var link = jQuery('.js-link');
        link.on('click', function(e) {
          e.preventDefault();
          list.slideToggle(200);
        });
        list.find('.tab-option').on('click', function() {
          var text = jQuery(this).html();
          var icon = '<span class="drowicon"></span>';
          link.html(text+icon);
          list.slideToggle(200);
        });
      });



      jQuery(".product-number, .icons-wrap").on('click', function(e) {
        e.stopPropagation();
      });
      if(window.matchMedia("(max-width: 991px)").matches){
        jQuery('.product-option').on('click', function(e){
          e.preventDefault();
          jQuery('.product-option-col.col-md-8').removeClass('col-md-8');
          jQuery(this).parents('.product-option-col').addClass('col-md-8');
        });
      }
      if(window.matchMedia("(max-width: 767px)").matches){
        $('.productconfiguratorslider').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          // asNavFor: '.topofferslider',
          autoplay: false,
          arrows: false,
          dots: false,
        });
      }
      },1000);
    });
  }

  leftRightScript(index:any){
      /*--customize-box-dropdown-end--*/
      jQuery(".leftRight"+index).on('click', function(e) {
     
        e.stopPropagation();
        if (jQuery(this).hasClass('active')) {
          // jQuery('.product-option').addClass('open');
        }
        else {
          jQuery(".leftRight"+index).removeClass('active');
          jQuery(this).addClass('active');
        }
      });
  }
  upgradepopupClose():void{
    document.body.classList.remove('upgradepopup')
  }
  upgradepopupShow():void{
    document.body.classList.add('upgradepopup')
  }

  // doughpopupClose():void{
  //   document.body.classList.remove('doughpopup')
  // }
  doughpopupShow():void{
    document.body.classList.add('doughpopup')
  }
  outofstockpopupShow():void{
    document.body.classList.add('outstockpopupshow')
  }
  outstockpopupClose():void{
    document.body.classList.remove('outstockpopupshow')
  }

  slickInit(e: any) {
    //console.log('slick initialized');
  }
}
