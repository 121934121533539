import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { DataService } from 'src/app/services/data.service';
declare var $:any;

@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.scss']
})
export class ProductListingComponent implements OnInit {
  menudata:any;
  newFilterManu:any;
  descName:any;
  quickAddShow:boolean = true;
  findMeInternal:boolean = true;
  selectedSlices:any = "Large";
  pickupDeliverystores:any;
  contentLoaded = false;
  outofstockdata:any
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService:ApiServiceService,
    private dataservice: DataService
  ) { }

 public async ngOnInit() {
    this.dataservice.cartshow(true);
    this.scripts();
    // if(this.activatedRoute.snapshot.params['slug'] == "create-your-own"){
    //   this.quickAddShow = false;
    // }


if(localStorage.getItem("pickupDeliverystores")){
  this.findMeInternal = false;
  
}else{
  this.findMeInternal = true;
}
await this.outofstockfunction();
this.menudata = localStorage.getItem("menuData");
this.getproductData(JSON.parse(this.menudata), this.outofstockdata);

// on change store location
this.dataservice.currantPickDeliv.subscribe(async data=>{
  
 if(data == "SetPickup"){
  this.contentLoaded = false;
    this.pickupDeliverystores = localStorage.getItem("pickupDeliverystores");
    let addressData = JSON.parse(this.pickupDeliverystores);
    await this.outofstockfunction();
    this.apiService.getMenu("take_out", addressData.data.customer_key).subscribe(data=>{
      this.getproductData(data, this.outofstockdata)
      localStorage.setItem('menuData', JSON.stringify(data));
      
    })
    
  }
})

  }
 async outofstockfunction():Promise<any>{
  this.pickupDeliverystores = localStorage.getItem("pickupDeliverystores");
  let addressData = JSON.parse(this.pickupDeliverystores);
  if(addressData != null){
    await this.apiService.outofstock(addressData.data.customer_key).then(data=>{
      console.log(data, "ofs");
       this.outofstockdata= data;
    })
  }else{
    this.outofstockdata= [];
  }
  }


// geting product data through URL
  async getproductData(data:any, outofstock:any){
    
    let mapAddonItems = await JSON.parse(localStorage.getItem("mapAddonItems") || "null")
    console.log(mapAddonItems)
    this.descName = this.activatedRoute.snapshot.params['slug'].replace(/-/g, " ").toUpperCase()
    var menuItem = data;
    if(!localStorage.getItem("menuData")){
      this.router.navigate(['/'])
    }
    this.newFilterManu = menuItem.filter((item:any)=>{
      console.log(this.activatedRoute.snapshot.params['slug']);
      return item.category.name.toLowerCase() == `${this.activatedRoute.snapshot.params['slug'].replace(/-/g, " ")}`;
    })
    this.newFilterManu.forEach((addmanu:any)=>{
      addmanu["out_of_stock"] = 0;
      addmanu.category["addon_customize"]= false;
    })
    outofstock.forEach((element:any) => {
      this.newFilterManu.forEach((manu:any)=>{
        if(element.item_id == manu._id){
          
          manu["out_of_stock"] = 1;
          console.log(manu)
        }
      })
    });
    await this.newFilterManu.forEach((newMenu:any)=>{
      mapAddonItems.forEach((addonItems:any)=>{
        addonItems.mapAddonItems.forEach((mapItems:any)=>{
          if(newMenu._id == mapItems._id){
            newMenu.category["addon_customize"]= true;
            newMenu["configuration"]=
              JSON.parse(localStorage.getItem("entity") || "null")
            
          }
        })
        
      })
    })

    await this.newFilterManu.forEach((addingEntityItem:any)=>{
      
      if(addingEntityItem.category.addon_customize== true){
        
        mapAddonItems.forEach((mapAddonItem:any) => {
          if(addingEntityItem.configuration != null){
            addingEntityItem.configuration.entities.forEach((entities:any) => {
              if(mapAddonItem.category.superCategoryName == entities.name){
                entities.levels[0].categories.forEach((categories:any) => {
                  if(categories.categoryName == mapAddonItem.category.name){
                    categories.items.push(mapAddonItem);
                  }
                });
              }
            });
          }
          
        });
        
        
      }else if(addingEntityItem.category.isCombo == true){
        mapAddonItems.forEach((mapAddonItem:any) => {
          if(addingEntityItem.configuration != null){
            addingEntityItem.configuration.entities.forEach((entities:any) => {
              if(mapAddonItem.category.superCategoryName == entities.name){
                entities.levels[0].categories.forEach((categories:any) => {
                  categories.items =  categories.items.filter((value:any, index:any, self:any)=>{
                    return index === self.findIndex((t:any) => (
                       t.name === value.name
                    ))
                  });
                
                  if(categories.categoryName == mapAddonItem.category.name){
                  categories.items.push(mapAddonItem);
                    
                  }
                });
              }
            });
          }
          
        });
      }
      
    })
    let manuitem = await JSON.parse(localStorage.getItem("menuData")|| "null")
  
        // await manuitem.forEach((manu:any, index:any) => {
          await this.newFilterManu.forEach((element:any, index:any) => {
            manuitem= manuitem.filter((item:any) => item._id != element._id);
            manuitem.push(element)
            // if(manu._id == element._id){
            //   manuitem.pop(manu)
            //   manuitem.push(element)
              // console.log(filteredPeople, element);
            // }
          });
        // });
        localStorage.setItem('menuData', JSON.stringify(manuitem));
        console.log(manuitem,"filters");
        console.log(this.newFilterManu,"filters2");
    this.contentLoaded = true;
  }

  
  customizeBtn(manuItems:any){
console.log(manuItems.name.replace(/ /g, "-").toLowerCase());
localStorage.setItem('orderedProductDetail', JSON.stringify(manuItems));
this.router.navigate([
  'product-details',
  this.activatedRoute.snapshot.params['slug'],
  manuItems.name.replace(/ /g, "-").toLowerCase()
])
  }

  listClick(event:any, item:any){
    this.selectedSlices = item;
  }
  quickAddBtn(manuItems:any){
    // this.selectedSlices = "Large";
    console.log(manuItems)
    if(localStorage.getItem("userData")){
      var cartProduct:any ={
        "productDetail":manuItems,
        "productName": manuItems.name,
        "sliceType":"",
        "pizzaIngrediants": [],
        "rate": manuItems.rate,
        "quantity": 1,
        "categoryUrl": this.activatedRoute.snapshot.params["slug"],
        "productUrl": "",
        "entity": [],
        "editProduct": [],
        "store_id": 2,
        "user_id": JSON.parse(localStorage.getItem("userData") || "null").id,
        "session_id": null,
        "combo_details":[],
    "addon_details":[],
      }
    }else if(localStorage.getItem("session_id")){
      var cartProduct:any ={
        "productDetail":manuItems,
        "productName": manuItems.name,
        "sliceType":"",
        "pizzaIngrediants": [],
        "rate": manuItems.rate,
        "quantity": 1,
        "categoryUrl": this.activatedRoute.snapshot.params["slug"],
        "productUrl": "",
        "entity": [],
        "editProduct": [],
        "store_id": 2,
        "user_id": null,
        "session_id": JSON.parse(localStorage.getItem("session_id") || "null"),
        "combo_details":[],
    "addon_details":[],
      }
    }
    else{
      var cartProduct:any ={
        "productDetail":manuItems,
        "productName": manuItems.name,
        "sliceType":"",
        "pizzaIngrediants": [],
        "rate": manuItems.rate,
        "quantity": 1,
        "categoryUrl": this.activatedRoute.snapshot.params["slug"],
        "productUrl": "",
        "entity": [],
        "editProduct": [],
        "store_id": 2,
        "session_id": null,
        "user_id": null,
        "combo_details":[],
      "addon_details":[],
      }
    }
    console.log(cartProduct, "cartProduct");
    this.apiService.addtocart(cartProduct).subscribe((data:any)=>{
      console.log(data, "api");
      if(data.status_code == 200){
    
        setTimeout(() =>{
          this.dataservice.productItem();
          // this.ngOnInit();
        },1000)
        if(data.data.session_id != null){
          localStorage.setItem("session_id",JSON.stringify(data.data.session_id))
        }
      }
    })
  }
  addToCart(manuItems:any){
console.log(manuItems);
  }

  /* justslider-slider */ 
  // justConfig = { 
  

  /* alsoConfig-slider */ 
  alsoConfig = { 
    arrows:true,
    infinite: true,
    dots:false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  /* alsoConfig-end */
  scripts(){ 
    $(document).ready(function(){
      jQuery('.plp-box #additem').on('click', function(e){
        e.preventDefault();
        jQuery('.plp-box.is-visible').removeClass('is-visible');
        jQuery(this).parents('.plp-box').addClass('is-visible');
      });
      jQuery('.size-close').on('click', function(e){
          e.preventDefault();
          jQuery(this).parents('.plp-box').removeClass('is-visible');
      });


      // jQuery("#additem").on('click', function(){
      //   jQuery(".additem-popup").addClass("is-visible");
      // });
      // jQuery(".size-close").on('click', function(){
      //   jQuery(".additem-popup").removeClass("is-visible");
      // });
      jQuery("#findpopup2").on('click', function(){
        jQuery(".find-popup").addClass("is-visible");
      });
      jQuery(".size-close").on('click', function(){
        jQuery(".find-popup").removeClass("is-visible");
      });
      jQuery('.findpopuptab li a').on('click', function(){
        var target = $(this).attr('data-tab');
        jQuery('.findpopuptab li a').removeClass('active');
        jQuery(this).addClass('active');
        jQuery("#"+target).fadeIn('slow').siblings(".tab-pane").hide();
        return false;
      });
    
    });
  }
  slickInit(e: any) {
    //console.log('slick initialized');
  }
}
