import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ApiServiceService } from '../services/api-service.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-pickup-location-popup',
  templateUrl: './pickup-location-popup.component.html',
  styleUrls: ['./pickup-location-popup.component.scss']
})
export class PickupLocationPopupComponent implements OnInit {
  storelistShow:boolean = false;
  bannerNoStores:boolean = true;
 notstoreData:boolean = true;
 storeDataAvail:boolean = false;
 pickupgoBtn:boolean = false;
 bar:any ;
 pickupId:any;
 pickupDeliverystores:any = localStorage.getItem("pickupDeliverystores");
 longitude:any;
 latitude:any;
 noDelivery:boolean = false;
 currentCity:any;
 mapLoader:boolean = false;
 inputmap:boolean = true;
 storedatanotavail:any;
 private geoCoder: any;
 storeData:any
 @ViewChild('search')
 public searchElementRef!: ElementRef;
  constructor(
    private apiService:ApiServiceService, 
    private dataservice: DataService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    // document.getElementById("mapLoader")?.style.setProperty("display","none");
    this.mapLoader =false;
    this.mapInitialize()
  }
  FindsPopUpHide(): void {
    document.body.classList.remove('Find-open')
  }

  async pickupClick(type:any){
    
    navigator.geolocation.getCurrentPosition((resp): void => {
      
      this.getAddress(resp.coords.latitude, resp.coords.longitude);
    })   
     
  }

  async getAddress(lati:any, long:any): Promise<void> { 
   
    // document.getElementById("mapLoader")?.style.setProperty("display","block");
    this.mapLoader = true;
    // document.getElementById("inputmap")?.style.setProperty("display","none");
    this.inputmap = false;
     
    this.geoCoder = new google.maps.Geocoder();
    this.geoCoder.geocode({ 'location': { lat: lati, lng: long } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
      
          var add = results[0].formatted_address;
          var value = add.split(",");
    
          this.longitude = long;
          this.latitude = lati;
          this.currentCity = value[value.length - 3].replace(/[0-9]/g, '');
        
          let locateArray = {
           "lat":this.latitude,
           "long":this.longitude,
           "city":this.currentCity
        }
         
         this.apiService.storeLocator(locateArray).subscribe(store=>{
        
           if(store.status_code == 200){
            this.storedatanotavail = "";
            this.storeData = store.data;
            this.bannerNoStores = false;
            this.storelistShow = true;
            this.geoCoder = new google.maps.Geocoder;
 
            // document.getElementById("mapLoader")?.style.setProperty("display","none");
            this.mapLoader = false;
            // document.getElementById("inputmap")?.style.setProperty("display","block");
            this.inputmap = true;
            
            
           }else{
            this.storedatanotavail = store.message
            this.bannerNoStores = true;
            this.storelistShow = false;
            this.geoCoder = new google.maps.Geocoder;
 
            // document.getElementById("mapLoader")?.style.setProperty("display","none");
            this.mapLoader = false;
            // document.getElementById("inputmap")?.style.setProperty("display","block");
            this.inputmap = true;
           
           }
 
         })
          
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  
 }

 modelChangeFn(e:any){
  this.bar = e;
  if(e==""){
    this.bannerNoStores = true;
    this.storelistShow = false;
    this.storedatanotavail = "";
   this.mapInitialize()
  }else{
    this.mapInitialize()
  }
}

mapInitialize(){
  this.mapsAPILoader.load().then(() => {
    // this.getAddress();
    this.geoCoder = new google.maps.Geocoder;

    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
       
        //set latitude, longitude and zoom
      
        this.getAddress(place.geometry.location.lat(), place.geometry.location.lng());
      });
    });
  });
}

 selectClick(index:any, stores:any, pickup:any){
  this.pickupId = index;
  this.pickupgoBtn = true;
  if(localStorage.getItem("userData")){ 
    var storeData = {
      "user_id":JSON.parse(localStorage.getItem("userData")||"null").id,
      "location_id":stores.id
  }

    this.apiService.addUSerPickUp(storeData).subscribe(data=>{
  
      if(data.status_code == 200){
       this.dataservice.listAddress();
       this.dataservice.addressCheckoutfunction(true);
       document.body.classList.remove('Find-open')
      }
    })
  }else{
    if(localStorage.getItem("savedPickupLocation")){
      var arraysavedPickupLocation = JSON.parse(localStorage.getItem("savedPickupLocation") || "null");
      stores.by_default = 0;
   
      if(arraysavedPickupLocation.some((item:any)=>item.id == stores.id)==true){
        document.body.classList.remove('Find-open')
      }else{
        arraysavedPickupLocation.push(stores)
        localStorage.setItem('savedPickupLocation', JSON.stringify(arraysavedPickupLocation));
        this.dataservice.addressCheckoutfunction(true);
        document.body.classList.remove('Find-open')

      }
    }
  }
 
}

}
