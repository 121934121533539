import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import * as $ from "jquery";
import { ApiServiceService } from '../services/api-service.service';
import { DataService } from '../services/data.service';
declare var $:any;
// declare var $:any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isShown: boolean = false ;
  cartProduct:any;
  totalCost:any;
  addedCart:boolean = false;
  emptyCart:boolean = true
  carthideshow:boolean = true;
  cartCount:any =0;
  checkoutloader:boolean = false;
  footerlinks:any;
  removebtnloader:boolean = false;
  removebtnFlag:any;
  constructor(
    private dataservice: DataService,private router: Router, private activeRoute: ActivatedRoute,private apiService:ApiServiceService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.dataservice.currantcarthideshow.subscribe(data=>{
      if(data == true){
        this.carthideshow = true
      }else{
        this.carthideshow = false
      }
    })

    this.dataservice.currantorderProductLo.subscribe(data =>{
      if(data == null){
        if(localStorage.getItem("userData") && localStorage.getItem("session_id")){
          var userID:any = JSON.parse(localStorage.getItem("userData") || "null").id;
          var sessionId :any=JSON.parse(localStorage.getItem("session_id") || "null")
        }else if(localStorage.getItem("userData")){
          var userID:any = JSON.parse(localStorage.getItem("userData") || "null").id;
          var sessionId :any=null
         }else if(localStorage.getItem("session_id")){
           var userID:any = null;
          var sessionId :any=JSON.parse(localStorage.getItem("session_id") || "null")
         }
         var storeid;
         if(localStorage.getItem("pickupDeliverystores")){
          storeid =JSON.parse(localStorage.getItem("pickupDeliverystores") || "null").data.id;
         }else{
          storeid = null
         }
        
        this.apiService.listcart(userID, sessionId,storeid).subscribe((data:any)=>{
        
          if(data.status_code==200){
            if(data.data.length == 0){
              this.addedCart = false;
              this.emptyCart = true;
            }else{
              this.cartProduct = data.data;
              this.cartCount = data.count;
              this.addedCart = true;
              this.emptyCart = false;
              this.totalCost = data.data.reduce((accumulator:number, object:any) => {
                  return accumulator + parseInt(object.full_details.rate);
              }, 0);
            }
            
          }
        })
      }else{
        if(data.data.length == 0){
          this.cartCount = 0;
          this.addedCart = false;
          this.emptyCart = true;
        }else{
          this.cartProduct = data.data;
          this.totalCost = data.data.reduce((accumulator:number, object:any) => {
            return accumulator + parseInt(object.full_details.rate);
        }, 0);
          this.cartCount = data.count;
              this.addedCart = true;
              this.emptyCart = false;
        }
        
      }
    })

    this.scripts();


    this.apiService.footerlinks().subscribe((data:any)=>{
      this.footerlinks = new Array(data.data);
     
    })
  }

  editSelected(firstUrl:any, secUrl:any, productdata:any){
   
    this.router.navigate([
      'product-details',
      productdata.category_url,
      productdata.product_url,
      productdata.id
    ])
    
    // window.location.reload();
    // this.router.navigate(['/product-details/'+firstUrl+'/'+secUrl]);
    setTimeout(()=>{
      window.location.reload();
    }, 100);
  }


  async removeItem(item:any, index:any){
    this.removebtnloader = true;
    this.removebtnFlag = index;
    var urlId = window.location.href.split("/");
    this.apiService.removeCart(item.id).subscribe(data=>{
    
      if(data.status_code == 200){
        this.dataservice.productItem();
       setTimeout(() => {
        this.removebtnloader = false;
       }, 500);
      }
      
    })
    if(item.id == parseInt(urlId[urlId.length-1])){
  
    this.router.navigate(['/product-details/'+item.category_url+'/'+item.product_url]);
    }
    
  }
  checkoutBtn(){
    this.checkoutloader = true;

    if(localStorage.getItem("userData")){
      if(localStorage.getItem("pickupDeliverystores")){
        this.router.navigate(['/catalog/checkout']);
        this.checkoutloader = false;
  
        // setTimeout(() => {
        //   this.checkoutloader = false;
        //  }, 3000);
         this.toggleShow();
  
          jQuery(".addcard-top").toggleClass("current");
      }else{
        this.checkoutloader = false;
        jQuery(".find-popup").addClass("is-visible");
      }
      
    }else{
      this.checkoutloader = false;
      if(localStorage.getItem("pickupDeliverystores")){
        this.router.navigate(['/catalog/checkout-guest'])
        this.isShown = ! this.isShown;
        jQuery(".addcard-top").toggleClass("current");
      }else{
        jQuery(".find-popup").addClass("is-visible");
      }
      
    }
  }
  toggleShow() {
    this.isShown = ! this.isShown;
  }
  scripts(){

    $(document).ready(function(){
      // setTimeout(function(){
      //   jQuery('.product-features .feature-accordion').on('click',function(){
      //     jQuery(this).parents('.product-features').toggleClass('active')
      //   });
      // },3000);
      jQuery('.addcard-top').on('click', function(){
        jQuery(".addcard-top").toggleClass("current");
        jQuery("body").toggleClass("hidden-scroll-body");
      });
      jQuery('button').on('click', function(){
        jQuery("body").removeClass("hidden-scroll-body");
      });

      if(window.matchMedia("(max-width: 767px)").matches){
        jQuery(".pizzaxsheading").on('click', function() {
          if (jQuery(this).hasClass('active')) {
          jQuery(this).removeClass('active');
          jQuery(this).siblings('.footerlink').slideUp();
          }
          else {
          jQuery(".pizzaxsheading").removeClass('active');
          jQuery('.footerlink').slideUp();
          jQuery(this).addClass('active');
          jQuery(this).siblings('.footerlink').slideDown();
          }
        });
      }
      
    });
    
  }


  forceNavigate(name: string) {
    window.location.href = `/about-us#${name}`
    
      //  this.router.navigate(['/about-us'], { fragment: name });
  }

  ngAfterViewInit(){
    setTimeout(function(){
      let url = window.location.href;
      url = url.split('#')[1]
    if(url){
        jQuery('html, body').animate({
            scrollTop: $('#'+url).offset().top
        }, 'slow');
    }

  },500);

 }


}
