<!-- delete saved stores popup starts-->
<div class="find-popup3">
  <div class="find-overlay" id="findpopup"></div>
  <div class="find-wrapper find-transition delite-popup-wraper">
      <div class="find-header">
          <button class="find-close" (click)="doughpopupClose()"><img src="assets/images/icons/close-icon.png" alt="close-icon"> </button>
          <div class="user-icon">
            <i class="fa fa-bell" aria-hidden="true"></i>
          </div>
          <div class="pizzalgheading">D'ough!</div>
      </div>
    <div class="find-body">
      <p class="pizzaparaDelete">Something went wrong, Please try again Later</p>
      <div class="find-content">
          <button class="btn-delete-yes" (click)="doughpopupClose()">RETRY</button>
      </div>
    </div>
  </div>
</div>
<!-- delete saved stores popup end-->
