import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByKey',
})
export class SortByKeyPipe implements PipeTransform {
  transform(array: any[], key: string): any[] {
    if (!array || !key) return array; // Handle invalid inputs
    return [...array].sort((a, b) => a[key] - b[key]);
  }
}
