import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private titleService: Title, private metaService: Meta) { }

  async updatemetatags(metatags:any){
    const seoTitle = await metatags;
    this.titleService.setTitle(seoTitle.meta_title ? seoTitle.meta_title : 'Pza Pizzeria');
    this.metaService.addTag({property: 'og:title', content: seoTitle.meta_title ? seoTitle.meta_title : 'Pza Pizzeria'});
    this.metaService.addTag({property: 'twitter:title', content: seoTitle.meta_title ? seoTitle.meta_title : 'Pza Pizzeria'});
    this.metaService.addTag({name: 'description', content: seoTitle.meta_description ? seoTitle.meta_description : 'Pza Pizzeria'});
    this.metaService.addTag({property: 'og:description', content: seoTitle.meta_description ? seoTitle.meta_description : 'Pza Pizzeria'});
    this.metaService.addTag({property: 'twitter:description', content: seoTitle.meta_description ? seoTitle.meta_description : 'Pza Pizzeria'});
    // this.metaService.updateTag({property: 'og:image', content: seoTitle ? seoTitle : 'https://pizzapizza.lyxelandflamingotech.in/assets/images/logo.png'});
    // this.metaService.updateTag({property: 'twitter:image', content: seoTitle ? seoTitle : 'https://pizzapizza.lyxelandflamingotech.in/assets/images/logo.png'});
    // this.metaService.updateTag({property: 'og:url', content: seoTitle});
    // this.metaService.updateTag({property: 'twitter:url', content: seoTitle});
  }
}
