<div class="size-popup find-popup">
    <div class="size-overlay" id="findpopup"></div>
    <div class="size-wrapper size-transition">
      <button class="size-close"><img src="assets/images/icons/close-icon.png" alt="close-icon"> </button>
      <div class="size-body">
        <div class="size-content">
            <div class="signup-main">
                <ul class="findpopuptab">
                    <li *ngIf="noDelivery">
                      <a class="active" data-tab="delivery" href="javascript:void(0);">Delivery</a>
                    </li>
                    <li>
                        <a class="active" data-tab="pickup" href="javascript:void(0);">Pickup</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div id="delivery" class="tab-pane show active" *ngIf="noDelivery">
                        <div class="find-main">
                            <div class="find-form">
                                <div class="pizzalgheading">Where are we delivering to?</div>
                                <!-- <form> -->
                                    <div class="row align-items-center">
                                        <div class="col-md-9">
                                            <div class="form-group">
                                                <label class="label-top">Enter your address, main intersection or postal code</label>
                                                <!-- <input type="text" class="form-control"> -->
                                                <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                                                <a class="find-btn" (click)="deliveryClick()"><i class="fa fa-location-arrow" aria-hidden="true"></i> Find Me</a>
                                            </div>
                                        </div>
                                        <div class="col-md-3 find-right">
                                            <button style="width:100px;" type="submit"  class="pizza-btn" [attr.disabled] = "pickupgoBtn == true ? null : 'disabled'">

                                                <span *ngIf="textGo">Go</span>
                                                <div *ngIf="goLoader" class="loader-12"></div>

                                        </button>
                                        </div>
                                    </div>
                                <!-- </form> -->
                            </div>
                            <div class="find-bg">
                                <img src="assets/images/location-modal-delivery-image.jpg" alt="location-modal-delivery-image">
                            </div>
                        </div>
                        <!-- <div class="manually-main">
                            <div class="pizzalgheading">Enter your address manually</div>
                            <div class="manually-form">
                                <form>
                                    <div class="row">
                                        <div class="col-6 col-md-3">
                                            <div class="form-group">
                                                <label class="label-top">Street Number</label>
                                                <input type="text" class="form-control" formControlName="Apartment">
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-9">
                                            <div class="form-group">
                                                <label class="label-top">Street Name</label>
                                                <input type="text" class="form-control" formControlName="Apartment">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-lg-5">
                                            <div class="form-group">
                                                <label class="label-top">City</label>
                                                <input type="text" class="form-control" formControlName="Apartment">
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-2">
                                            <div class="form-group">
                                                <label class="label-top">Province</label>
                                                <select class="form-control select-form" formControlName="month">
                                                    <option value="">ab</option>
                                                    <option value="1">bc</option>
                                                    <option value="2">mb</option>
                                                    <option value="3">nb</option>
                                                    <option value="4">nl</option>
                                                    <option value="5">ns</option>
                                                    <option value="6">nt</option>
                                                    <option value="7">nu</option>
                                                    <option value="8">on</option>
                                                    <option value="9">pe</option>
                                                    <option value="10">qc</option>
                                                    <option value="11">sk</option>
                                                    <option value="12">yt</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-5">
                                            <div class="form-group">
                                                <label class="label-top">Postal Code</label>
                                                <input type="text" class="form-control" formControlName="Apartment">
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-12 manually-btn">
                                            <div class="form-group">
                                                <input type="submit" class="pizza-btn" value="Go">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> -->
                    </div>
                    <div id="pickup" class="tab-pane show active">
                            <div class="find-form">
                                <div class="pizzalgheading">Find your local PZA Pizzeria!</div>
                                <!-- <form> -->
                                    <div class="row align-items-center">
                                        <div class="col-md-9">
                                            <div class="form-group">
                                                <label class="label-top">Enter your address, main intersection or postal code</label>
                                                <!-- <input type="text" class="form-control" > -->
                                               
                                                <!-- <input class="form-control" type="text" /> -->
                                                <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" [ngModel]="bar" (ngModelChange)="modelChangeFn($event)" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                                                <button *ngIf="resetButton" type="reset" class="reset-btn" (click)="resetAddress()"></button>
                                                <a class="find-btn" (click)="pickupClick('Pickup')"><i class="fa fa-location-arrow" aria-hidden="true"></i> Find Me</a>
                                            </div>
                                        </div>
                                        <div class="col-md-3 find-right">

                                            <button style="width:100px;" type="submit"  class="pizza-btn" (click)="pickupGo()" [attr.disabled] = "pickupgoBtn == true ? null : 'disabled'">

                                                    <span *ngIf="textGo">Go</span>
                                                    <div *ngIf="goLoader" class="loader-12"></div>

                                            </button>
                                        </div>
                                    </div>
                                <!-- </form> -->

                            </div>
                            <div class="find-bg" style="margin-bottom: 10px;">
                                <p style="text-align: center;
                                color: black;
                                font-size: 18px;
                                margin: 0 0 30px;">{{storedatanotavail}}</p>
                            </div>
                            <div class="find-bg" id="storeImage" *ngIf="storeImage">
                                <img src="assets/images/location-modal-delivery-image.jpg" alt="location-modal-delivery-image" width="100" height="100">
                            </div>
                            <!-- <div class="signInSaved" id="popupsignupbtn" *ngIf="popupsignupbtn">
                                <hr>
                                <br/><br/>
                                <h2>Or sign in to see your saved addresses</h2><br/>
                                
                                <a (click)="movetosignin()" class="pizza-btn">Sign In</a>
                            </div> -->
                        <div class="location-modal" id="storeDataAvail" *ngIf="storeDataAvail">
                            <!-- <div class="location-pick-list select-active">
                                <div class="location-address">
                                    <div class="pizzaxxsheading">1197 Ellesmere Road</div>
                                   <p>
                                       <span>Toronto - Scarborough, ON, M1P2X8</span>
                                       <span>(416)</span>
                                       <span>1 km</span>
                                   </p>
                                </div>
                                <div class="location-hours">
                                    <div class="location-hours-left">
                                        <div class="pizzaxxsheading"> Hours </div>
                                        <p>
                                            <span>Monday 11:00 AM - 01:00 AM</span>
                                            <span>Tuesday 11:00 AM - 01:00 AM</span>
                                            <span>Wednesday 11:00 AM - 01:00 AM</span>
                                            <span>Thursday 11:00 AM - 02:00 AM</span>
                                            <span>Friday 11:00 AM - 02:00 AM</span>
                                            <span>Saturday 11:00 AM - 02:00 AM</span>
                                            <span>Sunday 11:00 AM - 01:00 AM</span>
                                        </p>
                                        <div class="location-pickup">Delivery • Pickup • Online</div>
                                    </div>
                                </div>
                                <div class="location-hours-right">
                                    <p>Select</p>
                                    <i aria-hidden="true" class="fa fa-check"></i>
                                </div>
                                <div class="location-directions">
                                    <a href="#" target="_blank">
                                        <i aria-hidden="true" class="fa fa-location-arrow"></i>
                                        Get Directions
                                    </a>
                                </div>
                            </div> -->
                            <div *ngFor="let stores of storeData; let i = index" class="location-pick-list" (click)="selectClick(i, stores, 'Pickup')" [ngClass]="pickupId == i? 'select-active' : ''">
                                <div class="location-address">
                                    <div class="pizzaxxsheading">{{stores.store_name}}</div>
                                   <p>
                                       <span>{{stores.address}}</span>
                                       <span>{{stores.contact_number}}</span>
                                       <span>{{stores.distance}} km</span>
                                   </p>
                                </div>
                                <div class="location-hours">
                                    <div class="location-hours-left">
                                        <div class="pizzaxxsheading"> Hours </div>
                                        <p>
                                            <span *ngFor="let timings of stores.timings; let i = index">{{timings.day}} {{timings.open_time}} - {{timings.closed_time}}</span>   
                                        </p>
                                        <div class="location-pickup">Pickup • Online</div>
                                    </div>
                                </div>
                                <div class="location-hours-right">
                                    <p>Select</p>
                                    <i aria-hidden="true" class="fa fa-check"></i>
                                </div>
                                <div class="location-directions">
                                    <a href="https://www.google.com.sa/maps/search/{{stores.delivery_address}}?hl=en" target="_blank">
                                        <i aria-hidden="true" class="fa fa-location-arrow"></i>
                                        Get Directions
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
<!-- delete saved stores popup starts-->
<div class="storeClose-popup">
    <div class="find-overlay" id="findpopup"></div>
    <div class="find-wrapper find-transition delite-popup-wraper">
        <div class="find-header">
            <button class="find-close" (click)="storeClosepopuClose()"><img src="assets/images/icons/close-icon.png" alt="close-icon"> </button>
            <div class="user-icon">
                <i class="fa fa-bell" aria-hidden="true"></i>
            </div>
            <div class="pizzalgheading">Store Closed</div>
        </div>
      <div class="find-body">
        <p class="pizzaparaDelete">The store you have selected doesn't seem to be open!</p>
        <div class="find-content" style="text-align: center;">
            <button class="btn-delete-yes" (click)="storeClosepopuClose()">Okay</button>
        </div>
      </div>
    </div>
</div>
<!-- delete saved stores popup end-->
