<header id="site-header" class="d-flex align-items-center">
    <div class="container">
        <div class="top-header">
            <a href="javascript:void(0);" class="menuicon"></a>
            <div class="header-logo">
                <a (click)="movetohome()"><img src="assets/images/header-logo.png" alt="header-logo" width="100" height="100"></a>
            </div>
            <div class="header-nav">
                <div class="header-nav-left">
                    <a routerLink="/" class="logo"><img src="assets/images/header-logo.png" alt="header-logo" width="100" height="100"></a>
			        <a class="crossIcon">
                        <img src="assets/images/crossmenu.png" alt="header-logo" width="100" height="100">
                    </a>
                    <ul>
                        <!-- <li>
                            <a href="#">CLUB 11-11</a>
                        </li>
                        <li>
                            <a href="#">GIFT CARDS</a>
                        </li> -->
                        <!-- <li>
                            <a href="#">Add Coupon</a>
                        </li> -->
                        <li>
                            <a>
                                <img src="assets/images/homeicon.png" alt="home-icon" width="100" height="100">
                                <div class="callbox">
                                    <a href="javascript:void(0);" id="findpopup">
                                        <span *ngIf="defaultAddress">PZA Plaza Faro</span>
                                        <span *ngIf="setAddress"><b style="font-weight: bold;">{{addressType}}</b>  at  <span title="{{addressall.store_name}}" class="addressHeader">{{addressall.store_name}}</span></span>
                                    </a>
                                    <a *ngIf="setAddress" href="tel:{{addressall.contact_number}}">{{addressall.contact_number}}</a>
                                    <a *ngIf="defaultAddress" href="tel:+52 33 3632 4377">+52 33 3632 4377</a>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="header-nav-right" >
                  <div class="log-in-sign-in-bx" *ngIf="notLogin">
                    <i class="fa fa-user-circle-o hidden-md-i" aria-hidden="true"></i>
                    <div class="header-right-log-in">
                      <a routerLink="/user/sign-in"><i class="fa fa-user-circle-o" aria-hidden="true"></i>Sign In</a>
                      <a routerLink="/user/sign-up">Create Account </a>
                    </div>
                  </div>
                    <!-- <a routerLink="/user/sign-in" class="signup-btn" *ngIf="notLogin">
                        <img src="assets/images/icons/user-icon.png" alt="user-icon">
                        Sign In
                    </a> -->

                    <div class="user-profile" *ngIf="logedin">
                        
                        <a routerLink="/user/account" class="user-image">
                            <img [src]="userdata.social_avatar== null? this.imgUrl+userdata.avatar : userdata.social_avatar" alt="user">
                            <span>{{userdata.first_name}}</span>
                        </a>
                    </div>
                    
                </div>
            </div>
                <div id="google_translater" class="translater-text"></div>
        </div>
    </div>
</header>

<!-- store-popup-start -->
<div class="storeClose-popup2">
    <div class="find-overlay" id="findpopup"></div>
    <div class="find-wrapper find-transition delite-popup-wraper">
        <div class="find-header">
            <button class="find-close" (click)="storeClosepopuClose()"><img src="assets/images/icons/close-icon.png" alt="close-icon"> </button>
            <div class="user-icon">
                <i class="fa fa-bell" aria-hidden="true"></i>
            </div>
            <div class="pizzalgheading">Store Closed</div>
        </div>
      <div class="find-body">
        <p class="pizzaparaDelete">The store you have selected doesn't seem to be open!</p>
        <div class="find-content" style="text-align: center;">
            <button class="btn-delete-yes" (click)="storeClosepopuClose()">Okay</button>
        </div>
      </div>
    </div>
</div>
<!-- store-popup-end -->
