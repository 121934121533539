import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ProductRoutingModule } from './product-routing.module';
import { ProductListingComponent } from './product-listing/product-listing.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { FindMeLocationModule } from '../find-me-location/find-me-location.module';
import { RemovedoubleCommaPipe } from '../helper/removedouble-comma.pipe';
import { WebLoaderModule } from '../web-loader/web-loader.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProductEditComponent } from './product-edit/product-edit.component';

@NgModule({
  declarations: [
    ProductListingComponent,
    ProductDetailComponent,
    RemovedoubleCommaPipe,
    ProductEditComponent,
  ],
  imports: [
    CommonModule,
    ProductRoutingModule,
    SlickCarouselModule,
    FindMeLocationModule,
    WebLoaderModule,
    NgxSkeletonLoaderModule,
  ]
})
export class ProductModule { }
