import { Component, EventEmitter, Injectable, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from "jquery";
import { ApiServiceService } from '../services/api-service.service';
import { DataService } from '../services/data.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  notLogin: boolean = true;
  logedin:boolean = false;
   localStorageData:any
  userdata:any
  imgUrl:any = this.apiService.baseUrl;
  defaultAddress:boolean = true;
  setAddress:boolean= false;
  pickupDeliverystores:any;
  addressType:any;
  addressall:any
  constructor(private apiService:ApiServiceService, private dataservice: DataService,private router: Router,) { }

  ngOnInit(): void {
    // if(!localStorage.getItem("pickupDeliverystores")){
    //   // setTimeout(function () {
    //     jQuery(".find-popup").addClass("is-visible");
    // // }, 1 * 60 * 1000);
      
    // }
    this.scripts();
this.dataservice.currantMessage.subscribe(data=>{
  if(data == "logedIn"){
    this.localStorageData = localStorage.getItem("userData");
    this.userdata =JSON.parse(this.localStorageData);
    this.notLogin = false;
    this.logedin = true;
  }else if(data == "logedOut"){
    this.notLogin = true;
    this.logedin = false;
  }
})

    if(localStorage.getItem("user") === null){
     this.notLogin = true;
    this.logedin = false;
    }else{

      this.localStorageData = localStorage.getItem("userData");
      this.userdata =JSON.parse(this.localStorageData);
      this.notLogin = false;
      this.logedin = true;
    }
 


    this.dataservice.currantPickDeliv.subscribe(data=>{
  
      if(data == "notSet"){
        if(localStorage.getItem("pickupDeliverystores")){
          this.pickupDeliverystores = localStorage.getItem("pickupDeliverystores");
          let addressData = JSON.parse(this.pickupDeliverystores);
    
        this.addressType = addressData.type;
        this.addressall = addressData.data
        this.defaultAddress = false;
        this.setAddress= true;
        this.dataservice.productItem();
        }else{
          this.defaultAddress = true;
          this.setAddress= false;
        }

      }else if(data == "SetPickup"){
        this.pickupDeliverystores = localStorage.getItem("pickupDeliverystores");
        let addressData = JSON.parse(this.pickupDeliverystores);
       
        this.addressType = addressData.type;
        this.addressall = addressData.data
        this.defaultAddress = false;
        this.setAddress= true;
         //closing hours to minutes

        //  const CSTTime = new Date();
         const date = new Date();

var newDate = new Date(date.getUTCFullYear(),date.getUTCMonth(),date.getUTCDate(),date.getUTCHours(),date.getUTCMinutes(),date.getUTCSeconds());
var CSToffSet = -360;
var offset= CSToffSet*60*1000;
var CSTTime = new Date(newDate.getTime()+offset);
  var closingHours = addressData.data.closing_hours.trim().split(/\s+/);

  var closingMinute;
  var closingHoursSplit = closingHours[0].trim().split(/:/);
  if(closingHours[1]=="PM"){

    closingMinute = (Number(closingHoursSplit[0])+12)*60+Number(closingHoursSplit[1])
  }else{
    closingMinute = (Number(closingHoursSplit[0]))*60+Number(closingHoursSplit[1])
  }

   // opening hours to minutes
   var openingHours = addressData.data.opening_hours.trim().split(/\s+/);

   var openingminutes;
   var openingHoursSplit = openingHours[0].trim().split(/:/);
   if(openingHours[1]=="PM"){

 
     openingminutes = (Number(openingHoursSplit[0])+12)*60+Number(openingHoursSplit[1])
   }else{
     openingminutes = (Number(openingHoursSplit[0]))*60+Number(openingHoursSplit[1])
   }

   
   if(openingminutes <= (CSTTime.getHours()*60+CSTTime.getMinutes()) && (CSTTime.getHours()*60+CSTTime.getMinutes()) <= closingMinute){

   }else{
     document.body.classList.add('storeCloseSavedStores2')
   }
   this.dataservice.productItem();
      }
    })
  }
  storeClosepopuClose(){
    document.body.classList.remove('storeCloseSavedStores2')
  }
  movetohome(){
    this.dataservice.productItem();
    this.router.navigate(['/']);
  }
  scripts(){
    $(document).ready(function(){
      jQuery(".menuicon").on('click', function(){
        // jQuery(".header-nav-left").slideToggle();
        jQuery("body").addClass("hiddenScroll");
        jQuery(".header-nav").addClass("active-header-nav");
        jQuery("#google_translater").addClass("translater-active");
      });
      jQuery(".crossIcon").on('click', function(){
        // jQuery(".header-nav-left").slideUp();
        jQuery("body").removeClass("hiddenScroll");
        jQuery(".header-nav").removeClass("active-header-nav");
      });
      jQuery(".header-right-log-in a, .header-nav-left a").on('click', function(){
        jQuery("body").removeClass("hiddenScroll");
        jQuery(".header-nav").removeClass("active-header-nav");
        jQuery("#google_translater").removeClass("translater-active");
      });
      // jQuery(".header-nav-left a").on('click', function(){
      //   jQuery("body").removeClass("hiddenScroll");
      //   jQuery(".header-nav").removeClass("active-header-nav");
      //   jQuery("#google_translater").removeClass("translater-active");
      // });

      jQuery("#findpopup").on('click', function(){
        jQuery(".find-popup").addClass("is-visible");
      });
      jQuery(".size-close").on('click', function(){
        jQuery(".find-popup").removeClass("is-visible");
      });
      jQuery('.findpopuptab li a').on('click', function(){
        var target = $(this).attr('data-tab');
        jQuery('.findpopuptab li a').removeClass('active');
        jQuery(this).addClass('active');
        jQuery("#"+target).fadeIn('slow').siblings(".tab-pane").hide();
        return false;
      });
    });
  }
}
