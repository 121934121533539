<!-- <footer id="sitefooter">
    <div class="container">
        <div class="footer-top">
            <div class="row">
                <div class="col-6 col-md-2 footer-left">
                    <a href="#">
                        <img src="assets/images/logo-footer.png" alt="logo-footer" class="footer-logo" width="100" height="100">
                    </a>
                    <div class="store-locator">
                        <ul>
                            <li><a href="javascript:void(0);" routerLink="/restaurant-locator"><img src="assets/images/icons/location-icon.svg" alt="location-icon" width="100" height="100"> Restaurant Locator</a></li>
                            <li><a href="javascript:void(0);" routerLink="/accessibility">Accessibility</a></li>
                            <li><a href="javascript:void(0);" routerLink="/guarantee-policy">Guarantee Policy</a></li>
                            <li><a href="javascript:void(0);" routerLink="/privacy-policy">Privacy Policy</a></li>
                            <li><a href="javascript:void(0);" routerLink="/terms-and-conditions">Terms and Conditions</a></li>
                        </ul>
                    </div>
                    <div class="social-icons">
                        <ul>
                            <li>
                                <a target="_blank" href="https://www.facebook.com/pzapizzeria.mx?mibextid=LQQJ4d"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://instagram.com/pzapizzeria.mx?igshid=YmMyMTA2M2Y="><i class="fa fa-instagram" aria-hidden="true"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://twitter.com/pzapizzeria_mx?s=21&t=RcdqySfM97x0zVa34l8kcQ"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-6 col-md-10 footer-right">
                    <div class="row">
                        <div class="col-md-3" *ngFor="let footerlink of footerlinks">
                            <div class="flinks">
                                <h6 class="pizzaxsheading d-block">About Us</h6>
                                <ul class="footerlink">
                                  <li><a  href="/about-us/#since">Since 1967</a></li>
                                  <li><a  href="/about-us/#aboutourFood">About Our Food</a></li>
                                  <li><a  href="/about-us/#ourquality">Our Quality</a></li>
                                  <li><a  href="/about-us#nutrition">Nutrition</a></li>
                                  <li><a  href="/about-us/#menufAQ">Menu FAQ</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="flinks">
                                <h6 class="pizzaxsheading d-block">Franchising </h6>
                                <ul class="footerlink">
                                  <li><a href="/franchising/#yourpizzaPizza">Your Pizza Pizza</a></li>
                                  <li><a  href="/franchising/#franchisingbasics">Franchising Basics</a></li>
                                  <li><a  href="/franchising/#franchiseopportunities">Franchise Opportunities</a></li>
                                  <li><a  href="/franchising/#meettheteams">Meet The Teams</a></li>
                                  <li><a  href="/franchising/#getanswers">Get Answers</a></li>
                                  <li><a href="/franchising/#contactus">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="flinks">
                                <h6 class="pizzaxsheading d-block">Company</h6>
                                <ul class="footerlink">
                                  <li><a  href="/company/#ourcompany">Our Company</a></li>
                                  <li><a  href="/company/#leadershipteam">Leadership Team</a></li>
                                  <li><a  href="/company/#comeworkhere">Come Work Here</a></li>
                                  <li><a  href="/company/#community">Community</a></li>
                                  <li><a  href="/company/#slicesforsmiles">Slices For Smiles</a></li>
                                  <li><a  href="/company/#environment">Environment</a></li>
                                  <li><a  href="/company/#news">News</a></li>
                                  <li><a  routerLink="/investors">Investors</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="flinks">
                                <h6 class="pizzaxsheading d-block">For You</h6>
                                <ul class="footerlink">
                                  <li><a href="/for-you/#intro">Intro</a></li>
                                  <li><a href="/for-you/#contactus">Contact Us</a></li>
                                  
                                  <li><a href="/for-you/#schoollunchprogram">School Lunch Program</a></li>
                                  <li><a href="/for-you/#corporatecatering">Corporate Catering</a></li>
                                  <li><a href="/for-you/#contests">Contests</a></li>
                                  <li><a>Customer Satisfaction Survey</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <p>This site is protected by reCAPTCHA and the Google <a href="javascript:void(0);" target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="javascript:void(0);" target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
            <span>Copyright © PZA Pizzeria 2023</span>
        </div>
    </div>
</footer> -->
<footer id="sitefooter">
    <div class="container">
        <div class="footer-top">
            <div class="row">
                <div class="col-12 col-md-12 footer-left footer-left-2">
                    <a href="#">
                        <img src="assets/images/logo-footer.png" alt="logo-footer" class="footer-logo" width="100" height="100">
                    </a>
                   
                    <div class="store-locator">
                        <ul>
                            <li><a href="javascript:void(0);" routerLink="/restaurant-locator"><img src="assets/images/icons/location-icon.svg" alt="location-icon" width="100" height="100"> Restaurant Locator</a></li>
                           
                           
                        </ul>
                    </div>
                    <div class="store-locator">
                        <ul>
                          
                            <li><a href="https://ca01.z.antigena.com/l/BSc0jkGz96lN8c8v~yxMmQ_UbT_JRRrnXbykrX7bXwc4rH4j9wNPF57xCIhRD_Qrg3g13NAIPBx_jYPPwoPwhMj6nBhFLgNeqiYz2RlPAcs7JsEZ5oZlAPNeIxIq4_h30NQ7AFgdzv58G2H2gqUEECm9xIhDQ6cRMn9tHHeFIOTEEQVte25E5lnDk2nkqGuVmqMC7U8d9ijQ9KQ2AdH28Qmak_FOs43Kg_9X5v3hdN6comm6x1ebuO6" target="_blank">Facturación</a></li>
                           
                        </ul>
                    </div>
                    <div class="social-icons">
                        <ul>
                            <li>
                                <a target="_blank" href="https://www.facebook.com/pzapizzeria.mx?mibextid=LQQJ4d"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://instagram.com/pzapizzeria.mx?igshid=YmMyMTA2M2Y="><i class="fa fa-instagram" aria-hidden="true"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://twitter.com/pzapizzeria_mx?s=21&t=RcdqySfM97x0zVa34l8kcQ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#fb5000" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="footer-bottom footer-bottom-2">
            <p>This site is protected by reCAPTCHA and the Google <a href="javascript:void(0);" target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="javascript:void(0);" target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
            <span>Copyright © PZA Pizzeria 2024</span>
        </div>
    </div>
</footer>

<div class="add-cart" *ngIf="carthideshow">
    <div class="container">
        <div class="addcard-main">
            <div *ngIf="isShown" class="footer-overlay-cart"></div>
            <div class="row">
                <div class="addcard-top " [ngClass]="cartCount>0?'productadded':''" (click)="toggleShow()">
                    <div class="addcard-top-left">
                        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                        <span *ngIf="addedCart">{{cartCount}} Item<span *ngIf="cartCount>1">s</span></span>
                    </div>
                    <div class="addcard-top-right">
                        <span class="price notranslate" *ngIf="addedCart">${{totalCost}} <i *ngIf="checkoutloader" class="fa fa-spinner fa-spin" style="color: white; transform: initial; position: initial; text-align: center;"  aria-hidden="true"></i></span>
                        <span class="price" *ngIf="emptyCart">Empty Cart</span>
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </div>
                </div>

            </div>
            <div class="row" *ngIf="isShown">
                <div class="emptyCart your-cart" *ngIf="emptyCart">
                    <div class="pizzaxxsheading">Your Cart</div>
                    <img src="assets/images/cart.png" alt="">
                    <div class="button-bottom">
                        <button class="pizza-btn" (click)="checkoutBtn()">CHECKOUT </button>
                    </div>
                </div>
                <div class="cart-content" *ngIf="addedCart">
                    <!-- <div class="offer-card">
                        <div class="offercard-left">
                            <img src="assets/images/icons/11-11_logo.png" alt="11-11_logo">
                        </div>
                        <div class="offercard-right">
                            <p>Are you a Club 11-11 member? You could be be earning dough on each of your orders!</p>
                        </div>
                    </div> -->
                    <div class="your-cart">
                        <div class="pizzaxxsheading">Your Cart</div>
                        <div class="cart-products-wrapper">
                            <div class="cart-item" *ngFor="let cartProducts of cartProduct; let x= index">
                                <div class="cart-products-left">
                                    <img src="{{cartProducts.full_details.productDetail.itemImage_png == null?'assets/images/product/pizza.png':cartProducts.full_details.productDetail.itemImage_png}}" alt="add-cart">
                                </div>
                                <div class="cart-products-right">
                                    <div class="pizzaxxsheading">{{cartProducts.full_details.productName}}</div>
                                    <div class="description"><p>{{cartProducts.full_details.productDetail.description}}</p></div>
                                    <!-- <div class="description">
                                        <p>
                                            <span *ngFor="let item of cartProducts.full_details.pizzaIngrediants">{{item}}, </span>
                                            <span *ngFor="let entities of cartProducts.full_details.entity">
                                                <span *ngFor="let categorys of entities.category">
                                                    <span *ngFor="let catItems of categorys.items">{{catItems.name}}{{catItems.quantity == 1?"":"(X"+catItems.quantity+")"}}, </span>
                                                </span>
                                            </span>
                                        </p>
                                    </div> -->
                                    <div class="cart-products-bottom">
                                        <button class="small-btn remove-btn" (click)="removeItem(cartProducts, x)"><i  class="fa fa-trash-o" aria-hidden="true"></i>Remove <i class="fa fa-spinner fa-spin" style="margin-left: 10px;" *ngIf="removebtnloader == true && removebtnFlag == x" aria-hidden="true"></i></button>
                                        <button *ngIf="cartProducts.full_details.productDetail.category.addon_customize==true || cartProducts.full_details.productDetail.category.isCombo==true" (click)="editSelected(cartProducts.full_details.categoryUrl, cartProducts.full_details.productUrl, cartProducts)" class="small-btn edit-btn"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>Edit</button>
                                        <div class="products-price notranslate">${{cartProducts.full_details.rate}}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="cart-item">
                                <div class="cart-products-left">
                                    <img src="assets/images/add-cart.png" alt="add-cart">
                                </div>
                                <div class="cart-products-right">
                                    <div class="pizzaxxsheading">Large Buffalo Chicken</div>
                                    <div class="description">
                                        <p>REGULAR THICK CRUST, Buffalo Base Sauce (extra charge)(extra charge), SIX CHEESE BASE(extra charge), Parmesan Cheese, Grilled Chicken (extra charge), Red Onions</p>
                                    </div>
                                    <div class="cart-products-bottom">
                                        <button class="small-btn remove-btn"><i class="fa fa-trash-o" aria-hidden="true"></i>Remove</button>
                                        <button class="small-btn edit-btn"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>Edit</button>
                                        <div class="products-price">$21.59</div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="button-bottom">
                        <button class="pizza-btn btn-check-footer" (click)="checkoutBtn()">CHECKOUT </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="add-detail">
        <!-- <p>Adults and youth (ages 13 and older) need an average of 2,000 calories a day, and children (ages 4 to 12) need an average of 1,500 calories a day. However, individual needs vary.</p> -->
    </div>
</div>
