import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removedoubleComma'
})
export class RemovedoubleCommaPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return value.replace(/"/g, "");
  }

}
