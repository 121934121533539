<div class="find-popup">
    <div class="find-overlay" id="findpopup"></div>
    <div class="find-wrapper find-transition">
        <div class="find-header">
            <button class="find-close" (click)="FindsPopUpHide()"><img src="assets/images/icons/close-icon.png" alt="close-icon"> </button>
            <div class="user-icon">
                <i class="fa fa-bell" aria-hidden="true"></i>
            </div>
            <div class="pizzalgheading">Find the closest store</div>
        </div>
      <div class="find-body">
        <div class="find-content">
            <div class="signup-main">
                <div class="find-form">
                   
                        <div class="row align-items-center">
                            <div class="col-md-12">
                                <div class="form-group" id="inputmap" *ngIf="inputmap">
                                    <label class="label-top">Enter your address, main intersection or postal code</label>
                                    <input type="text" class="form-control"  (keydown.enter)="$event.preventDefault()" [ngModel]="bar" (ngModelChange)="modelChangeFn($event)" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                                    <button class="find-btn" (click)="pickupClick('Pickup')"><i class="fa fa-location-arrow" aria-hidden="true" ></i> Find Me</button>
                                </div>
                                
                                    <div style="text-align:center" id="mapLoader" *ngIf="mapLoader">
                                        <div class="loader-21"></div>
                                    </div>
                            </div>
                        </div>
                  
                </div>
                <div class="find-bg" style="margin-bottom: 10px;">
                    <p style="text-align: center;
                    color: black;
                    font-size: 18px;
                    margin: 0 0 30px;">{{storedatanotavail}}</p>
                </div>
                <div class="find-bg" *ngIf="bannerNoStores">
                    <img src="assets/images/location-modal-delivery-image.jpg" alt="location-modal-pick-up-image">
                </div>
                
                <div class="location-modal" *ngIf="storelistShow">
                    <div class="location-pick-list" [ngClass]="pickupId == i? 'select-active' : ''" *ngFor="let item of storeData; let i = index"  (click)="selectClick(i, item, 'Pickup')">
                        <div class="location-address">
                            <div class="pizzaxxsheading">{{item.store_name}}</div>
                        <p>
                            <span>{{item.address}}</span>
                            <span>{{item.distance}} km</span>
                        </p> 
                        </div>
                        <div class="location-hours-right">
                            <p>Select</p>
                            <i aria-hidden="true" class="fa fa-check"></i>
                        </div>
                    </div>
                    <!-- <div class="location-pick-list">
                        <div class="location-address">
                            <div class="pizzaxxsheading">1197 Ellesmere Road</div>
                        <p>
                            <span>1197 Ellesmere Road, Toronto - Scarborough, ON, M1P2X8</span>
                            <span>1 km</span>
                        </p> 
                        </div>
                        <div class="location-hours-right">
                            <p>Select</p>
                            <i aria-hidden="true" class="fa fa-check"></i>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
